export default {
  'address_optional': '<0>Address </0><1>(Optional)</1>',
  'add_another_condition': 'Add Another Condition',
  'add_medical_condition': 'Add Medical Condition',
  'age': 'Age',
  'can_select_more_than_one_condition': 'You can select more than one condition after your input',
  'city': 'City',
  'country': 'Country',
  'condition': 'Condition',
  'contact_information': 'Contact Information',
  'covid_detail_in_clinical_trial': 'Are you enrolled in or do you have plans to participate in another clinical trial?',
  'covid_detail_previous_clinical_trial': 'Have you previously participated in another clinical trial?',
  'covid_detail_in_hospital': 'Are you currently hospitalized with a confirmed diagnosis of COVID-19?',
  'covid_detail_healthcare_worker': 'Are you a healthcare worker?',
  'covid_detail_require_ventilator': 'Do you require the use of a mechanical ventilator?',
  'covid_detail_have_immune_diseases': 'Do you have any immune related diseases?',
  'covid_detail_on_chemotherapy': 'Are you currently on chemotherapy?',
  'covid_detail_taking_immunosuppressants': 'Are you currently taking immunosuppressants?',
  'covid_detail_breastfeeding': 'Are you pregnant or breastfeeding?',
  'current_email': 'Current Email',
  'current_password': 'Current Password',
  'email': 'Email',
  'email_not_verified': '<0>Your current email has not been verified yet, please check your email for a confirmation link. </0><1>Resend Verification Email</1>',
  'email_updated_successfully': 'Your email has been successfully updated, please check your email for a confirmation link.',
  'email_verification_failed': 'There was an error confirming your email, please try again.',
  'email_verification_sent': 'A verification email has been sent, please check your inbox.',
  'email_verified_successfully': 'Your email has been successfully verified.',
  'error_loading_email': 'An unknown error occurred while retrieving your profile details, please try again.',
  'ethnic_identity': '<0>Ethnic Identity </0><1>(Optional)</1>',
  'first_name': 'First Name',
  'form_description': 'The questions asked here will help us match you with clinical trials that best suits your background and needs.',
  'gender_biological': 'Biological Gender',
  'gender_identity': '<0>Gender Identity </0><1>(Optional)</1>',
  'last_name': 'Last Name',
  'medical_information': 'Medical Information',
  'medical_information_description': 'Fill in your medical information to quickly search trials and be notified of clinical trials that best suit your needs.',
  'medical_detail_in_clinical_trial': 'Are you enrolled in or do you have plans to participate in another clinical trial?',
  'medical_detail_previous_clinical_trial': 'Have you previously participated in another clinical trial?',
  'medical_detail_have_immune_diseases': 'Do you have any immune related diseases?',
  'medical_detail_taking_immunosuppressants': 'Are you currently taking immunosuppressants?',
  'medical_detail_breastfeeding': 'Are you pregnant or breastfeeding?',
  'medical_details_heading': 'Please complete details about your medical history',
  'medical_history_updated': 'Your medical history has been successfully updated.',
  'my_conditions': 'My Conditions',
  'my_medical_history': 'My Medical History',
  'new_email': 'New Email',
  'new_password': 'New Password',
  'new_password_confirm': 'Confirm New Password',
  'notification_settings': 'Notification Settings',
  'notification_settings_updated': 'Your notification settings have been successfully updated.',
  'notification_preferences_heading': 'What would you like to be notified for?',
  'notification_type_new_features': 'Notify me about new features on Heal Mary',
  'notification_type_trials_matching_health': 'Notify me for new trials that match my health criteria',
  'notification_type_trials_subscribed_searches': 'Notify for new trials that match my subscribed searches',
  'other': 'Other',
  'password_update_failed': 'Updating your password failed, please double check your current password and try again.',
  'password_updated_successfully': 'Your password was successfully updated.',
  'personal_information': 'Personal Information',
  'phone': 'Phone',
  'please_enter_condition_name': 'Please enter your condition',
  'please_add_condition': 'Please add your condition/illness',
  'please_answer_the_following': 'Please answer the following questions',
  'postal_code': 'Postal Code/ZIP',
  'preferred_contact_time': 'Preferred Day(s) and Time to call',
  'preferred_languages': 'Preferred Languages',
  'preferred_method_of_contact': 'Preferred Method of Contact',
  'preferred_tags': 'Preferred Tags',
  'preferred_tags_updated': 'Your preferred tags have been successfully updated.',
  'profile_settings': 'Profile Settings',
  'remove_medical_condition_title': 'Remove Medical Condition?',
  'remove_medical_condition_description': 'Are you sure you want to remove this medical condition from your profile?',
  'save_medical_history': 'Save',
  'save_preferred_tags': 'Save',
  'save_notification_settings': 'Save Settings',
  'save_profile': 'Save Profile',
  'saved_success': 'Your profile has been successfully updated.',
  'select_type_of_cancer': 'Please select your type of cancer',
  'state': 'Province/State',
  'street_address': 'Street Address',
  'unit_suite_number': 'Unit/Suite Number',
  'unknown_error': 'An unknown error occurred while saving your profile, please try again.',
  'update_email_title': 'Email',
  'update_password_title': 'Password',
  'user_type': 'User Type',
  'update_email': 'Update Email',
  'update_password': 'Update Password',
  'welcome_to_heal_mary': 'Welcome to Heal Mary, let\'s complete your profile.',
  'why_biological_gender': 'We ask for your biological gender (the gender you are born as) because some treatments are specific to people born with certain internal organs. For example, ovaries for people born as female and prostates for people born as male.',
  'why_email': 'This email will be used when contacting you, it can be different from the one you use to log in to Heal Mary',
  'why_ethnic_identity': 'We ask for your ethnic identity to customize your experience. With some diseases impacting certain ethnic backgrounds, we want you to provide you with important information that could help.',
  'why_gender_identity': 'We ask for your gender identity to help us respectfully address your healthcare needs through the process.',
  'why_preferred_language': 'We ask for your preferred language so sites can be aware and can help in that language.',

  'male': 'Male',
  'female': 'Female',
  'intersex': 'Intersex',

  'prefer_not_to_say': 'Prefer Not To Say',
  'agender': 'Agender',
  'androgyne': 'Androgyne',
  'bigender': 'Bigender',
  'butch': 'Butch',
  'cisgender': 'Cisgender',
  'gender_expansive': 'Gender Expansive',
  'genderfluid': 'Genderfluid',
  'gender_outlaw': 'Gender Outlaw',
  'genderqueer': 'Genderqueer',
  'masculine_of_center': 'Masculine Of Center',
  'nonbinary': 'Nonbinary',
  'omnigender': 'Omnigender',
  'polygender_and_pangender': 'Polygender And Pangender',
  'transgender': 'Transgender',
  'trans': 'Trans',
  'two_spirit': 'Two Spirit',

  'language_albanian': 'Albanian',
  'language_arabic': 'Arabic',
  'language_armenian': 'Armenian',
  'language_basque': 'Basque',
  'language_bengali': 'Bengali',
  'language_bosnian': 'Bosnian',
  'language_bulgarian': 'Bulgarian',
  'language_catalan': 'Catalan',
  'language_chinese_simplified': 'Chinese (Simplified)',
  'language_chinese_traditional': 'Chinese (Traditional)',
  'language_croatian': 'Croatian',
  'language_czech': 'Czech',
  'language_danish': 'Danish',
  'language_dutch': 'Dutch',
  'language_estonian': 'Estonian',
  'language_english': 'English',
  'language_finnish': 'Finnish',
  'language_french': 'French',
  'language_georgian': 'Georgian',
  'language_german': 'German',
  'language_greek': 'Greek',
  'language_hebrew': 'Hebrew',
  'language_hindi': 'Hindi',
  'language_hungarian': 'Hungarian',
  'language_icelandic': 'Icelandic',
  'language_indonesian': 'Indonesian',
  'language_irish': 'Irish',
  'language_italian': 'Italian',
  'language_japanese': 'Japanese',
  'language_korean': 'Korean',
  'language_kurdish': 'Kurdish',
  'language_latvian': 'Latvian',
  'language_lithuanian': 'Lithuanian',
  'language_macedonian': 'Macedonian',
  'language_malay': 'Malay',
  'language_malayalam': 'Malayalam',
  'language_norwegian': 'Norwegian',
  'language_persian': 'Persian',
  'language_polish': 'Polish',
  'language_portuguese_brazilian': 'Portuguese (Brazilian)',
  'language_portuguese_iberian': 'Portuguese (Iberian)',
  'language_punjabi': 'Punjabi',
  'language_romanian': 'Romanian',
  'language_russian': 'Russian',
  'language_serbian': 'Serbian',
  'language_slovak': 'Slovak',
  'language_slovenian': 'Slovenian',
  'language_spanish': 'Spanish',
  'language_swahili': 'Swahili',
  'language_swedish': 'Swedish',
  'language_tagalog': 'Tagalog',
  'language_tamil': 'Tamil',
  'language_telugu': 'Telugu',
  'language_thai': 'Thai',
  'language_vietnamese': 'Vietnamese',
  'language_welsh': 'Welsh',

  'ethnicity_american_indian_or_alaskan_native': 'American Indian or Alaskan Native',
  'ethnicity_asian': 'Asian',
  'ethnicity_black_or_african_american': 'Black or African American',
  'ethnicity_hispanic_or_latino': 'Hispanic or Latino',
  'ethnicity_native_hawaiian_or_other_pacific_islander': 'Native Hawaiian or Other Pacific Islander',
  'ethnicity_white': 'White',

  'caregiver': 'Caregiver',
  'patient': 'Patient',
  'practitioner': 'Practitioner',

  'zero_to_seventeen': '0 - 17',
  'eighteen_to_sixty_four': '18 - 64',
  'sixty_five_plus': '65+',

  'method_of_contact_phone': 'Call Me',
  'method_of_contact_email': 'Email Me',
  'method_of_contact_text': 'Text Me',
}
