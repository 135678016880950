import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation, Trans } from 'react-i18next';

import { MAILCHIMP_LINK } from 'common/constants';

import PageHeader from 'components/PageHeader';
import ButtonAnchor from 'components/ButtonAnchor';
import ButtonLink from 'components/ButtonLink';
import Divider from 'components/Divider';

import heroImg from './assets/hero.jpg';
import whoImg from './assets/who.png';

// Styled Components
const StyledCovidLanding = styled.main``;

const StyledButton = styled(ButtonLink)`
  display: inline-block;
  margin-top: 30px;
  box-shadow: 0 0 10px 0 ${(props) => props.theme.rawColors.black20};

  @media ${(props) => props.theme.devices.mobile} {
    display: block;
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const StyledSubHeading = styled.p`
  margin-top: 20px;
  padding-left: 6%;
  padding-right: 6%;

  font-size: ${(props) => props.theme.fontSizes.h3};
  font-weight: ${(props) => props.theme.fontWeights.bold};

  @media ${(props) => props.theme.devices.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.h3};
  }

  @media ${(props) => props.theme.devices.mobile} {
    font-size: ${(props) => props.theme.fontSizes.mobile.h3};
  }
`;

const StyledHero = styled.img`
  display: block;
  width: 100%;
  max-width: 780px;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;

  background-color: #8d8d8d;
`;

const StyledDetailsSection = styled.section`
  ${(props) => props.theme.paddedContent};

  max-width: 600px;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledWhoDetails = styled.section`
  display: flex;
  align-items: flex-start;

  max-width: 640px;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;

  padding: 42px 80px;

  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  img {
    margin-right: 40px;
    height: auto;
  }

  p {
    margin-bottom: 0;
  }

  a {
    display: inline-block;
    margin-top: 30px;
  }

  @media ${(props) => props.theme.devices.mobile} {
    flex-direction: column;
    align-items: center;

    padding-left: 20px;
    padding-right: 20px;

    text-align: center;

    img {
      margin-right: 0;
    }
  }
`;

const StyledReady = styled.section`
  margin-top: 80px;
  padding-top: 40px;
  padding-bottom: 30px;

  background-color: ${(props) => props.theme.colors.mediumBackground};
`;

const StyledReadyContent = styled.div`
  ${(props) => props.theme.paddedContent};

  text-align: center;

  h3 {
    margin-bottom: 0;
  }

  p {
    margin-top: 30px;
    font-size: ${(props) => props.theme.fontSizes.small};
  }
`;

export type CovidLandingProps = {
  theme: DefaultTheme;
};

// Component
const CovidLanding = ({ theme }: CovidLandingProps) => {
  const { t } = useTranslation();

  return (
    <StyledCovidLanding>
      <PageHeader backgroundColor={theme.rawColors.beige}>
        <h1>{t('covidLanding:clinical_trials_heading')}</h1>
        <StyledSubHeading>{t('covidLanding:about_covid')}</StyledSubHeading>
        <StyledButton to="/covidsearch" dark>
          {t('covidLanding:find_covid_trials')}
        </StyledButton>
      </PageHeader>

      <StyledHero src={heroImg} alt="" />

      <StyledDetailsSection>
        <h2>{t('covidLanding:how_it_works')}</h2>
        <Divider width={100} />
        <Trans i18nKey="covidLanding:how_it_works_details">
          <p>
            <span />
            <a
              href="https://www.nia.nih.gov/health/clinical-trials-benefits-risks-and-safety"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          </p>
          <p />
          <p />
        </Trans>
      </StyledDetailsSection>

      <StyledDetailsSection>
        <h2>{t('covidLanding:need_help')}</h2>
        <Divider width={100} />
        <Trans i18nKey="covidLanding:need_help_details">
          <p />
          <p />
        </Trans>
      </StyledDetailsSection>

      <StyledWhoDetails>
        <img src={whoImg} alt={t('covidLanding:world_health_organization')} />
        <div>
          <h2>{t('covidLanding:world_health_organization')}</h2>
          <p>{t('covidLanding:who_description')}</p>
          <ButtonAnchor
            href="https://www.who.int/ictrp/en/"
            target="_blank"
            rel="noopener noreferrer"
            dark
          >
            {t('covidLanding:launch_website')}
          </ButtonAnchor>
        </div>
      </StyledWhoDetails>

      <StyledReady>
        <StyledReadyContent>
          <h3>{t('covidLanding:ready_to_start')}</h3>
          <StyledButton to="/covidsearch" dark>
            {t('covidLanding:find_covid_trials')}
          </StyledButton>

          <p>
            <Trans i18nKey="covidLanding:stay_in_touch">
              <span />
              <a
                href={MAILCHIMP_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              <span />
            </Trans>
          </p>
        </StyledReadyContent>
      </StyledReady>
    </StyledCovidLanding>
  );
};

export default CovidLanding;
