import { useLayoutEffect, useRef, useState } from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledPillToggle = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 32px;
  border: 1px solid ${(props) => props.theme.colors.darkButtonBorder};
  border-radius: 20px;
  color: ${(props) => props.theme.colors.link};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.small};
`;

const StyledOption = styled.label<{ itemWidth: number }>`
  width: ${(props) =>
    props.itemWidth !== 0 ? `${props.itemWidth}px` : 'auto'};
  text-align: center;
  z-index: 100;

  cursor: pointer;

  span {
    white-space: nowrap;
    transition: color 0.4s;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;

    &:focus + span {
      text-decoration: underline;
    }

    &:checked + span {
      color: ${(props) => props.theme.colors.lightText};
    }
  }
`;

const StyledIndicator = styled.div<{ itemWidth: number; offset: number }>`
  position: absolute;
  left: ${(props) => props.itemWidth * props.offset}px;
  width: ${(props) => (props.offset === -1 ? 0 : props.itemWidth)}px;
  height: 100%;
  background-color: ${(props) => props.theme.colors.darkButtonBackground};

  border-radius: 20px;
  z-index: 1;

  transition: left 0.25s;
`;

export type ToggleOption = {
  label: string;
  value: string;
};

export type PillToggleProps = {
  name: string;
  options: ToggleOption[];
  value: string;
  onValueChange: (value: string) => void;
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'div'>;

const PillToggle = ({
  name,
  options,
  value,
  onValueChange,
  ...rest
}: PillToggleProps) => {
  const [itemWidth, setItemWidth] = useState<number>(0);
  const itemRefs = useRef<(HTMLLabelElement | null)[]>([]);

  useLayoutEffect(() => {
    let largestWidth = 32;
    itemRefs.current.forEach((item) => {
      if (item === null) {
        return;
      }
      const boundingRect = item.getBoundingClientRect();
      if (boundingRect.width + 32 > largestWidth) {
        largestWidth = boundingRect.width + 32;
      }
    });
    setItemWidth(largestWidth);
  }, []);

  const offset = options.findIndex((option) => option.value === value);

  return (
    <StyledPillToggle {...rest}>
      {options.map((option, index) => (
        <StyledOption
          key={option.value}
          ref={(ref) => (itemRefs.current[index] = ref)}
          itemWidth={itemWidth}
        >
          <input
            checked={option.value === value}
            name={name}
            type="radio"
            value={option.value}
            onChange={() => onValueChange(option.value)}
          />
          <span>{option.label}</span>
        </StyledOption>
      ))}
      <StyledIndicator itemWidth={itemWidth} offset={offset} />
    </StyledPillToggle>
  );
};

export default PillToggle;
