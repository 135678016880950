import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';

import {
  CONTACT_ADDRESS,
  CONTACT_EMAIL,
  CONTACT_PHONE,
  FACEBOOK_PRIVACY_LINK,
} from 'common/constants';

// Styled Components
const StyledPrivacyPolicy = styled.main`
  ${(props) => props.theme.paddedContent};
  max-width: 900px;
  margin: 60px auto;
`;

export type PrivacyPolicyProps = {
  theme?: DefaultTheme;
};

// Component
// Need to include props to support StyledComponents useTheme
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PrivacyPolicy = (props: PrivacyPolicyProps) => {
  const { t } = useTranslation(['privacy']);

  return (
    <StyledPrivacyPolicy className="infopage">
      <Helmet>
        <title>{t('privacy:browser_title')}</title>
      </Helmet>

      <h1>{t('privacy:privacy_notice')}</h1>

      <section>
        <h2>{t('privacy:1_purpose')}</h2>
        <Trans i18nKey="privacy:1_purpose_content">
          <p />
          <p />
          <p />
        </Trans>
      </section>

      <section>
        <h2>{t('privacy:2_personal_and_personal_health_information')}</h2>
        <Trans i18nKey="privacy:2_personal_and_personal_health_information_content">
          <p />
          <p />
        </Trans>
      </section>

      <section>
        <h2>
          {t(
            'privacy:3_collection_use_and_disclosure_of_personal_and_personal_information',
          )}
        </h2>
        <Trans i18nKey="privacy:3_collection_use_and_disclosure_of_personal_and_personal_information_content">
          <p />
          <p />
          <p />
          <p />
          <p />
          <p />
          <p />
          <p />
          <p />
        </Trans>
      </section>

      <section>
        <h2>{t('privacy:4_usage_and_aggregate_data')}</h2>
        <Trans i18nKey="privacy:4_usage_and_aggregate_data_content">
          <p />
          <p />
          <p />
          <p />
        </Trans>
      </section>

      <section>
        <h2>{t('privacy:5_consent')}</h2>
        <Trans i18nKey="privacy:5_consent_content">
          <p />
        </Trans>
      </section>

      <section>
        <h2>{t('privacy:6_data_retention')}</h2>
        <Trans i18nKey="privacy:6_data_retention_content">
          <p />
        </Trans>
      </section>

      <section>
        <h2>{t('privacy:7_control_of_user_data')}</h2>
        <Trans i18nKey="privacy:7_control_of_user_data_content">
          <p />
          <p />
          <p />
          <p />
          <p />
          <p />
          <p />
        </Trans>
      </section>

      <section>
        <h2>{t('privacy:8_governing_law')}</h2>
        <Trans i18nKey="privacy:8_governing_law_content">
          <p />
        </Trans>
      </section>

      <section>
        <h2>{t('privacy:9_contact_us')}</h2>
        <Trans
          i18nKey="privacy:9_contact_us_content"
          values={{
            address: CONTACT_ADDRESS,
            email: CONTACT_EMAIL,
            phone: CONTACT_PHONE,
          }}
        >
          <p />
          <p />
          <p />
          <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
          <p />
        </Trans>
      </section>

      <section>
        <h2>{t('privacy:10_cookies')}</h2>
        <Trans i18nKey="privacy:10_cookies_content">
          <p />
        </Trans>
      </section>

      <section>
        <h2>{t('privacy:11_google_maps')}</h2>
        <Trans i18nKey="privacy:11_google_maps_content">
          <p />
        </Trans>
      </section>

      <section>
        <h2>{t('privacy:12_facebook_messenger')}</h2>
        <Trans i18nKey="privacy:12_facebook_messenger_content">
          <p />
          <p />
          <a
            href={FACEBOOK_PRIVACY_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            &nbsp;
          </a>
        </Trans>
      </section>
    </StyledPrivacyPolicy>
  );
};

export default PrivacyPolicy;
