import { createGlobalStyle, css, DefaultTheme } from 'styled-components';

export const colors = {
  black: '#2E3844',
  black20: 'rgba(0, 0, 0, 0.2)',
  black50: 'rgba(0, 0, 0, 0.5)',
  blue: '#344968',
  lightBlue: '#7084A0',
  brightBlue: '#1566FF',
  beige: '#E5D8D1',
  rose: '#D8A5A0',
  darkRose: '#B78681',
  gray: '#EFEFEF',
  blueGray: '#F4F6F9',
  lightGray: '#F3F3F3',
  mediumGray: '#E2E2E2',
  mediumDarkGray: '#B9B9B9',
  darkGray: '#8D8D8D',
  yellow: '#E4D58D',
  offWhite: '#F9F9F9',
  white: '#FFFFFF',
  red: '#ce1212',
  green: '#3D8461',
  orange: '#D57F37',
  transparent: 'transparent',
};

const HealMaryTheme: DefaultTheme = {
  colors: {
    activeIndicator: colors.yellow,
    hover: colors.darkRose,
    active: colors.rose,
    error: colors.red,
    requiredIndicator: colors.red,

    // Backgrounds
    background: colors.white,
    mediumBackground: colors.gray,
    darkBackground: colors.blue,
    headerBackground: colors.white,
    headerBackgroundDark: colors.beige,
    backgroundActive: colors.blueGray,
    infoBackground: colors.lightGray,

    // Text
    text: colors.black,
    lightText: colors.white,
    accentText: colors.darkGray,
    successText: colors.green,
    heading: colors.blue,
    subheading: colors.lightBlue,
    link: colors.blue,
    linkDisabled: colors.mediumDarkGray,
    label: colors.rose,

    // Buttons
    darkButtonBackground: colors.blue,
    darkButtonText: colors.white,
    darkButtonBorder: colors.blue,
    darkButtonDisabledBackground: colors.mediumGray,

    lightButtonBackground: colors.transparent,
    lightButtonText: colors.blue,
    lightButtonBorder: colors.blue,

    altLightButtonBackground: colors.white,
    altLightButtonText: colors.lightBlue,
    altLightButtonBorder: colors.lightBlue,

    textLinkButton: colors.brightBlue,

    accentBorder: colors.lightBlue,
    inputBorder: colors.mediumGray,

    dashboardBackground: colors.offWhite,
    searchBackground: colors.offWhite,
    altSearchBackground: colors.lightGray,

    // Borders
    divider: colors.gray,

    // Inputs
    optionCircle: colors.blue,

    // Table
    tableEvenRow: colors.gray,
    tableOddRow: colors.offWhite,

    // Status
    statusActive: colors.green,
    statusInactive: colors.orange,
  },

  rawColors: colors,

  fonts: {
    main: 'Lato, sans-serif',
    title: "'Playfair Display', sans-serif",
  },

  fontWeights: {
    regular: 400,
    bold: 700,
  },

  fontSizes: {
    xxsmall: '12px',
    xsmall: '14px',
    small: '16px',
    regular: '18px',

    h1: '40px',
    h2: '30px',
    h3: '24px',
    h4: '20px',
    h5: '18px',
    h6: '16px',

    tablet: {
      h1: '40px',
      h2: '30px',
      h3: '24px',
      h4: '20px',
      h5: '18px',
      h6: '16px',
    },

    mobile: {
      h1: '28px',
      h2: '24px',
      h3: '20px',
      h4: '16px',
      h5: '14px',
      h6: '12px',
    },
  },

  paddedContent: css`
    padding-left: 40px;
    padding-right: 40px;

    @media ${(props) => props.theme.devices.mobile} {
      padding-left: 15px;
      padding-right: 15px;
    }
  `,

  dashboardContent: css`
    box-sizing: border-box;
    width: 100%;
    max-width: 1360px;
    ${(props) => props.theme.paddedContent}
  `,

  devices: {
    tablet: '(max-width: 940px)',
    mobile: '(max-width: 700px)',
  },
};

export default HealMaryTheme;

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;

    font-family: ${(props) => props.theme.fonts.main};
    font-size: ${(props) => props.theme.fontSizes.regular};
    line-height: 1.4;
    color: ${(props) => props.theme.colors.text};
    background-color: ${(props) => props.theme.colors.background};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.link};
    text-decoration: none;
    border-bottom: 2px solid #344968;
  }

  h1, h2, h3, h4, h5 {
    font-family: ${(props) => props.theme.fonts.title};
    color: ${(props) => props.theme.colors.heading};
  }

  h1 {
    margin: 0;

    font-size: ${(props) => props.theme.fontSizes.h1};

    @media ${(props) => props.theme.devices.tablet}  {
      font-size: ${(props) => props.theme.fontSizes.tablet.h1};
    }

    @media ${(props) => props.theme.devices.mobile}  {
      font-size: ${(props) => props.theme.fontSizes.mobile.h1};
    }
  }

  h2 {
    margin-top: 0.3em;
    margin-bottom: 0.3em;

    font-size: ${(props) => props.theme.fontSizes.h2};

    @media ${(props) => props.theme.devices.tablet}  {
      font-size: ${(props) => props.theme.fontSizes.tablet.h2};
    }

    @media ${(props) => props.theme.devices.mobile}  {
      font-size: ${(props) => props.theme.fontSizes.mobile.h2};
    }
  }

  h3 {
    margin-top: 0;
    margin-bottom: 1.25em;

    font-size: ${(props) => props.theme.fontSizes.h3};

    @media ${(props) => props.theme.devices.tablet}  {
      font-size: ${(props) => props.theme.fontSizes.tablet.h3};
    }

    @media ${(props) => props.theme.devices.mobile}  {
      font-size: ${(props) => props.theme.fontSizes.mobile.h3};
    }
  }

  h4 {
    margin: 0;
    font-size: ${(props) => props.theme.fontSizes.h4};

    @media ${(props) => props.theme.devices.tablet}  {
      font-size: ${(props) => props.theme.fontSizes.tablet.h4};
    }

    @media ${(props) => props.theme.devices.mobile}  {
      font-size: ${(props) => props.theme.fontSizes.mobile.h4};
    }
  }

  h5 {
    margin: 0;

    font-size: ${(props) => props.theme.fontSizes.h5};

    @media ${(props) => props.theme.devices.tablet}  {
      font-size: ${(props) => props.theme.fontSizes.tablet.h5};
    }

    @media ${(props) => props.theme.devices.mobile}  {
      font-size: ${(props) => props.theme.fontSizes.mobile.h5};
    }
  }

  h6 {
    margin: 0;

    font-size: ${(props) => props.theme.fontSizes.h6};

    @media ${(props) => props.theme.devices.tablet}  {
      font-size: ${(props) => props.theme.fontSizes.tablet.h6};
    }

    @media ${(props) => props.theme.devices.mobile}  {
      font-size: ${(props) => props.theme.fontSizes.mobile.h6};
    }
  }

  main {
    flex-grow: 1;
  }

  p {
    margin-top: 0.6em;
  }

  input {
    padding: 12px 15px;
    font-family: ${(props) => props.theme.fonts.main};
    font-size: ${(props) => props.theme.fontSizes.regular};
  }

  select {
    font-family: ${(props) => props.theme.fonts.main};
    font-size: ${(props) => props.theme.fontSizes.regular};
  }

  button {
    font-family: ${(props) => props.theme.fonts.main};
    font-size: ${(props) => props.theme.fontSizes.regular};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    line-height: 1.4;
    color: ${(props) => props.theme.colors.text};
    cursor: pointer;
  }

  button:focus:not(.focus-visible) {
    outline: none;
  }

  address {
    font-style: normal;
  }

  #root {
  }

  .infopage {
    h2, h3 {
      margin-top: 1em;
      margin-bottom: 0.5em;
    }
  }
`;
