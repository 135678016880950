export default {
  'account_exists': 'An account with the specified email already exists, please sign in or use a different email',
  'create_an_account': 'Create an Account',
  'have_account_sign_in': '<0>Already have an account? </0><1>Sign In Now<1>',
  'password_requirements': 'Your password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a symbol.',
  'sign_up': 'Sign Up',
  'sign_up_description': 'Sign up to view and save trials you applied for and to stay notified of trial updates.',
  'terms_and_privacy': '<0>By Signing up, you agree to our </0><1>Terms of Service</1><2> and </2><3>Privacy Policy</3><4>.</4>',
  'terms_and_privacy_integrator': '<0>This service is provided by a third party, </0><1>Heal Mary</1><2>. By Signing up, you agree to Heal Mary\'s </2><3>Terms of Service</3><4> and </4><5> Privacy Policy</5><6>.</6>',
  'unknown_error': 'There was an error signing up, please try again.',
};
