import IntegratorClient from 'common/types/IntegratorClient';
import getClientId from 'common/util/getClientId';
import { BASE_URL } from './constants';

/**
 * Helper function for getting integrator logo URL
 * @returns {string} The URL to the integrator logo
 */
export function getIntegratorImageUrl(): string {
  const clientId = getClientId();
  if (!clientId) {
    return '';
  }

  return `${BASE_URL}/integrator_logos/${clientId}`;
}

/**
 * Get the current integrator client details
 * @returns {Promise<IntegratorClient | null>} The integrator client or null when not found
 */
export async function getIntegratorClient(): Promise<IntegratorClient | null> {
  const clientId = getClientId();
  if (!clientId) {
    return null;
  }

  const response = await fetch(`${BASE_URL}/integrator_clients/${clientId}`);

  if (response.status === 404) {
    return null;
  }

  if (!response.ok) {
    throw new Error(`Bad Response: ${response.status}`);
  }
  return response.json();
}
