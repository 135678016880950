import styled, { css, DefaultTheme } from 'styled-components/macro';
import aiIconImg from './assets/icon-ai.svg';

// Styled Components
const StyledAiProcessedIndicator = styled.div<{
  marginTop?: number;
  inline?: boolean;
}>`
  ${(props) =>
    props.inline
      ? css`
          display: inline-flex;
          gap: 8px;
          padding: 4px 8px;
        `
      : css`
          display: flex;
          gap: 16px;
          padding: 4px 16px;
        `}
  align-items: center;
  justify-content: flex-start;
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : '8px')};
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.infoBackground};
  text-align: left;
  font-size: ${(props) => props.theme.fontSizes.small};
`;

export type AiProcessedIndicatorProps = {
  message: React.ReactNode;
  marginTop?: number;
  inline?: boolean;
  theme?: DefaultTheme;
};

const AiProcessedIndicator = ({
  message,
  marginTop,
  inline,
}: AiProcessedIndicatorProps) => (
  <StyledAiProcessedIndicator inline={inline} marginTop={marginTop}>
    <img src={aiIconImg} alt="" />
    <span>{message}</span>
  </StyledAiProcessedIndicator>
);

export default AiProcessedIndicator;
