import MagicLinkForm from 'components/MagicLinkForm';
import Modal from 'components/Modal';
import TextButton from 'components/TextButton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultTheme } from 'styled-components/macro';

export type MagicLinkModalProps = {
  theme?: DefaultTheme;
  onClose: () => void;
};

const MagicLinkModal = ({ onClose }: MagicLinkModalProps) => {
  const { t } = useTranslation();
  const [isMagicLinkSent, setIsMagicLinkSent] = useState(false);
  const [resetFormState, setResetFormState] = useState(Date.now());

  const onReset = () => {
    setResetFormState(Date.now());
    setIsMagicLinkSent(false);
  };

  return (
    <Modal
      heading={
        isMagicLinkSent
          ? t('common:magic_link_check_your_email')
          : t('common:magic_link_modal_title')
      }
      controls={
        <TextButton onClick={isMagicLinkSent ? onReset : onClose}>
          {t('common:back')}
        </TextButton>
      }
      small
    >
      <MagicLinkForm
        reset={resetFormState}
        onMagicLinkSent={() => setIsMagicLinkSent(true)}
      />
    </Modal>
  );
};

export default MagicLinkModal;
