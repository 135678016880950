// Helper for notifying iframe of height
type NotifyMessage =
  | {
      action: 'pageChange';
      data?: { path?: string };
    }
  | {
      action: 'resize';
      data: { height: number };
    }
  | {
      action: 'modal';
      data: { isShown: boolean };
    };

export default function notifyParent(message: NotifyMessage) {
  if (window?.parent && window.parent !== window) {
    window.parent.postMessage(message, '*');
  }
}
