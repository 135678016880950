/* eslint-disable react/prop-types */
import LabeledField from 'components/LabeledField';
import { LabeledFieldProps } from 'components/LabeledField/LabeledField';
import MultiSelect from 'components/MultiSelect';
import { MultiSelectProps } from 'components/MultiSelect/MultiSelect';
import React from 'react';
import { DefaultTheme } from 'styled-components/macro';

// Styled Components

export type LabeledMultiSelectProps<T> = {
  theme?: DefaultTheme;
} & LabeledFieldProps &
  MultiSelectProps<T>;

const LabeledMultiSelect = React.forwardRef(
  <T extends string | number>(
    {
      hasError,
      isRequired,
      label,
      tooltip,
      ...rest
    }: LabeledMultiSelectProps<T>,
    ref: React.Ref<HTMLLabelElement>,
  ) => (
    <LabeledField
      hasError={hasError}
      label={label}
      isRequired={isRequired}
      tooltip={tooltip}
      ref={ref}
    >
      <MultiSelect<T> hasError={hasError} {...rest} />
    </LabeledField>
  ),
);

export default LabeledMultiSelect as <T extends string | number>(
  props: LabeledMultiSelectProps<T> & { ref?: React.Ref<HTMLLabelElement> },
) => React.ReactElement;
