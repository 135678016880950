export default {
  'are_you_here_for_covid_or_other': 'Are you here for COVID-19 trials, or other clinical trials?',
  'browser_title': 'Heal Mary',

  'title': 'Heal Mary is an application designed to connect patients, caregivers and doctors with clinical trials data from all over the world for the best possible health outcome.',
  'find_trial': 'Find a clinical trial for me',

  'mission_statement': 'We believe in equal access and equal representation in healthcare clinical trial options for everyone in all communities.',
  'our_mission': 'OUR MISSION',

  'problems_percent': '<0>95</0><1>%</1>',
  'problems_description': 'of the world has health related problems',
  'enrollment_percent': '<0>40</0><1>%</1>',
  'enrollment_description': 'of cancer trials fail due to patient enrollment',
  'trials_count': '250k+',
  'trials_description': 'trials in the world right now',

  'free_for_patients_title': 'Free for patients to use',
  'free_for_patients_description': '<0>We know how difficult and stressful it can be to find information about clinical trial options when living with an illness or being a caregiver for someone who is sick. We\'re helping patients find options and feel empowered in their choices because we\'ve been there too.</0>',

  'decisions_title': 'Informed decision making',
  'decisions_description': '<p>We seek to provide clear information and solutions about trials that are happening world wide so that you are armed with information and working with your doctor to find the best trial for your personal best outcome.</p>',

  'welcome': 'Welcome to Heal Mary',
  'why_use_heal_mary': 'Why Use Heal Mary?',

  'why_free': 'Free to Use',
  'why_save_time': 'Save you time in searching for clinical trials',
  'why_global': 'Use a global trial database updated daily',
  'why_up_to_date': 'Stay up to date with current and future trials ',
  'why_easily_access': 'Easily access acceptance criteria to see if you qualify',
  'why_access_available': 'Access all available trials for each disease',
  'why_increase_matches': 'Increase potential trial matches meeting your personal healthcare needs',
  'why_ai': 'AI technology to translate medical jargon to plain language for easy understanding',
};
