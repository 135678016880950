import * as Sentry from '@sentry/react';
import { postProfile } from 'common/api/profile';
import Profile, { NotificationTypeEnum } from 'common/types/Profile';
import Button from 'components/Button';
import LabeledCheckboxGroup from 'components/LabeledCheckboxGroup';
import SuccessMessage from 'components/SuccessMessage';
import useIsMounted from 'hooks/useIsMounted';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledNotificationPreferencesForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media ${(props) => props.theme.devices.mobile} {
    gap: 40px;
  }
`;

const StyledButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${(props) => props.theme.devices.mobile} {
    button {
      width: 100%;
    }
  }
`;

const StyledError = styled.span`
  color: ${(props) => props.theme.colors.error};
  text-align: center;
  margin-bottom: 32px;
`;

export type NotificationPreferencesFormProps = {
  currentNotificationTypes: NotificationTypeEnum[];
  onProfileUpdated: (profile: Profile) => void;
  theme?: DefaultTheme;
};

const NotificationPreferencesForm = ({
  currentNotificationTypes,
  onProfileUpdated,
  ...rest
}: NotificationPreferencesFormProps) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorKey, setErrorKey] = useState<string | null>(null);
  const [preferencesSaved, setPreferencesSaved] = useState<boolean>(false);
  const [notificationTypes, setNotificationTypes] = useState<
    NotificationTypeEnum[]
  >(currentNotificationTypes);

  const onSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    return false;
  };

  const onUpdateNotificationPreferences = async () => {
    setErrorKey(null);

    try {
      setIsSubmitting(true);
      const profile = await postProfile({
        notification_types: notificationTypes,
      });
      if (!isMounted.current) {
        return;
      }
      setIsSubmitting(false);

      if (!profile) {
        setErrorKey('common:unknown_error');
        return;
      }
      onProfileUpdated(profile);

      setPreferencesSaved(true);
      setTimeout(() => {
        if (!isMounted.current) {
          return;
        }
        setPreferencesSaved(false);
      }, 5000);
    } catch (ex) {
      if (!isMounted.current) {
        return;
      }

      setErrorKey('common:unknown_error');
      setIsSubmitting(false);
      Sentry.captureException(ex);
    }
  };

  return (
    <StyledNotificationPreferencesForm onSubmit={onSubmit} {...rest}>
      <section>
        <LabeledCheckboxGroup
          disabled={isSubmitting}
          label={t('profile:notification_preferences_heading')}
          options={Object.values(NotificationTypeEnum).map(
            (notificationType) => ({
              label: t(`profile:notification_type_${notificationType}`),
              value: notificationType,
            }),
          )}
          values={notificationTypes}
          vertical
          onChange={(values) =>
            setNotificationTypes(values as NotificationTypeEnum[])
          }
        />
      </section>
      <StyledButtonRow>
        {errorKey && <StyledError>{t(errorKey)}</StyledError>}
        <SuccessMessage
          message={t('profile:notification_settings_updated')}
          shown={preferencesSaved}
        />
        <Button
          disabled={isSubmitting}
          type="button"
          dark
          onClick={onUpdateNotificationPreferences}
        >
          {t('profile:save_notification_settings')}
        </Button>
      </StyledButtonRow>
    </StyledNotificationPreferencesForm>
  );
};

export default NotificationPreferencesForm;
