/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import CovidFilters from 'common/types/CovidFilters';

import AgeInput, { AgeOption } from 'components/AgeInput/AgeInput';
import Button from 'components/Button';
import LocationSelect from 'components/LocationSelect';
import Modal from 'components/Modal';
import TextButton from 'components/TextButton';
import YesNoInput from 'components/YesNoInput/YesNoInput';

// Styled Components
const StyledControls = styled.div`
  display: flex;
  flex-direction: column;

  button:nth-of-type(2) {
    margin-top: 30px;
  }
`;

const StyledInputs = styled.div`
  margin-bottom: 60px;
`;

const StyledInput = styled.div`
  display: flex;
  margin-bottom: 40px;

  h2,
  div {
    text-align: left !important;
    justify-content: flex-start !important;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 20px !important;
  }

  h3 {
    font-size: 20px !important;
  }
`;

const StyledInputNumber = styled.div`
  flex: 0 0 auto;
  width: 30px;
  margin-right: 20px;

  font-family: ${(props) => props.theme.fonts.title};
  font-size: 24px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  color: ${(props) => props.theme.colors.accentText};
`;

export type ModifySearchModalProps = {
  filters: CovidFilters;
  onClose: () => void;
  onUpdateFilters: (filters: CovidFilters) => void;
  theme?: DefaultTheme;
};

// Component
const ModifySearchModal = ({
  filters,
  onClose,
  onUpdateFilters,
}: ModifySearchModalProps) => {
  const { t } = useTranslation();
  const [updatedFilters, setUpdatedFilters] = useState(filters);

  const getSetFilter =
    (filter: string) => (value?: string | boolean | number) => {
      setUpdatedFilters({
        ...updatedFilters,
        [filter]: value,
      });
    };

  const onCountryAndStateChange = (country: string, state?: string) => {
    setUpdatedFilters({
      ...updatedFilters,
      country,
      state,
    });
  };

  const inputs = [
    // Step #1: Location
    <LocationSelect
      key="location"
      selectedCountry={updatedFilters.country}
      selectedState={updatedFilters.state}
      onStateChange={getSetFilter('state')}
      onCountryAndStateChange={onCountryAndStateChange}
    />,

    // Step #2: Age
    <AgeInput
      key="age"
      value={updatedFilters.age}
      onChange={(newValue) =>
        getSetFilter('age')((newValue as AgeOption)?.value)
      }
    />,

    // Step #3: Other clinical
    <YesNoInput
      key="other_clinical"
      question={t('searchCovid:are_you_in_other_trial')}
      value={updatedFilters.inClinicalTrial}
      onChange={getSetFilter('inClinicalTrial')}
    />,

    // Step #4: Previous clinical trial
    <YesNoInput
      key="previous_trial"
      question={t('searchCovid:have_previous_trial')}
      value={updatedFilters.previousClinicalTrial}
      onChange={getSetFilter('previousClinicalTrial')}
    />,

    // Step #5: Hospitalized
    <YesNoInput
      key="hospitalized"
      question={t('searchCovid:are_you_hospitalized')}
      value={updatedFilters.inHospital}
      onChange={getSetFilter('inHospital')}
    />,

    // Step #6: Healthcare Worker
    <YesNoInput
      key="healthcare_worker"
      question={t('searchCovid:are_you_a_healthcare_worker')}
      value={updatedFilters.healthcareWorker}
      onChange={getSetFilter('healthcareWorker')}
    />,

    // Step #7: Ventilator
    <YesNoInput
      key="ventilator"
      question={t('searchCovid:do_you_require_a_ventilator')}
      value={updatedFilters.requireVentilator}
      onChange={getSetFilter('requireVentilator')}
    />,

    // Step #8: Have immune related diseases
    <YesNoInput
      key="immune_related_diseases"
      question={t('searchCovid:do_you_have_immune_disease')}
      value={updatedFilters.haveImmuneDiseases}
      onChange={getSetFilter('haveImmuneDiseases')}
    />,

    // Step #9: On Chemo
    <YesNoInput
      key="on_chemo"
      question={t('searchCovid:are_you_on_chemo')}
      value={updatedFilters.onChemotherapy}
      onChange={getSetFilter('onChemotherapy')}
    />,

    // Step #10: Taking immunosuppressants
    <YesNoInput
      key="immunosuppressants"
      question={t('searchCovid:are_you_taking_immuno')}
      value={updatedFilters.takingImmunosuppressants}
      onChange={getSetFilter('takingImmunosuppressants')}
    />,

    // Step #11: Pregnant or breastfeeding
    <YesNoInput
      key="pregnant"
      question={t('searchCovid:are_you_pregnant')}
      value={updatedFilters.breastfeeding}
      onChange={getSetFilter('breastfeeding')}
    />,
  ];

  return (
    <Modal
      controls={
        <StyledControls>
          <Button
            onClick={() => {
              onUpdateFilters(updatedFilters);
              onClose();
            }}
            dark
          >
            {t('searchCovid:updated_selection')}
          </Button>
          <TextButton onClick={onClose}>{t('common:close')}</TextButton>
        </StyledControls>
      }
      heading={t('searchCovid:modify_your_criteria')}
    >
      <StyledInputs>
        {inputs.map((input, index) => (
          <StyledInput key={index}>
            <StyledInputNumber>{index + 1}</StyledInputNumber>
            {input}
          </StyledInput>
        ))}
      </StyledInputs>
    </Modal>
  );
};

export default ModifySearchModal;
