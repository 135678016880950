import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledDetailsList = styled.ol`
  margin: 0;
  margin-bottom: 40px;
  padding: 0;
  list-style: none;

  & > li {
    margin-top: 40px;
  }
`;

export type DetailsListProps = {
  children: React.ReactNode;
  theme?: DefaultTheme;
};

// Component
const DetailsList = ({ children }: DetailsListProps) => (
  <StyledDetailsList>{children}</StyledDetailsList>
);

export default DetailsList;
