import { checkIsSignedIn } from 'common/api/auth';
import { PATHS } from 'common/constants';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export default function useRequireAuth(redirectPath: string = PATHS.SIGN_IN) {
  const history = useHistory();
  const location = useLocation();
  const [isAuthChecked, setIsAuthChecked] = useState<boolean>(false);

  useEffect(() => {
    const checkSession = async () => {
      const isSignedIn = await checkIsSignedIn();
      if (!isSignedIn) {
        history.push({
          pathname: redirectPath,
          search: `?targetPath=${location.pathname}${encodeURIComponent(
            location.search,
          )}`,
        });
      } else {
        setIsAuthChecked(true);
      }
    };

    checkSession();
  }, [history, location, redirectPath]);

  return isAuthChecked;
}
