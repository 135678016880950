/**
 * Clamp text to a given length, adding an ellipse to indicate trimming
 * @param text Text to clamp
 * @param maxLength Max length of text
 * @returns {string} The text clamped to the length, with ellipse added if trimmed
 */
export default function clampText(text: string, maxLength: number) {
  return text.length > maxLength
    ? `${text.substring(0, maxLength - 3)}...`
    : text;
}
