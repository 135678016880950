import * as Sentry from '@sentry/react';
import { resetForgotPassword } from 'common/api/auth';
import { PATHS } from 'common/constants';
import isEmailValid from 'common/util/isEmailValid';
import isPasswordValid from 'common/util/isPasswordValid';
import AuthPage from 'components/AuthPage';
import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import LabeledInput from 'components/LabeledInput/LabeledInput';
import LabeledPasswordField from 'components/LabeledPasswordField';
import useIsMounted from 'hooks/useIsMounted';
import QueryString from 'qs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components';

import accentImg from './assets/accent_image.jpg';
import iconConfirmationImg from './assets/icon-confirmation.svg';

const StyledHeading = styled.h1`
  white-space: pre-line;
  margin-bottom: 50px;
`;

const StyledResetPasswordForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const StyledFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const StyledPasswordReset = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  img {
    margin-right: 20px;
  }
`;

const StyledError = styled.span`
  color: ${(props) => props.theme.colors.error};
`;

type FormState = {
  email: string;
  password: string;
};

type QueryItems = {
  action?: string;
  code?: string;
  email?: string;
  region?: string;
  username?: string;
};

export type ResetPasswordProps = {
  theme?: DefaultTheme;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ResetPassword = (props: ResetPasswordProps) => {
  const { t } = useTranslation();

  const location = useLocation();
  const { email, code } = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as QueryItems;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorKey, setErrorKey] = useState<string | null>(null);
  const [emailHasError, setEmailHasError] = useState<boolean>(false);
  const [passwordHasError, setPasswordHasError] = useState<boolean>(false);
  const [formState, setFormState] = useState<FormState>({
    email: email ?? '',
    password: '',
  });
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  const isMounted = useIsMounted();
  const history = useHistory();

  if (!code) {
    history.replace(PATHS.SIGN_IN);
    return null;
  }

  const onSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    return false;
  };

  const onReset = async () => {
    setErrorKey(null);

    // Validate email
    let hasError = false;
    if (!isEmailValid(formState.email)) {
      hasError = true;
      setEmailHasError(true);
    }

    if (
      /**
       * Check for:
       *  - 1 number
       *  - 1 Uppercase letter
       *  - 1 Lowercase letter
       *  - 1 Special character
       *  - Minimum 8 characters total
       */
      !isPasswordValid(formState.password)
    ) {
      hasError = true;
      setPasswordHasError(true);
      setErrorKey('signUp:password_requirements');
    }

    if (hasError) {
      return;
    }

    try {
      setIsSubmitting(true);
      const result = await resetForgotPassword(
        code,
        formState.email,
        formState.password,
      );

      if (!isMounted.current) {
        return;
      }
      setIsSubmitting(false);

      if (result?.success) {
        setIsCompleted(true);
        return;
      }

      switch (result?.error?.code) {
        case 'ExpiredCodeException': {
          history.push(
            `${PATHS.FORGOT_PASSWORD}?error=expired_code&email=${email}`,
          );
          return;
        }
        case 'InvalidPasswordException': {
          setErrorKey('signUp:password_requirements');
          return;
        }
        default: {
          setErrorKey('resetPassword:unknown_error');
          return;
        }
      }
    } catch (ex: any) {
      if (!isMounted.current) {
        return;
      }

      setErrorKey('resetPassword:unknown_error');
      setIsSubmitting(false);
      Sentry.captureException(ex);
    }
  };

  return (
    <AuthPage accentImage={accentImg}>
      <StyledResetPasswordForm onSubmit={onSubmit}>
        <StyledHeading>{t('resetPassword:reset_your_password')}</StyledHeading>
        <StyledFields>
          {isCompleted ? (
            <StyledPasswordReset>
              <img src={iconConfirmationImg} alt="" />
              <span>{t('resetPassword:your_password_has_been_reset')}</span>
            </StyledPasswordReset>
          ) : (
            <>
              <LabeledInput
                autoComplete="email"
                disabled={isSubmitting}
                hasError={emailHasError}
                label={t('common:email')}
                placeholder={t('common:email_placeholder')}
                type="email"
                value={formState.email}
                onChange={(evt) => {
                  setEmailHasError(false);
                  setFormState({ ...formState, email: evt.target.value });
                }}
              />
              <LabeledPasswordField
                disabled={isSubmitting}
                hasError={passwordHasError}
                label={t('resetPassword:new_password')}
                value={formState.password}
                onChange={(evt) => {
                  setPasswordHasError(false);
                  setFormState({ ...formState, password: evt.target.value });
                }}
              />
            </>
          )}
          {errorKey && <StyledError>{t(errorKey)}</StyledError>}
          {isCompleted ? (
            <ButtonLink dark to={PATHS.SIGN_IN}>
              {t('resetPassword:return_to_sign_in')}
            </ButtonLink>
          ) : (
            <Button dark disabled={isSubmitting} onClick={onReset}>
              {t('resetPassword:reset_password')}
            </Button>
          )}
        </StyledFields>
      </StyledResetPasswordForm>
    </AuthPage>
  );
};

export default ResetPassword;
