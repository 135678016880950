/* eslint-disable no-param-reassign */
import LocationGroupings from 'common/types/LocationGroupings';
import TrialLocation from 'common/types/TrialLocation';

/**
 * Group a location under its country and state e.g. { "United States": { "Alabama": [...AlabamaLocations] } } }
 * @param {object} groupings The country > state structure to group the location in
 * @param {string} country The country of the location
 * @param {string} state The state of the location
 * @param {object} location The location itself
 */
export default function groupLocationByCountryAndState(
  groupings: LocationGroupings,
  country: string | undefined,
  state: string | undefined,
  location: TrialLocation,
) {
  if (country) {
    if (!groupings[country]) {
      groupings[country] = {};
    }

    if (state) {
      if (!groupings[country][state]) {
        groupings[country][state] = [];
      }

      groupings[country][state].push(location);
    } else {
      if (!groupings[country].other) {
        groupings[country].other = [];
      }

      groupings[country].other.push(location);
    }
  } else {
    if (!groupings.other) {
      groupings.other = [] as any[];
    }
    groupings.other.push(location);
  }
}
