import { CancerConditionDetails } from 'common/api/conditions';
import IconSelect from 'components/IconSelect';
import LabeledInput from 'components/LabeledInput';
import { CONDITION_GROUPS } from 'modules/Search/constants';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';

import iconBackImg from './assets/icon-back.svg';

// Styled Components
const StyledEditCancerConditionForm = styled.div``;

const StyledHeading = styled.h1`
  font-size: ${(props) => props.theme.fontSizes.h4};
  margin-bottom: 24px;

  @media ${(props) => props.theme.devices.mobile} {
    margin-bottom: 20px;
    font-size: ${(props) => props.theme.fontSizes.mobile.h4};
  }
`;

const StyledInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledBackButton = styled.button`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 0;
  border: none;
  background: transparent;
  color: ${(props) => props.theme.colors.link};

  img {
    margin-right: 8px;
  }

  @media ${(props) => props.theme.devices.mobile} {
    font-size: ${(props) => props.theme.fontSizes.small};
  }
`;

export type EditCancerConditionFormProps = {
  disabled?: boolean;
  cancerDetails: Partial<CancerConditionDetails>;
  onValueChange: (
    cancerConditionDetails: Partial<CancerConditionDetails>,
  ) => void;
  theme?: DefaultTheme;
};

const EditCancerConditionForm = ({
  disabled,
  cancerDetails,
  onValueChange,
  ...rest
}: EditCancerConditionFormProps) => {
  const { t } = useTranslation();
  const currentConditionGroup = cancerDetails.condition
    ? Object.values(CONDITION_GROUPS).find((group) =>
        Object.values(group.conditions)
          .map((condition) => condition.value)
          .includes(cancerDetails.condition!),
      )
    : null;

  const [conditionGroup, setConditionGroup] = useState<string | null>(
    currentConditionGroup ? currentConditionGroup.value : null,
  );
  const [isOtherCondition, setIsOtherCondition] = useState<boolean>(
    cancerDetails.condition && !currentConditionGroup ? true : false,
  );
  const conditionGroupsOptions = Object.values(CONDITION_GROUPS).map(
    (conditionGroup) => ({
      text: t(conditionGroup.i18nKey),
      icon: conditionGroup.icon,
      value: conditionGroup.value,
    }),
  );

  const conditionOptions = !conditionGroup
    ? []
    : Object.values(CONDITION_GROUPS[conditionGroup].conditions).map(
        (condition) => ({
          text: t(condition.i18nKey),
          icon: condition.icon,
          value: condition.value,
        }),
      );

  return (
    <StyledEditCancerConditionForm {...rest}>
      <StyledHeading>{t('profile:select_type_of_cancer')}</StyledHeading>
      {!conditionGroup ? (
        <StyledInputGroup>
          <IconSelect
            disabled={disabled || isOtherCondition}
            options={conditionGroupsOptions}
            selectedValues={conditionGroup ? [conditionGroup] : []}
            singleColumnMobile
            onChange={(conditionGroups) => {
              setConditionGroup(
                conditionGroups.length > 0 ? conditionGroups[0] : null,
              );
            }}
            light
          />
          <LabeledInput
            disabled={disabled}
            label={t('profile:other')}
            type="text"
            value={isOtherCondition ? cancerDetails.condition : ''}
            onChange={(evt) => {
              const { value } = evt.target;
              if (value) {
                setIsOtherCondition(true);
              } else {
                setIsOtherCondition(false);
              }
              onValueChange({
                ...cancerDetails,
                condition: value ? value : undefined,
              });
            }}
          />
        </StyledInputGroup>
      ) : (
        <StyledInputGroup>
          <StyledBackButton
            onClick={() => {
              setConditionGroup(null);
              onValueChange({
                ...cancerDetails,
                condition: undefined,
              });
            }}
          >
            <img src={iconBackImg} alt="" />
            <span>{t('common:back')}</span>
          </StyledBackButton>
          <IconSelect
            disabled={disabled}
            options={conditionOptions}
            selectedValues={
              cancerDetails.condition ? [cancerDetails.condition] : []
            }
            singleColumnMobile
            onChange={(conditions) => {
              onValueChange({
                ...cancerDetails,
                condition: conditions.length > 0 ? conditions[0] : undefined,
              });
            }}
            light
          />
        </StyledInputGroup>
      )}
    </StyledEditCancerConditionForm>
  );
};

export default EditCancerConditionForm;
