import { sendMagicLink } from 'common/api/auth';
import { PATHS } from 'common/constants';
import isEmailValid from 'common/util/isEmailValid';
import Button from 'components/Button';
import LabeledInput from 'components/LabeledInput';
import QueryString from 'qs';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components/macro';
import checkIconImg from './assets/icon-check.svg';
import magicLinkIconImg from './assets/icon-magic_link.svg';

// Styled Components
const StyledContent = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledIcon = styled.img`
  display: block;
  margin: 0 16px;
`;

const StyledDescription = styled.div`
  display: flex;
  gap: 4px;
  padding: 8px;
  background-color: ${(props) => props.theme.colors.infoBackground};
  border-radius: 8px;
  font-size: ${(props) => props.theme.fontSizes.small};
`;

const StyledError = styled.span`
  color: ${(props) => props.theme.colors.error};
`;

const StyledSuccessMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 8px 16px;
  background-color: ${(props) => props.theme.colors.infoBackground};
  border-radius: 8px;
`;

const StyledBoldText = styled.span`
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export type MagicLinkFormProps = {
  reset?: number;
  onMagicLinkSent?: () => void;

  theme?: DefaultTheme;
};

const MagicLinkForm = ({ reset, onMagicLinkSent }: MagicLinkFormProps) => {
  const location = useLocation();
  const { magicExpired } = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as { magicExpired?: string };

  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState<boolean>(false);
  const [errorKey, setErrorKey] = useState<string | null>(
    magicExpired ? 'common:magic_link_expired' : null,
  );
  const [isMagicLinkSent, setIsMagicLinkSent] = useState<boolean>(false);

  useEffect(() => {
    setIsMagicLinkSent(false);
  }, [reset]);

  const onSubmit = async () => {
    setErrorKey(null);

    if (!isEmailValid(email)) {
      setIsEmailInvalid(true);
      return;
    }

    try {
      setIsSubmitting(true);
      const result = await sendMagicLink(email);
      if (!result.success) {
        throw new Error();
      }
      setIsMagicLinkSent(true);
      onMagicLinkSent && onMagicLinkSent();
    } catch (err) {
      setErrorKey('common:unknown_error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return isMagicLinkSent ? (
    <StyledContent>
      <StyledSuccessMessage>
        <img src={checkIconImg} alt="" />
        <span style={{ overflow: 'auto' }}>
          <Trans i18nKey={'common:magic_link_sent'} values={{ email }}>
            <span />
            <StyledBoldText />
          </Trans>
        </span>
      </StyledSuccessMessage>
      <div>
        <Trans i18nKey={'common:magic_link_sent_details'} values={{ email }}>
          <span />
          <StyledBoldText />
        </Trans>
      </div>
      {errorKey && <StyledError>{t(errorKey)}</StyledError>}
      <Button type="button" dark disabled={isSubmitting} onClick={onSubmit}>
        {t('common:magic_link_resend')}
      </Button>
    </StyledContent>
  ) : (
    <StyledContent onSubmit={(evt) => evt.preventDefault()}>
      <LabeledInput
        autoComplete="email"
        disabled={isSubmitting}
        hasError={isEmailInvalid}
        label={t('common:email')}
        placeholder={t('common:email_placeholder')}
        type="email"
        value={email}
        onChange={(evt) => {
          setIsEmailInvalid(false);
          setEmail(evt.target.value);
        }}
      />
      {errorKey && <StyledError>{t(errorKey)}</StyledError>}
      <Button dark disabled={isSubmitting} onClick={onSubmit}>
        {t('common:magic_link_send')}
      </Button>
      <StyledDescription>
        <StyledIcon src={magicLinkIconImg} alt="" />
        <span>
          <Trans i18nKey={'common:magic_link_description'}>
            <span />
            <Link to={PATHS.SIGN_IN} />
          </Trans>
        </span>
      </StyledDescription>
    </StyledContent>
  );
};

export default MagicLinkForm;
