import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps, useLocation } from 'react-router-dom';

import SocialIcons from 'components/SocialIcons';

import arrowRightImg from './assets/arrow-right.svg';

// Styled Components
const StyledFooterBar = styled.footer<{ showSearch: boolean }>`
  ${(props) => props.theme.paddedContent}

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding-top: 22px;
  padding-bottom: 22px;

  background-color: ${(props) => props.theme.colors.darkBackground};

  color: ${(props) => props.theme.colors.lightText};
  font-size: ${(props) => props.theme.fontSizes.xsmall};
  font-weight: ${(props) => props.theme.fontWeights.bold};

  @media ${(props) => props.theme.devices.tablet} {
    flex-direction: column;

    padding-top: 30px;
    padding-bottom: 30px;

    margin-bottom: ${(props) => (props.showSearch ? '60px' : null)};
  }
`;

const StyledCopyright = styled.div`
  @media ${(props) => props.theme.devices.tablet} {
    order: 2;
  }
`;

const StyledLinks = styled.div`
  display: flex;
  flex-direction: row;

  @media ${(props) => props.theme.devices.tablet} {
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

const StyledLink = styled(Link)`
  margin-left: 40px;
  color: ${(props) => props.theme.colors.lightText};

  @media ${(props) => props.theme.devices.tablet} {
    margin-left: 0;
    margin-bottom: 10px;
    text-align: center;
  }
`;

export type StyledSearchLinkProps = {
  showSearch: boolean;
} & LinkProps;
// Extract 'showSearch' to avoid passing it to the dom anchor element
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ShowSearchExtractedLink = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showSearch,
  ...rest
}: StyledSearchLinkProps) => <Link {...rest} />;

const StyledSearchLink = styled(ShowSearchExtractedLink)`
  display: none;

  @media ${(props) => props.theme.devices.tablet} {
    ${(props) => props.theme.paddedContent}

    display: ${(props) => (props.showSearch ? 'flex' : 'none')};
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    height: 60px;

    font-size: ${(props) => props.theme.fontSizes.regular};
    color: ${(props) => props.theme.colors.lightText};
    background-color: ${(props) => props.theme.colors.darkBackground};

    border-bottom: none;

    box-shadow: 0 0 10px 0 ${(props) => props.theme.rawColors.black20};
  }
`;

export type FooterBarProps = {
  theme?: DefaultTheme;
};

// Component
// Need to include props to handle StyledComponents withTheme
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FooterBar = (props: FooterBarProps) => {
  const { t } = useTranslation(['common']);
  const location = useLocation();

  const showSearch =
    location.pathname === '/' ||
    location.pathname.indexOf('/about') === 0 ||
    location.pathname.indexOf('/contact') === 0;

  return (
    <StyledFooterBar showSearch={showSearch}>
      <StyledCopyright>{t('common:copyright')}</StyledCopyright>
      <SocialIcons />
      <StyledLinks>
        <StyledLink to="/faq">{t('common:faq')}</StyledLink>
        <StyledLink to="/terms">{t('common:terms_of_service')}</StyledLink>
        <StyledLink to="/privacy">{t('common:privacy_policy')}</StyledLink>
      </StyledLinks>

      <StyledSearchLink to="/" showSearch={showSearch}>
        {t('common:search_clinical_trials')}
        <img src={arrowRightImg} alt="" />
      </StyledSearchLink>
    </StyledFooterBar>
  );
};

export default FooterBar;
