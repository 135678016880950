import useOnClickOutside from 'hooks/useOnClickOutside';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components/macro';

import dropdownArrowImg from './assets/dropdown-arrow.svg';

// Styled Components
const StyledDropdownNavigation = styled.nav`
  position: relative;
  height: 60px;
`;

const StyledDropdownButton = styled.button<{ isExpanded?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 18px;
  padding-right: 48px;
  padding-top: 0;
  padding-bottom: 0;

  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  border-radius: ${(props) => (props.isExpanded ? '5px 5px 0 0' : '5px')};

  background-image: url(${dropdownArrowImg});
  background-repeat: no-repeat;
  background-position: right -30px center;
  background-origin: content-box;

  text-align: left;
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  color: ${(props) => props.theme.colors.heading};

  img {
    width: 24px;
    height: 24px;
    margin-right: 15px;
  }
`;

const StyledDropdownList = styled.ul<{ isExpanded?: boolean }>`
  display: ${(props) => (props.isExpanded ? 'block' : 'none')};
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;

  margin: 0;
  padding: 8px 20px;

  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  border-top: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 10px 10px -5px ${(props) => props.theme.rawColors.black20};

  list-style: none;

  a {
    display: flex;
    align-items: center;

    padding: 12px 0;

    text-decoration: none;
    font-size: ${(props) => props.theme.fontSizes.small};
    border-bottom: none;

    img {
      width: 24px;
      height: 24px;
      margin-right: 15px;
    }
  }
`;

export type NavItem = {
  icon: string;
  textKey: string;
  path: string;
};

export type DropdownNavigationProps = {
  navItems: NavItem[];
  theme?: DefaultTheme;
};

const DropdownNavigation = ({ navItems, ...rest }: DropdownNavigationProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const navRef = useOnClickOutside<HTMLElement>(() => setIsExpanded(false));

  useEffect(() => {
    setIsExpanded(false);
  }, [location]);

  let activeIndex = -1;
  let matchLength = 0;
  navItems.forEach(({ path }, index) => {
    if (location.pathname.startsWith(path)) {
      if (path.length > matchLength) {
        matchLength = path.length;
        activeIndex = index;
      }
    }
  });

  const filteredItems = [...navItems];
  const activeItem =
    activeIndex !== -1 ? filteredItems.splice(activeIndex, 1)[0] : null;

  return (
    <StyledDropdownNavigation ref={navRef} {...rest}>
      <StyledDropdownButton
        isExpanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {activeItem && (
          <>
            <img src={activeItem.icon} alt="" />
            <span>{t(activeItem.textKey)}</span>
          </>
        )}
      </StyledDropdownButton>
      <StyledDropdownList isExpanded={isExpanded}>
        {filteredItems.map(({ icon, textKey, path }) => (
          <li key={path}>
            <Link to={path}>
              <img src={icon} alt="" />
              <span>{t(textKey)}</span>
            </Link>
          </li>
        ))}
      </StyledDropdownList>
    </StyledDropdownNavigation>
  );
};

export default DropdownNavigation;
