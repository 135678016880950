import IconSelect from 'components/IconSelect';
import { IconSelectProps } from 'components/IconSelect/IconSelect';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledSearchIconSelect = styled(IconSelect)`
  justify-content: center;
  margin-left: 8.75%;
  margin-right: 8.75%;
  padding-bottom: 16px;

  @media ${(props) => props.theme.devices.mobile} {
    justify-content: space-between;

    margin-left: 0;
    margin-right: 0;
  }
`;

export type SearchIconSelectProps = {
  theme?: DefaultTheme;
};

const SearchIconSelect = (props: IconSelectProps) => (
  <StyledSearchIconSelect {...props} />
);

export default SearchIconSelect;
