import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';

import iconMedicalImg from './assets/icon-medical.svg';

// Styled Components
const StyledSearchSourceControl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 52px;
`;

const StyledHeading = styled.h1`
  font-size: ${(props) => props.theme.fontSizes.h2};

  @media ${(props) => props.theme.devices.mobile} {
    font-size: ${(props) => props.theme.fontSizes.mobile.h2};
  }
`;

const StyledButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 465px;
`;

export type SearchSourceControlProps = {
  title: string;
  onSearchWithConditions: () => void;
  onStartNew: () => void;
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'div'>;

const SearchSourceControl = ({
  title,
  onSearchWithConditions,
  onStartNew,
  ...rest
}: SearchSourceControlProps) => {
  const { t } = useTranslation();

  return (
    <StyledSearchSourceControl {...rest}>
      <StyledHeading>{title}</StyledHeading>
      <StyledButtons>
        <Button
          dark
          icon={iconMedicalImg}
          iconHover={iconMedicalImg}
          onClick={() => onSearchWithConditions()}
        >
          {t('search:search_using_my_medical_information')}
        </Button>
        <Button dark onClick={onStartNew}>
          {t('search:start_a_new_search')}
        </Button>
      </StyledButtons>
    </StyledSearchSourceControl>
  );
};

export default SearchSourceControl;
