import * as Sentry from '@sentry/react';
import { forgotPassword } from 'common/api/auth';
import { PATHS } from 'common/constants';
import AuthPage from 'components/AuthPage';
import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import LabeledInput from 'components/LabeledInput';
import useIsMounted from 'hooks/useIsMounted';
import QueryString from 'qs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components/macro';

import accentImg from './assets/accent_image.jpg';
import iconConfirmationImg from './assets/icon-confirmation.svg';

// Styled Components
const StyledDescription = styled.p`
  margin-top: 20px;
  margin-bottom: 50px;
`;

const StyledForgotPasswordForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const StyledFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const StyledResetSent = styled.div`
  display: flex;
  align-items: flex-start;
  height: 86px;

  @media ${(props) => props.theme.devices.mobile} {
    height: 80px;
  }

  img {
    margin-right: 20px;
  }
`;

const StyledError = styled.span`
  color: ${(props) => props.theme.colors.error};
`;

type QueryItems = {
  email?: string;
  error?: string;
};

export type ForgotPasswordProps = {
  theme?: DefaultTheme;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ForgotPassword = (props: ForgotPasswordProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { email: urlEmail, error } = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as QueryItems;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorKey, setErrorKey] = useState<string | null>(
    error ? `forgotPassword:${error}` : null,
  );
  const [emailHasError, setEmailHasError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(urlEmail ?? '');
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  const isMounted = useIsMounted();

  const onSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    return false;
  };

  const onReset = async () => {
    setErrorKey(null);
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailHasError(true);
      return;
    }

    try {
      setIsSubmitting(true);
      await forgotPassword(email);

      if (!isMounted.current) {
        return;
      }
      setIsSubmitting(false);
      setIsCompleted(true);
    } catch (ex: any) {
      if (!isMounted.current) {
        return;
      }

      setErrorKey('forgotPassword:unknown_error');
      setIsSubmitting(false);
      Sentry.captureException(ex);
    }
  };

  return (
    <AuthPage accentImage={accentImg}>
      <StyledForgotPasswordForm onSubmit={onSubmit}>
        <h1>{t('forgotPassword:reset_your_password')}</h1>
        <StyledDescription>
          {t('forgotPassword:forgot_instructions')}
        </StyledDescription>

        <StyledFields>
          {isCompleted ? (
            <StyledResetSent>
              <img src={iconConfirmationImg} alt="" />
              <span>{t('forgotPassword:reset_sent')}</span>
            </StyledResetSent>
          ) : (
            <LabeledInput
              autoComplete="email"
              disabled={isSubmitting}
              hasError={emailHasError}
              label={t('common:email')}
              placeholder={t('common:email_placeholder')}
              type="email"
              value={email}
              onChange={(evt) => {
                setEmailHasError(false);
                setEmail(evt.target.value);
              }}
            />
          )}
          {errorKey && <StyledError>{t(errorKey)}</StyledError>}
          {isCompleted ? (
            <ButtonLink dark to={PATHS.SIGN_IN}>
              {t('forgotPassword:return_to_sign_in')}
            </ButtonLink>
          ) : (
            <Button dark disabled={isSubmitting} onClick={onReset}>
              {t('forgotPassword:send_password_reset')}
            </Button>
          )}
        </StyledFields>
      </StyledForgotPasswordForm>
    </AuthPage>
  );
};

export default ForgotPassword;
