import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledIconSelect = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3%;
`;

const StyledIconOption = styled.button<{
  active: boolean;
  hasIcon: boolean;
  light: boolean;
  singleColumnMobile: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;

  width: ${(props) => (props.hasIcon ? '31%' : '27%')};
  height: 78px;

  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0;

  background-color: ${(props) =>
    props.light && !props.active
      ? 'transparent'
      : props.theme.colors.searchBackground};
  border: 0.5px solid ${(props) => props.theme.colors.inputBorder};
  border-radius: 5px;

  box-shadow: 0 0 20px -8px ${(props) => props.theme.rawColors.black20};

  line-height: normal;

  transition: opacity 0.4s;

  &:disabled {
    opacity: 0.4;
  }

  &:nth-of-type(n + 4) {
    margin-top: 3%;
  }

  @media ${(props) => props.theme.devices.mobile} {
    ${(props) =>
      props.singleColumnMobile
        ? css`
            width: 100%;
            &:nth-of-type(n + 2) {
              margin-top: 3%;
            }
          `
        : css`
            width: 47.8%;
            &:nth-of-type(n + 3) {
              margin-top: 3%;
            }
          `}
    height: 68px;

    margin-left: 0;
    margin-right: 0;

    padding-left: 15px;
    padding-right: 15px;
  }
`;

const StyledSelectionIndicator = styled.span`
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  border: 2px solid ${(props) => props.theme.colors.active};
  border-radius: 5px;
`;

const StyledIcon = styled.img`
  width: auto;
  height: 38px;
  margin-right: 20px;

  @media ${(props) => props.theme.devices.mobile} {
    margin-right: 10px;
  }
`;

const StyledLabel = styled.span<{ hasIcon: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch; /* IE11 Fix for not wrapping */
  flex-grow: 1;

  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  text-align: left;

  @media ${(props) => props.theme.devices.mobile} {
    font-size: ${(props) =>
      props.hasIcon
        ? props.theme.fontSizes.xsmall
        : props.theme.fontSizes.small};
    text-align: center;
  }
`;

export type IconSelectOption = {
  icon?: string;
  text: string;
  value: string;
};

export type IconSelectProps = {
  disabled?: boolean;
  light?: boolean;
  multi?: boolean;
  options: IconSelectOption[];
  selectedValues?: string[];
  singleColumnMobile?: boolean;
  onChange: (values: string[]) => void;
  theme?: DefaultTheme;
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'>;

// Component
const IconSelect = ({
  disabled = false,
  light = false,
  multi = false,
  options,
  selectedValues = [],
  singleColumnMobile = false,
  onChange,
  ...rest
}: IconSelectProps) => {
  const onValueSelected = (selectedValue: string) => {
    if (multi) {
      const updatedSelectedValues = selectedValues.includes(selectedValue)
        ? [
            ...selectedValues.slice(0, selectedValues.indexOf(selectedValue)),
            ...selectedValues.slice(selectedValues.indexOf(selectedValue) + 1),
          ]
        : [...selectedValues, selectedValue];
      onChange(updatedSelectedValues);
    } else {
      const updatedSelectedValues = selectedValues.includes(selectedValue)
        ? []
        : [selectedValue];
      onChange(updatedSelectedValues);
    }
  };

  const optionElements = options.map((option) => (
    <StyledIconOption
      active={selectedValues.includes(option.value)}
      disabled={disabled}
      hasIcon={option.icon !== undefined}
      key={option.text}
      light={light}
      singleColumnMobile={singleColumnMobile}
      onClick={() => onValueSelected(option.value)}
    >
      {selectedValues.includes(option.value) && <StyledSelectionIndicator />}
      {option.icon && <StyledIcon src={option.icon} alt="" draggable="false" />}
      <StyledLabel hasIcon={option.icon !== undefined}>
        {option.text}
      </StyledLabel>
    </StyledIconOption>
  ));

  return <StyledIconSelect {...rest}>{optionElements}</StyledIconSelect>;
};

export default IconSelect;
