import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-phone-number-input/style.css';

import { hydrate, render } from 'react-dom';

import HealMary from 'apps/HealMary';
import HealMaryIntegrator from 'apps/HealMaryIntegrator';
import configureAmplify from 'common/aws/configureAmplify';
import { IS_INTEGRATOR_MODE } from 'common/constants';
import initSentry from 'common/sentry/initSentry';

initSentry();
configureAmplify();

const rootElement = document.getElementById('root');

const App = IS_INTEGRATOR_MODE ? HealMaryIntegrator : HealMary;

if (rootElement?.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}
