import TagsResponse from 'common/types/TagsResponse';
import { BASE_URL } from './constants';

/**
 * Get all available tags
 */
export async function getTags(): Promise<TagsResponse> {
  const response = await fetch(`${BASE_URL}/tags`);

  return response.json();
}
