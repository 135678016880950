import * as Sentry from '@sentry/react';
import { postProfile } from 'common/api/profile';
import Profile from 'common/types/Profile';
import Button from 'components/Button';
import SuccessMessage from 'components/SuccessMessage';
import TagsSelect from 'components/TagsSelect';
import useIsMounted from 'hooks/useIsMounted';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledEditPreferredTagsForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media ${(props) => props.theme.devices.mobile} {
    gap: 40px;
  }
`;

const StyledButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${(props) => props.theme.devices.mobile} {
    button {
      width: 100%;
    }
  }
`;

const StyledError = styled.span`
  color: ${(props) => props.theme.colors.error};
  text-align: center;
  margin-bottom: 32px;
`;

export type EditPreferredTagsFormProps = {
  currentPreferredTags?: number[];
  onProfileUpdated: (profile: Profile) => void;
  theme?: DefaultTheme;
};

const EditPreferredTagsForm = ({
  currentPreferredTags,
  onProfileUpdated,
  ...rest
}: EditPreferredTagsFormProps) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorKey, setErrorKey] = useState<string | null>(null);
  const [tagsSaved, setTagsSaved] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<number[]>(
    currentPreferredTags ?? [],
  );

  const onSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    return false;
  };

  const onUpdatePreferredTags = async () => {
    setErrorKey(null);

    try {
      setIsSubmitting(true);
      const profile = await postProfile({
        preferred_tags: selectedTags,
      });
      if (!isMounted.current) {
        return;
      }
      setIsSubmitting(false);

      if (!profile) {
        setErrorKey('common:unknown_error');
        return;
      }

      onProfileUpdated(profile);
      setTagsSaved(true);
      setTimeout(() => {
        if (!isMounted.current) {
          return;
        }
        setTagsSaved(false);
      }, 5000);
    } catch (ex) {
      if (!isMounted.current) {
        return;
      }

      setErrorKey('common:unknown_error');
      setIsSubmitting(false);
      Sentry.captureException(ex);
    }
  };

  return (
    <StyledEditPreferredTagsForm onSubmit={onSubmit} {...rest}>
      <TagsSelect selectedTags={selectedTags} onTagsChange={setSelectedTags} />

      <StyledButtonRow>
        {errorKey && <StyledError>{t(errorKey)}</StyledError>}
        <SuccessMessage
          message={t('profile:preferred_tags_updated')}
          shown={tagsSaved}
        />
        <Button
          disabled={isSubmitting}
          type="button"
          dark
          onClick={onUpdatePreferredTags}
        >
          {t('profile:save_preferred_tags')}
        </Button>
      </StyledButtonRow>
    </StyledEditPreferredTagsForm>
  );
};

export default EditPreferredTagsForm;
