import SelectTitle from 'components/SelectTitle';
import TagsSelect from 'components/TagsSelect';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledTagsSearchSelect = styled.div``;

const StyledTagsSelect = styled(TagsSelect)`
  align-items: center;

  > label {
    width: 100%;
    max-width: 400px;
  }
`;

export type TagsSearchSelectProps = {
  selectedTags: number[];
  onTagsChange: (tags: number[]) => void;
  theme?: DefaultTheme;
};

const TagsSearchSelect = ({
  selectedTags,
  onTagsChange,
}: TagsSearchSelectProps) => {
  const { t } = useTranslation();

  return (
    <StyledTagsSearchSelect>
      <SelectTitle>{t('search:tags')}</SelectTitle>
      <StyledTagsSelect
        selectedTags={selectedTags}
        onTagsChange={onTagsChange}
      />
    </StyledTagsSearchSelect>
  );
};

export default TagsSearchSelect;
