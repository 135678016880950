export default {
  'create_account_description': 'Create an account to save trials that you\'ve applied to and stay up to date on eligible trials in the future. You will be redirected here after signing up.',
  'create_an_account': 'Create an account',
  'create_an_account_title': 'Create an Account',
  'have_account_sign_in': '<0>Have an account? </0><1>Sign In</1>',

  // Fields
  'name': 'Name',
  'email': 'Email',
  'phone': 'Phone',
  'preferred_method': 'Preferred Method of Contact',
  'street_address': 'Street Address',
  'unit_number': 'Unit/Suite Number',
  'country': 'Country',
  'province': 'Province/State',
  'city': 'City',
  'postal': 'Postal Code/ZIP',
  'trial_location': 'Please select your clinic trial location',
  'opt_in_share': 'I opt-in to share my acceptance criteria for matching with more trials.',

  // Contact Methods
  'call_me': 'Call Me',
  'text_me': 'Text Me',
  'email_me': 'Email Me',

  'lets_get_started': 'Let\'s get started',
  'submit': 'Submit',
  'unknown_error': 'An unknown error occurred, please try again.',
  'well_be_in_touch': 'Thank you for your submission, we\'ll contact you within the next 48 hours.',
  'we_use_your_contact_info': 'We use your contact information to help you get in touch with the team running this trial. Your privacy matters to us.',
  'we_do_not_sell_your_info': '<0>We do not sell your information to 3rd parties.</0><1></1><2>Please click here to review our </2><3>Privacy Policy</3><4>.</4>',
};
