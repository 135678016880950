import TrialIntervention from 'common/types/TrialIntervention';

export default function hasDisplayableIntervention(
  interventions?: TrialIntervention[],
) {
  return (
    interventions !== undefined &&
    interventions.filter(
      (intervention) => intervention.intervention_type !== 'Other',
    ).length > 0
  );
}
