import * as Sentry from '@sentry/react';
import { signUp } from 'common/api/auth';
import { IS_INTEGRATOR_MODE, PATHS } from 'common/constants';
import isEmailValid from 'common/util/isEmailValid';
import isPasswordValid from 'common/util/isPasswordValid';
import AuthPage from 'components/AuthPage';
import Button from 'components/Button';
import LabeledInput from 'components/LabeledInput/LabeledInput';
import LabeledPasswordField from 'components/LabeledPasswordField';
import useIsMounted from 'hooks/useIsMounted';
import useRedirectIfSignedIn from 'hooks/useRedirectIfSignedIn';
import QueryString from 'qs';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components';

import accentImg from './assets/accent_image.jpg';

const StyledSignUpForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const StyledDescription = styled.p`
  margin-top: 20px;
  margin-bottom: 50px;
`;

const StyledFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const StyledError = styled.span`
  color: ${(props) => props.theme.colors.error};
`;

const StyledTerms = styled.div`
  margin-top: 20px;
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

const StyledSignIn = styled.div`
  margin-top: 60px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  text-align: center;
`;

type FormState = {
  email: string;
  password: string;
};

type QueryItems = {
  targetPath?: string;
};

export type SignUpProps = {
  theme?: DefaultTheme;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SignUp = (props: SignUpProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  const { targetPath } = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as QueryItems;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorKey, setErrorKey] = useState<string | null>(null);
  const [emailHasError, setEmailHasError] = useState<boolean>(false);
  const [passwordHasError, setPasswordHasError] = useState<boolean>(false);
  const [formState, setFormState] = useState<FormState>({
    email: '',
    password: '',
  });

  const isMounted = useIsMounted();
  const history = useHistory();
  useRedirectIfSignedIn(targetPath ?? PATHS.DASHBOARD);

  const onSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    return false;
  };

  const onSignUp = async () => {
    setErrorKey(null);

    // Validate email
    let hasError = false;
    if (!isEmailValid(formState.email)) {
      hasError = true;
      setEmailHasError(true);
    }

    if (
      /**
       * Check for:
       *  - 1 number
       *  - 1 Uppercase letter
       *  - 1 Lowercase letter
       *  - 1 Special character
       *  - Minimum 8 characters total
       */
      !isPasswordValid(formState.password)
    ) {
      hasError = true;
      setPasswordHasError(true);
      setErrorKey('signUp:password_requirements');
    }

    if (hasError) {
      return;
    }

    try {
      setIsSubmitting(true);
      const result = await signUp(formState.email, formState.password);

      if (!isMounted.current) {
        return;
      }
      setIsSubmitting(false);

      if (result?.success) {
        const queryString = QueryString.stringify(
          {
            targetPath,
            signUpComplete: true,
          },
          { skipNulls: true },
        );
        history.push(`${PATHS.SIGN_IN}?${queryString}`);
        return;
      }

      switch (result?.error?.code) {
        case 'CodeDeliveryFailureException': {
          Sentry.captureMessage(
            `CodeDeliveryFailureException: Failed to sign up user, email: ${formState.email}`,
          );
          setErrorKey('signUp:unknown_error');
          return;
        }
        case 'InvalidPasswordException': {
          setErrorKey('signUp:password_requirements');
          return;
        }
        case 'UsernameExistsException': {
          setErrorKey('signUp:account_exists');
          return;
        }
        default: {
          setErrorKey('signUp:unknown_error');
          return;
        }
      }
    } catch (ex: any) {
      if (!isMounted.current) {
        return;
      }

      setErrorKey('signUp:unknown_error');
      setIsSubmitting(false);
      Sentry.captureException(ex);
    }
  };

  return (
    <AuthPage accentImage={accentImg}>
      <StyledSignUpForm onSubmit={onSubmit}>
        <h1>{t('signUp:create_an_account')}</h1>
        <StyledDescription>{t('signUp:sign_up_description')}</StyledDescription>

        <StyledFields>
          <LabeledInput
            autoComplete="email"
            disabled={isSubmitting}
            hasError={emailHasError}
            label={t('common:email')}
            placeholder={t('common:email_placeholder')}
            type="email"
            value={formState.email}
            onChange={(evt) => {
              setEmailHasError(false);
              setFormState({ ...formState, email: evt.target.value });
            }}
          />
          <LabeledPasswordField
            disabled={isSubmitting}
            hasError={passwordHasError}
            label={t('common:password')}
            value={formState.password}
            onChange={(evt) => {
              setPasswordHasError(false);
              setFormState({ ...formState, password: evt.target.value });
            }}
          />
          {errorKey && <StyledError>{t(errorKey)}</StyledError>}
          <Button dark disabled={isSubmitting} onClick={onSignUp}>
            {t('signUp:sign_up')}
          </Button>
        </StyledFields>
        <StyledTerms>
          {IS_INTEGRATOR_MODE ? (
            <Trans i18nKey="signUp:terms_and_privacy_integrator">
              <span />
              <a href="https://healmary.com" target="_blank" rel="noreferrer">
                Heal Mary
              </a>
              <span />
              <Link to="/terms" />
              <span />
              <Link to="/privacy" />
              <span />
            </Trans>
          ) : (
            <Trans i18nKey="signUp:terms_and_privacy">
              <span />
              <Link to="/terms" />
              <span />
              <Link to="/privacy" />
              <span />
            </Trans>
          )}
        </StyledTerms>
        <StyledSignIn>
          <Trans i18nKey="signUp:have_account_sign_in">
            <span />
            <Link
              to={`${PATHS.SIGN_IN}${
                targetPath
                  ? `?targetPath=${encodeURIComponent(targetPath)}`
                  : ''
              }`}
            />
          </Trans>
        </StyledSignIn>
      </StyledSignUpForm>
    </AuthPage>
  );
};

export default SignUp;
