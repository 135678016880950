import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { CONTACT_EMAIL } from 'common/constants';

// Styled Components
const StyledTermsOfService = styled.main`
  ${(props) => props.theme.paddedContent};
  max-width: 900px;
  margin: 60px auto;
`;

export type TermsOfServiceProps = {
  theme?: DefaultTheme;
};

// Component
// Need to include props to support StyledComponents useTheme
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TermsOfService = (props: TermsOfServiceProps) => {
  const { t } = useTranslation(['terms']);

  return (
    <StyledTermsOfService className="infopage">
      <Helmet>
        <title>{t('terms:browser_title')}</title>
      </Helmet>

      <h1>{t('terms:terms_of_use')}</h1>
      <Trans i18nKey="terms:intro">
        <p />
        <p />
        <p />
      </Trans>

      <section>
        <h2>{t('terms:scope_of_terms')}</h2>
        <Trans i18nKey="terms:scope_of_terms_content">
          <p />
          <p />
        </Trans>
      </section>

      <section>
        <h2>{t('terms:use_terms')}</h2>
        <Trans i18nKey="terms:use_terms_content">
          <p />
          <p />
          <p />
          <p />
          <p />
          <p />
          <p />
          <p />
          <p />
          <p />
        </Trans>
      </section>

      <section>
        <h2>{t('terms:privacy_and_personal_info')}</h2>
        <Trans i18nKey="terms:privacy_and_personal_info_content">
          <p />
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p />
          <p />
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
        </Trans>

        <p>
          <Trans i18nKey="terms:contact_info" values={{ email: CONTACT_EMAIL }}>
            &nbsp;
            <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
          </Trans>
        </p>
      </section>

      <p>{t('terms:last_updated')}</p>
    </StyledTermsOfService>
  );
};

export default TermsOfService;
