import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledDivider = styled(({ width, theme, ...rest }) => <hr {...rest} />)<{
  width: number;
}>`
  width: ${(props) => props.width}px;
  height: 4px;
  margin: 0;

  border: none;
  background-color: ${(props) => props.theme.rawColors.rose};
`;

export type DividerProps = {
  width?: number;
  theme?: DefaultTheme;
} & React.ComponentProps<'hr'>;

// Component
const Divider = ({ width = 100, ...rest }: DividerProps) => (
  <StyledDivider align="left" width={width} {...rest} />
);

export default Divider;
