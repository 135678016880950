import queryString from 'query-string';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components/macro';

import {
  CancerConditionDetails,
  Condition,
  ConditionTypeEnum,
  getConditions,
} from 'common/api/conditions';
import { getProfile } from 'common/api/profile';
import { getTrials } from 'common/api/trials';
import { COUNTRIES_BY_ALPHA_2 } from 'common/countries';
import Profile, { GenderEnum } from 'common/types/Profile';
import TrialUIFilters from 'common/types/TrialUIFilters';
import getFirstValue from 'common/util/getFirstValue';

import AgeInput from 'components/AgeInput';
import { AgeOption } from 'components/AgeInput/AgeInput';
import LocationSelect from 'components/LocationSelect';
import SearchPage from 'components/SearchPage';
import SearchSourceControl from 'components/SearchSourceControl';

import AuthContext from 'contexts/AuthContext';
import useIsMounted from 'hooks/useIsMounted';

import { CONDITIONS, DEFAULT_FILTERS } from './constants';

import { IS_INTEGRATOR_MODE, PATHS } from 'common/constants';
import ConditionSelect from './components/ConditionSelect';
import GenderSelect from './components/GenderSelect';
import ResultsFilters from './components/ResultsFilters';
import TagsSearchSelect from './components/TagsSearchSelect';
import UserConditionSelect from './components/UserConditionSelect';

// Styled Components
const StyledLoading = styled.div``;

// Helper
const getFilterUrl = (step: number, filters: TrialUIFilters) => {
  const filterParams = queryString.stringify(
    { ...filters, step },
    { arrayFormat: 'bracket', skipEmptyString: true, skipNull: true },
  );
  return `${PATHS.SEARCH}?${filterParams}`;
};

export type SearchProps = {
  theme?: DefaultTheme;
};

const PROFILE_GENDER_TO_SEARCH: Record<GenderEnum, string> = {
  male: 'Male',
  female: 'Female',
  intersex: '',
};

// Component
// Need to include props to support StyledComponents useTheme
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Search = (props: SearchProps) => {
  const { t } = useTranslation(['common', 'search']);
  const history = useHistory();
  const location = useLocation();
  const isMounted = useIsMounted();

  const queryParams = queryString.parse(location.search, {
    arrayFormat: 'bracket',
    parseNumbers: true,
  });

  const currentStep = (queryParams.step as number) || 0;
  const filters = {
    ...DEFAULT_FILTERS,
    ...queryString.parse(location.search, {
      arrayFormat: 'bracket',
      parseNumbers: true,
      parseBooleans: true,
    }),
  };

  const [conditionSearchValue, setConditionSearchValue] = useState(
    filters.conditionSearch,
  );
  const [isSearchSourceShown, setIsSearchSourceShown] = useState<boolean>(
    currentStep === 0,
  );
  const { isAuthChecked, isAuthenticated } = useContext(AuthContext);
  const [conditions, setConditions] = useState<Condition[] | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [isProfileChecked, setIsProfileChecked] = useState<boolean>(false);
  const [isUserConditionSelectShown, setIsUserConditionSelectShown] =
    useState<boolean>(false);
  const [isSearchingWithMedicalInfo, setIsSearchingWithMedicalInfo] =
    useState<boolean>(false);

  useEffect(() => {
    const loadConditions = async () => {
      try {
        const response = await getConditions();
        if (!isMounted.current) {
          return;
        }
        setConditions(response.conditions);
      } catch (ex) {
        if (!isMounted.current) {
          return;
        }
        // On error, ignore user's conditions
        setConditions([]);
      }

      try {
        const response = await getProfile();
        if (!isMounted.current) {
          return;
        }
        setProfile(response);
      } catch (ex) {
        if (!isMounted.current) {
          return;
        }
      }
      setIsProfileChecked(true);
    };
    if (isAuthChecked && isAuthenticated) {
      loadConditions();
    }
  }, [isAuthChecked, isAuthenticated, isMounted]);

  const onNewSearch = () => {
    setIsSearchingWithMedicalInfo(false);
    setIsSearchSourceShown(false);
  };

  const onSearchWithProfile = (condition?: Condition) => {
    if (!profile) {
      return;
    }

    const cancerConditions = conditions
      ? conditions.filter(
          ({ type }) =>
            type === ConditionTypeEnum.cancer ||
            type === ConditionTypeEnum.other,
        )
      : [];
    if (cancerConditions.length > 1 && !condition) {
      // Show condition select
      setIsUserConditionSelectShown(true);
      return;
    }
    setIsUserConditionSelectShown(false);
    setIsSearchSourceShown(false);
    setIsSearchingWithMedicalInfo(true);

    const searchCondition =
      condition ?? (cancerConditions.length === 1 ? cancerConditions[0] : null);
    const conditionDetails = searchCondition?.details as
      | CancerConditionDetails
      | undefined;

    const isBuiltInCondition =
      conditionDetails && CONDITIONS[conditionDetails.condition] ? true : false;

    history.replace(
      getFilterUrl(stepControls.length, {
        age: profile.age,
        country: profile.address_country_alpha_2
          ? COUNTRIES_BY_ALPHA_2[profile.address_country_alpha_2]
          : undefined,
        state: profile.address_state,
        genders:
          profile.gender_biological &&
          // Can't currently search by intersex
          profile.gender_biological !== GenderEnum.intersex
            ? [PROFILE_GENDER_TO_SEARCH[profile.gender_biological]]
            : [],
        conditionSearch:
          !isBuiltInCondition && conditionDetails
            ? conditionDetails.condition
            : '',
        conditionGroups: [],
        conditions:
          isBuiltInCondition && conditionDetails
            ? [conditionDetails.condition]
            : [],
        phases: [],
        tags: profile.preferred_tags ?? [],
      }),
    );
  };

  const setUpdatedFilters = (
    updatedFilters: TrialUIFilters,
    advance = false,
  ) => {
    // Update the URL for linking
    history.replace(
      getFilterUrl(currentStep + (advance ? 1 : 0), updatedFilters),
    );
  };

  const getSetFilter =
    (filter: string, advance = true) =>
    (value?: string | string[] | boolean | number | number[]) => {
      // Reset page filter if other filter changes
      const updatedPage = filter !== 'page' ? 1 : filters.page;
      const updatedFilters = {
        ...filters,
        page: updatedPage,
        [filter]: value,
      };

      // Don't advance if the value is empty
      const isValueEmpty =
        !value || (Array.isArray(value) && value.length === 0);
      setUpdatedFilters(updatedFilters, advance && !isValueEmpty);
    };

  const onCountryAndStateChange = (
    country: string,
    state?: string,
    countryHasStates?: boolean,
  ) => {
    const updatedFilters = {
      ...filters,
      country,
      state,
    };

    const advance =
      country !== '' &&
      countryHasStates !== undefined &&
      ((countryHasStates && state !== undefined) || !countryHasStates);

    setUpdatedFilters(updatedFilters, advance);
  };

  const onBack = () => {
    if (currentStep === 0) {
      setIsSearchSourceShown(true);
    } else if (
      // When going back, need to skip condition if the group isn't selected
      currentStep === 2 &&
      (!filters.conditions || filters.conditions.length < 1)
    ) {
      history.replace(getFilterUrl(currentStep - 2, filters));
    } else {
      history.replace(getFilterUrl(currentStep - 1, filters));
    }
  };

  const onNext = () => {
    // For condition, need to skip the condition select if skipping the group
    if (currentStep === 0) {
      history.replace(getFilterUrl(currentStep + 2, filters));
    } else {
      history.replace(getFilterUrl(currentStep + 1, filters));
    }
  };

  const onStartOver = () => {
    setConditionSearchValue('');
    setIsSearchSourceShown(true);

    history.replace(getFilterUrl(0, DEFAULT_FILTERS));
  };

  const onFiltersUpdate = (updatedFilters: TrialUIFilters) => {
    history.push(getFilterUrl(currentStep, updatedFilters));
  };

  // Define controls for each step
  const stepControls = [
    // Step #1: Condition Group
    <ConditionSelect
      key="condition_group"
      hideIcons={IS_INTEGRATOR_MODE}
      searchValue={conditionSearchValue}
      selectedConditions={filters.conditions}
      selectedGroups={filters.conditionGroups}
      step={0}
      onConditionsChange={() => {}}
      onGroupsChange={getSetFilter('conditionGroups')}
      // Special case where search has to skip next step
      onSearch={() => {
        history.replace(
          getFilterUrl(currentStep + 2, {
            ...filters,
            conditionSearch: conditionSearchValue,
          }),
        );
      }}
      onSearchChange={setConditionSearchValue}
    />,

    // Step #2: Condition
    <ConditionSelect
      key="condition"
      searchValue={conditionSearchValue}
      selectedConditions={filters.conditions}
      selectedGroups={filters.conditionGroups}
      step={1}
      onConditionsChange={getSetFilter('conditions')}
      onGroupsChange={() => {}}
      onSearch={() => {}}
      onSearchChange={setConditionSearchValue}
    />,

    // Step #3: Location
    <LocationSelect
      key="location"
      selectedCountry={filters.country}
      selectedState={filters.state}
      onStateChange={getSetFilter('state')}
      onCountryAndStateChange={onCountryAndStateChange}
    />,

    // Step #4: Age Group
    <AgeInput
      key="age"
      value={filters.age}
      includeBlank
      onChange={(newValue) =>
        getSetFilter('age')((newValue as AgeOption)?.value)
      }
    />,

    // Step #5: Gender
    <GenderSelect
      key="gender"
      selectedGenders={filters.genders}
      onGendersChange={getSetFilter('genders')}
    />,

    // Step #6: Tags
    <TagsSearchSelect
      key="tags"
      selectedTags={filters.tags}
      onTagsChange={getSetFilter('tags', false)}
    />,
  ];

  return (
    <SearchPage
      currentStep={currentStep}
      disclaimer={
        <Trans i18nKey="search:disclaimer">
          <span>
            <Link to="/faq" />
          </span>
        </Trans>
      }
      filters={filters}
      filterControls={
        <ResultsFilters
          conditionSearch={filters.conditionSearch}
          conditionGroups={filters.conditionGroups}
          conditions={filters.conditions}
          phases={filters.phases}
          country={filters.country}
          state={filters.state}
          age={filters.age}
          genders={filters.genders}
          tags={filters.tags}
          onFiltersUpdate={onFiltersUpdate}
          onStartOver={onStartOver}
        />
      }
      hasPreSearchControls={isAuthenticated && isProfileChecked && !!profile}
      preSearchControls={
        !isAuthChecked ||
        (isAuthChecked && isAuthenticated && !isProfileChecked) ? (
          <StyledLoading>{t('common:loading')}</StyledLoading>
        ) : (
          isAuthenticated &&
          isSearchSourceShown &&
          (isUserConditionSelectShown && conditions ? (
            <UserConditionSelect
              conditions={conditions}
              onCancel={() => setIsUserConditionSelectShown(false)}
              onConditionSelected={onSearchWithProfile}
            />
          ) : (
            <SearchSourceControl
              title={t('search:how_would_you_like_to_search_cancer_trials')}
              onSearchWithConditions={onSearchWithProfile}
              onStartNew={onNewSearch}
            />
          ))
        )
      }
      resultsTitle={
        isSearchingWithMedicalInfo
          ? t('search:results_title_from_medical')
          : t('search:results_title')
      }
      searchTitle={t('search:going_to_ask_questions')}
      stepControls={stepControls}
      title={t('search:browser_title')}
      trialBaseUrl="/trials"
      isStepWithNext={(step) => step === 2 || step === 5}
      isStepWithSkip={(step) => step !== 2 && step !== 5}
      onBack={onBack}
      onNext={onNext}
      onPageSelect={getSetFilter('page', false)}
      searchTrials={() =>
        getTrials(
          {
            age: filters.age,
            condition:
              filters.conditionSearch || getFirstValue(filters.conditions),
            phase: getFirstValue(filters.phases),
            country: filters.country,
            state: filters.state,
            gender: getFirstValue(filters.genders),
            tags: filters.tags,
          },
          filters.page,
        )
      }
    />
  );
};

export default Search;
