import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components/macro';

import {
  EVENT_CLICK,
  EVENT_CLOSE_CONTACT_INFORMATION,
  EVENT_INCOMPLETE,
  EVENT_SUBMIT,
  gtmPush,
} from 'common/analytics/gtm';
import { getAuthEmailDetails } from 'common/api/auth';
import { applyToTrial, submitTrialInterest } from 'common/api/trials';
import { API_COUNTRY_MAPPINGS, PATHS } from 'common/constants';
import COUNTRIES from 'common/countries';
import EligibilityChecklistItem from 'common/types/EligibilityChecklistItem';
import TrialLocation from 'common/types/TrialLocation';

import useIsMounted from 'hooks/useIsMounted';

import getClientId from 'common/util/getClientId';
import Button from 'components/Button/Button';
import LabeledInput from 'components/LabeledInput';
import LabeledSelect from 'components/LabeledSelect';
import Modal from 'components/Modal/Modal';
import TextButton from 'components/TextButton';

// Styled Components
const StyledForm = styled.div`
  display: flex;
  flex-wrap: wrap;

  > label,
  > fieldset {
    width: 47.5%;

    &:nth-child(2n) {
      margin-left: 2.5%;
    }

    &:nth-child(2n + 1) {
      margin-right: 2.5%;
    }

    // Custom width to avoid location title wrapping
    @media (max-width: 815px) {
      width: 100%;
      &:nth-child(n + 2) {
        margin-top: 30px;
      }

      &:nth-child(2n) {
        margin-left: 0;
      }

      &:nth-child(2n + 1) {
        margin-right: 0;
      }
    }

    &:nth-child(n + 3) {
      margin-top: 30px;
    }
  }
`;

const StyledDisclaimer = styled.div`
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;

  font-size: ${(props) => props.theme.fontSizes.small};
  text-align: center;
`;

const StyledIntro = styled.div`
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.small};
`;

const StyledFormControls = styled.div`
  margin-top: 80px;
  margin-bottom: 16px;

  text-align: center;

  @media ${(props) => props.theme.devices.mobile} {
    margin-top: 40px;
  }

  button {
    width: 290px;

    @media ${(props) => props.theme.devices.mobile} {
      width: 100%;
    }
  }
`;

const StyledSignUp = styled.div`
  flex: 1 0 auto;
  box-sizing: border-box;
  width: 100%;
  margin-top: 30px;
  padding: 24px 16px;

  background-color: ${(props) => props.theme.colors.mediumBackground};
  color: ${(props) => props.theme.colors.heading};
`;

const StyledSignUpTitle = styled.h2`
  margin: 0;
  font-size: ${(props) => props.theme.fontSizes.h3};
`;

const StyledSignUpDescription = styled.p``;

const StyledSignUpActions = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 8px;
  font-weight: ${(props) => props.theme.fontWeights.bold};

  @media ${(props) => props.theme.devices.mobile} {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 0;
  }
`;

const StyledError = styled.div`
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.error};
`;

const StyledThankYou = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  text-align: center;
`;

const REDIRECT_SPONSORED_TRIALS = false;

export type TrialApplicationModalProps = {
  inclusionResults: EligibilityChecklistItem[];
  exclusionResults: EligibilityChecklistItem[];
  isSponsored?: boolean;
  locations?: TrialLocation[];
  trialId: string;
  onClose: () => void;
  theme?: DefaultTheme;
};

type FormState = {
  email: string;
  phone: string;
  trialLocation: string;
  country?: string;
};

// Component
const TrialApplicationModal = ({
  inclusionResults,
  exclusionResults,
  isSponsored = false,
  locations = [],
  trialId,
  onClose,
}: TrialApplicationModalProps) => {
  const { t } = useTranslation(['geo']);
  const isMounted = useIsMounted();

  // Generate a URL to bring users back to after signing in
  const inclusionValueStrings = inclusionResults.map(
    ({ value }, index) => `${index}=${value}`,
  );
  const exclusionValueStrings = exclusionResults.map(
    ({ value }, index) => `${index}=${value}`,
  );
  const inclusionParam = `inclusionValues=${encodeURIComponent(
    inclusionValueStrings.join('&'),
  )}`;
  const exclusionParam = `exclusionValues=${encodeURIComponent(
    exclusionValueStrings.join('&'),
  )}`;
  const applicationUrl = `/trials/${trialId}?showEligibilityCheck=true&showApplication=true&${inclusionParam}&${exclusionParam}`;

  const [formState, setFormState] = useState<FormState>({
    email: '',
    phone: '',
    trialLocation: '',
  });
  const [error, setError] = useState<string | null>(null);
  const [fieldsWithError, setFieldsWithError] = useState<string[]>([]);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const emailRef = useRef<HTMLLabelElement | null>(null);

  // Get the auth email to pass as the default contact email
  useEffect(() => {
    const loadEmail = async () => {
      const emailDetails = await getAuthEmailDetails();

      if (!isMounted.current || !emailDetails) {
        return;
      }

      setIsLoggedIn(true);
      setFormState((currentFormState) => {
        if (!currentFormState.email) {
          return { ...currentFormState, email: emailDetails.email };
        }
        return currentFormState;
      });
    };

    loadEmail();
  }, [isMounted]);

  // Create Country options
  const countryOptions = Object.values(COUNTRIES).map((country) => (
    <option key={country.value} value={country.value}>
      {t(country.i18nKey)}
    </option>
  ));
  // Add a blank option first so that no country is selected to start with
  countryOptions.unshift(<option key="blank" value="none" />);

  // Create State/Province options
  const stateOptions =
    formState.country &&
    COUNTRIES[formState.country] &&
    COUNTRIES[formState.country].states
      ? Object.values(COUNTRIES[formState.country].states!)
          .sort((a, b) => {
            if (a.value > b.value) return 1;
            if (b.value > a.value) return -1;
            return 0;
          })
          .map((state) => (
            <option key={state.value} value={state.value}>
              {t(state.i18nKey)}
            </option>
          ))
      : [];
  if (stateOptions.length > 0) {
    // Add a blank option first so that no country is selected to start with
    stateOptions.unshift(<option key="blank" value="none" />);
  }

  // Get array of possible country name mappings - if there aren't any for a specific country the country name
  // itself is used (e.g.: "Korea" could be "Korea, Republic of" or "Korea, Democratic People's Republic of")
  const countryMappings =
    formState.country && API_COUNTRY_MAPPINGS[formState.country]
      ? API_COUNTRY_MAPPINGS[formState.country]
      : [formState.country];
  // Filter locations based on the selected country
  const filteredLocations =
    formState.country && locations.length > 0
      ? locations.filter(
          ({ facility }) =>
            facility?.address?.country &&
            countryMappings.includes(facility.address.country),
        )
      : locations;

  const locationOptions =
    filteredLocations.length > 0
      ? locations.map(({ facility }) => {
          const stateString = facility?.address?.state
            ? `${facility.address.state}, `
            : '';
          const zipString = facility?.address?.zip
            ? `, ${facility.address.zip}`
            : '';
          const facilityId = `${facility?.name}, ${facility?.address?.city}, ${stateString}${facility?.address?.country}${zipString}`;
          return (
            <option key={facilityId} value={facilityId}>
              {facilityId}
            </option>
          );
        })
      : [];
  if (locationOptions.length > 0) {
    // Add a blank option first so that no country is selected to start with
    locationOptions.unshift(<option key="blank" value="none" />);
  }

  const getSetFormState =
    (field: keyof FormState) =>
    ({
      target: { value },
    }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      // Remove from errors on change
      if (fieldsWithError.includes(field)) {
        setFieldsWithError(
          fieldsWithError.filter((fieldWithError) => fieldWithError !== field),
        );
      }

      setFormState((currentState) => {
        const updatedState = { ...currentState };
        updatedState[field] = value;
        return updatedState;
      });
    };

  const onSubmit = async () => {
    setError(null);
    setFieldsWithError([]);

    // Check for required fields
    const updatedFieldsWithError = [];
    if (formState.email.length < 1) {
      updatedFieldsWithError.push('email');
      if (updatedFieldsWithError.length === 1 && emailRef.current) {
        emailRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }

    if (updatedFieldsWithError.length > 0) {
      setFieldsWithError(updatedFieldsWithError);
      gtmPush({
        event: EVENT_CLICK,
        surveyName: trialId,
        eventAction: EVENT_SUBMIT,
        eventLabel: 'contact information',
        eventStatus: EVENT_INCOMPLETE,
      });
      return;
    }

    gtmPush({
      event: EVENT_CLICK,
      surveyName: trialId,
      eventAction: EVENT_SUBMIT,
      eventLabel: 'contact information',
    });

    try {
      // For sponsored trials, the user is redirected to the trial link
      // NOTE: This is currently disabled until how to determine which
      // sponsored trials are redirected is decided (REDIRECT_SPONSORED_TRIALS == false)
      if (isSponsored && REDIRECT_SPONSORED_TRIALS) {
        const redirectData = await applyToTrial(
          trialId,
          formState,
          inclusionResults,
          exclusionResults,
          getClientId(),
        );
        if (!redirectData || !redirectData.redirect_url) {
          setError(t('trialApplication:unknown_error'));
          return;
        }

        window.location.href = redirectData.redirect_url!;
      } else {
        // For non-sponsored trials, the user details are submitted
        const submitResult = await submitTrialInterest(
          trialId,
          formState,
          inclusionResults,
          exclusionResults,
          getClientId(),
        );

        if (!submitResult || !submitResult.success) {
          setError(t('trialApplication:unknown_error'));
          return;
        }

        setIsSubmitted(true);
      }
    } catch (ex) {
      setError(t('trialApplication:unknown_error'));
    }
  };

  const handleOnClose = () => {
    gtmPush({
      event: EVENT_CLICK,
      surveyName: trialId,
      eventAction: EVENT_CLOSE_CONTACT_INFORMATION,
    });
    onClose();
  };

  if (isSubmitted) {
    return (
      <Modal
        key="submitted"
        depth={2}
        controls={
          <TextButton onClick={onClose}>{t('common:close')}</TextButton>
        }
        small
      >
        <StyledThankYou>
          {t('trialApplication:well_be_in_touch')}
        </StyledThankYou>
      </Modal>
    );
  }

  return (
    <Modal
      depth={2}
      heading={t('trialApplication:lets_get_started')}
      controls={
        <TextButton onClick={handleOnClose}>{t('common:close')}</TextButton>
      }
    >
      <StyledIntro>
        {t('trialApplication:we_use_your_contact_info')}
      </StyledIntro>
      <StyledForm role="form">
        <LabeledInput
          autoComplete="email"
          disabled={isLoggedIn}
          hasError={fieldsWithError.includes('email')}
          label={t('trialApplication:email')}
          type="text"
          value={formState.email}
          ref={emailRef}
          isRequired
          onChange={getSetFormState('email')}
        />
        {!isLoggedIn && (
          <LabeledInput
            autoComplete="tel"
            label={t('trialApplication:phone')}
            type="text"
            value={formState.phone}
            onChange={getSetFormState('phone')}
          />
        )}
        {locationOptions.length > 0 && (
          <LabeledSelect
            label={t('trialApplication:trial_location')}
            value={formState.trialLocation}
            onChange={getSetFormState('trialLocation')}
          >
            {locationOptions}
          </LabeledSelect>
        )}
        {!isLoggedIn && (
          <StyledSignUp>
            <StyledSignUpTitle>
              {t('trialApplication:create_an_account_title')}
            </StyledSignUpTitle>
            <StyledSignUpDescription>
              {t('trialApplication:create_account_description')}
            </StyledSignUpDescription>
            <StyledSignUpActions>
              <Link
                to={`${PATHS.SIGN_UP}?targetPath=${encodeURIComponent(
                  applicationUrl,
                )}`}
              >
                {t('trialApplication:create_an_account')}
              </Link>
              <span>
                <Trans i18nKey={'trialApplication:have_account_sign_in'}>
                  <span />
                  <Link
                    to={`${PATHS.SIGN_IN}?targetPath=${encodeURIComponent(
                      applicationUrl,
                    )}`}
                  />
                </Trans>
              </span>
            </StyledSignUpActions>
          </StyledSignUp>
        )}
      </StyledForm>
      <StyledFormControls>
        <StyledError>{error}</StyledError>
        <Button dark onClick={onSubmit}>
          {t('trialApplication:submit')}
        </Button>
      </StyledFormControls>
      <StyledDisclaimer>
        <Trans i18nKey="trialApplication:we_do_not_sell_your_info">
          <span />
          <br />
          <span />
          <Link to="/privacy" target="_blank" rel="noopener noreferrer" />
          <span />
        </Trans>
      </StyledDisclaimer>
    </Modal>
  );
};

export default TrialApplicationModal;
