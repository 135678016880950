import TrialApplication from 'common/types/TrialApplication';
import TrialSearchResult from 'common/types/TrialSearchResult';
import { BASE_URL } from './constants';
import authenticatedFetch from './util/authenticatedFetch';

export type TrialAndApplication = {
  trial: TrialSearchResult;
  trial_application: TrialApplication;
};

export type TrialsAppliedForResponse = {
  trials: TrialAndApplication[];
  page: number;
  pages: number;
  per_page: number;
  total: number;
};

export type ArchiveTrialApplicationResponse = {
  success: boolean;
};

/**
 * Get trials applied for by the current user
 * @param {number} page The page to retrieve
 * @param {boolean} archived Whether or not to query archived trials
 * @param {number} perPage The the number of results to include per page
 * @returns {Promise<TrialsAppliedForResponse | null>} The trials applied for by the current user
 */
export async function getTrialsAppliedFor(
  page: number = 1,
  archived: boolean = false,
  perPage: number = 10,
): Promise<TrialsAppliedForResponse | null> {
  const response = await authenticatedFetch(
    `${BASE_URL}/trials_applied_for?page=${page}&per_page=${perPage}&archived=${archived}`,
  );

  if (!response.ok) {
    return null;
  }

  return response.json();
}

/**
 * Mark a trial application as archived/un-archived
 * @param {string} id The ID of the trial application
 * @param {boolean} isArchived Whether the trial is archived or not
 * @returns {Promise<LikeTrialResponse | null>} Whether or not the trial application was successfully updated
 */
export async function setTrialApplicationArchived(
  id: string,
  isArchived: boolean,
): Promise<ArchiveTrialApplicationResponse | null> {
  const headers: HeadersInit = new Headers();
  headers.set('Content-Type', 'application/json');
  const response = await authenticatedFetch(
    `${BASE_URL}/trials_applied_for/${id}`,
    {
      method: 'POST',
      headers,
      body: JSON.stringify({
        archived: isArchived,
      }),
    },
  );

  if (!response.ok) {
    return null;
  }
  return response.json();
}
