/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import Button from 'components/Button';
import { ButtonProps, StyledButtonProps } from 'components/Button/Button';

// Extract Button styling props to avoid passing it to the dom anchor element
// eslint-disable-next-line react/prop-types
const ButtonPropsExtractedAnchor = ({
  children,
  dark,
  hasIcon,
  small,
  ...rest
}: StyledButtonProps & React.ComponentProps<'a'>) => (
  <a {...rest}>{children}</a>
);

// Component
const ButtonAnchor = ({
  children,
  ...rest
}: ButtonProps & React.ComponentProps<'a'>) => (
  <Button as={ButtonPropsExtractedAnchor} {...rest}>
    {children}
  </Button>
);

export default ButtonAnchor;
