import { EVENT_PAGE_VIEW, gtmPush } from 'common/analytics/gtm';
import { PATHS } from 'common/constants';
import bcCancerPilotData from 'common/data/bc_trial_data';
import covid19PilotData from 'common/data/covid_19_trial_data';
import TrialResponse from 'common/types/TrialResponse';
import FooterBar from 'components/FooterBar';
import HeaderBar from 'components/HeaderBar';
import TrialPage from 'components/TrialPage';
import About from 'modules/About';
import Contact from 'modules/Contact';
import CovidLanding from 'modules/CovidLanding';
import Dashboard from 'modules/Dashboard';
import Faq from 'modules/Faq';
import ForgotPassword from 'modules/ForgotPassword';
import MagicLink from 'modules/MagicLink';
import PrivacyPolicy from 'modules/PrivacyPolicy';
import ResetPassword from 'modules/ResetPassword';
import Search from 'modules/Search';
import SearchCovid from 'modules/SearchCovid';
import SignIn from 'modules/SignIn';
import SignInMagic from 'modules/SignInMagic';
import SignUp from 'modules/SignUp';
import TermsOfService from 'modules/TermsOfService';
import Trial from 'modules/Trial';
import TrialCovid from 'modules/TrialCovid';
import queryString from 'query-string';
import { useEffect } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useLocation,
} from 'react-router-dom';
import styled from 'styled-components/macro';

// Styled Components
const StyledHealMary = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 100vh;
`;

// Component
const HealMary = () => {
  const location = useLocation();

  // Scroll to top on location change - react-router doesn't handle this natively
  useEffect(() => {
    // Skip scrolling to top when navigating on the covid search to allow for
    // dynamically updating the filters in the URL without causing scrolling
    if (location.pathname === '/covidsearch' && location.search !== '') {
      return;
    }

    // Also skip scrolling on the filters step of search
    const searchParams = queryString.parse(location.search);
    if (
      location.pathname === PATHS.SEARCH &&
      (searchParams['step'] as string) === '5'
    ) {
      return;
    }
    window.scrollTo(0, 0);
  }, [location]);

  const getPilotRender =
    (trialData: TrialResponse) => (props: RouteComponentProps) => {
      const loadTrial = async () => trialData;

      return <TrialPage loadTrial={loadTrial} {...props} />;
    };

  // Redirect old Results page to the search page at step 5 (viewing results)
  const redirectOldResults = ({
    location: { search },
  }: RouteComponentProps) => <Redirect to={`search${search}&step=5`} />;

  gtmPush({
    event: EVENT_PAGE_VIEW,
    page: {
      path: location,
    },
  });

  // While SquareSpace is used, APP home should redirect to search
  const RedirectToSearch = () => <Redirect to={PATHS.SEARCH} />;

  return (
    <StyledHealMary>
      <HeaderBar />
      <Switch>
        <Route path={PATHS.MAGIC_LINK} component={MagicLink} />
        <Route path={PATHS.ABOUT} component={About} />
        <Route
          path="/bccancerpilot"
          render={getPilotRender(bcCancerPilotData)}
        />
        <Route path="/covid19pilot" render={getPilotRender(covid19PilotData)} />
        <Route path={PATHS.COVID} component={CovidLanding} />
        <Route path={PATHS.COVID_SEARCH} component={SearchCovid} />
        <Route path="/covidtrials/:id" component={TrialCovid} />
        <Route path={PATHS.CONTACT} component={Contact} />
        <Route path={PATHS.DASHBOARD} component={Dashboard} />
        <Route path={PATHS.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route path="/home" component={RedirectToSearch} />
        <Route path={PATHS.LEGACY_RESULTS} component={redirectOldResults} />
        <Route path={PATHS.SEARCH} component={Search} />
        <Route path="/trials/:id" component={Trial} />
        <Route path={PATHS.PRIVACY} component={PrivacyPolicy} />
        <Route path={PATHS.RESET_PASSWORD} component={ResetPassword} />
        <Route path={PATHS.SIGN_IN} component={SignIn} />
        <Route path={PATHS.SIGN_IN_MAGIC} component={SignInMagic} />
        <Route path={PATHS.SIGN_UP} component={SignUp} />
        <Route path={PATHS.TERMS} component={TermsOfService} />
        <Route path={PATHS.FAQ} component={Faq} />
        <Route path={PATHS.HOME} component={RedirectToSearch} />
      </Switch>
      <FooterBar />
    </StyledHealMary>
  );
};

export default HealMary;
