import TrialIntervention from 'common/types/TrialIntervention';
import { Trans, useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';

import iconBehavioralImg from './assets/icon-behavioral.svg';
import iconBiologicalImg from './assets/icon-biological.svg';
import iconCombinationProductImg from './assets/icon-combinationproduct.svg';
import iconDeviceImg from './assets/icon-device.svg';
import iconDiagnosticTestImg from './assets/icon-diagnostictest.svg';
import iconDietarySupplementImg from './assets/icon-dietarysupplements.svg';
import iconDrugImg from './assets/icon-drug.svg';
import iconGeneticImg from './assets/icon-genetic.svg';
import iconProcedureImg from './assets/icon-procedure.svg';
import iconRadiationImg from './assets/icon-radiation.svg';

// Styled Components
const StyledInterventionIconSet = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid ${(props) => props.theme.colors.accentBorder};
  border-radius: 200px;
`;

const StyledIcon = styled.img``;

const StyledPlusMore = styled.span`
  color: ${(props) => props.theme.colors.lightButtonText};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.xsmall};
`;

const INTERVENTION_TYPES = {
  Behavioral: {
    i18key: 'common:intervention_behavioral',
    icon: iconBehavioralImg,
  },
  Biological: {
    i18key: 'common:intervention_biological',
    icon: iconBiologicalImg,
  },
  'Combination Product': {
    i18key: 'common:intervention_combination_product',
    icon: iconCombinationProductImg,
  },
  Device: {
    i18key: 'common:intervention_device',
    icon: iconDeviceImg,
  },
  'Diagnostic Test': {
    i18key: 'common:intervention_diagnostic_test',
    icon: iconDiagnosticTestImg,
  },
  'Dietary Supplement': {
    i18key: 'common:intervention_dietary_supplement',
    icon: iconDietarySupplementImg,
  },
  Drug: {
    i18key: 'common:intervention_drug',
    icon: iconDrugImg,
  },
  Genetic: {
    i18key: 'common:intervention_genetic',
    icon: iconGeneticImg,
  },
  Procedure: {
    i18key: 'common:intervention_procedure',
    icon: iconProcedureImg,
  },
  Radiation: {
    i18key: 'common:intervention_radiation',
    icon: iconRadiationImg,
  },
};

export type InterventionIconSetProps = {
  interventions: TrialIntervention[];
  maxIcons?: number;
  theme?: DefaultTheme;
};

const InterventionIconSet = ({
  interventions,
  maxIcons = 2,
  ...rest
}: InterventionIconSetProps) => {
  const { t } = useTranslation();

  const distinctTypes = interventions.reduce((distinctTypes, intervention) => {
    if (!distinctTypes.includes(intervention.intervention_type)) {
      distinctTypes.push(intervention.intervention_type);
    }
    return distinctTypes;
  }, [] as string[]);

  const distinctIconTypes = distinctTypes.filter((type) =>
    Object.keys(INTERVENTION_TYPES).includes(type),
  );
  const iconTypes = distinctIconTypes.slice(0, maxIcons);
  const hiddenTypes = distinctIconTypes.slice(maxIcons);

  return (
    <StyledInterventionIconSet {...rest}>
      {iconTypes.map((type) => {
        const interventionType =
          INTERVENTION_TYPES[type as keyof typeof INTERVENTION_TYPES];
        const localizedName = t(interventionType.i18key);
        return (
          <StyledIcon
            key={type}
            src={interventionType.icon}
            alt={localizedName}
            title={localizedName}
          />
        );
      })}
      {iconTypes.length < distinctIconTypes.length && (
        <StyledPlusMore
          title={hiddenTypes
            .map((hiddenType) =>
              t(
                INTERVENTION_TYPES[
                  hiddenType as keyof typeof INTERVENTION_TYPES
                ].i18key,
              ),
            )
            .join(', ')}
        >
          <Trans
            i18nKey="common:plus_number"
            values={{ number: distinctIconTypes.length - iconTypes.length }}
          />
        </StyledPlusMore>
      )}
    </StyledInterventionIconSet>
  );
};

export default InterventionIconSet;
