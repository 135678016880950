import React from 'react';

const PUNCTUATION = [
  ' ',
  '.',
  ',',
  '!',
  '?',
  '@',
  '#',
  '%',
  '$',
  '^',
  '&',
  '*',
  ':',
  ';',
  '-',
  '+',
  '=',
  '"',
  "'",
  '(',
  ')',
  '[',
  ']',
  '<',
  '>',
  '\\',
  '/',
  '\n',
];

export type TokenOccurrence = {
  index: number;
  token: string;
};

export type TokenizedTextProps = {
  children: string;
  tokens: string[];
  wrap: (text: string, token: string) => React.ReactNode;
};

// Component
const TokenizedText = ({ children, tokens, wrap }: TokenizedTextProps) => {
  const tokenOccurrences: TokenOccurrence[] = [];
  const childrenLower = children.toLowerCase();

  tokens.forEach((token) => {
    const tokenLower = token.toLowerCase();
    const tokenLen = token.length;

    let index = childrenLower.indexOf(tokenLower);
    while (index !== -1) {
      const leadingChar = index > 0 ? childrenLower.substr(index - 1, 1) : '';
      const trailingChar = childrenLower.substr(index + tokenLower.length, 1);

      // Only include whole words
      if (
        (!leadingChar || PUNCTUATION.includes(leadingChar)) &&
        (!trailingChar || PUNCTUATION.includes(trailingChar))
      ) {
        tokenOccurrences.push({
          index,
          token,
        });
      }

      index = childrenLower.indexOf(tokenLower, index + tokenLen);
    }
  });

  // Sort the occurrences
  const sortedTokenOccurrences = tokenOccurrences.sort(
    (a, b) => a.index - b.index,
  );

  // Remove overlapping tokens
  const filteredTokenOccurrences: TokenOccurrence[] = [];
  const toFilter = [...sortedTokenOccurrences];
  let current = toFilter.shift();
  while (current !== undefined && toFilter.length > 0) {
    const next = toFilter.shift();
    if (
      next !== undefined &&
      current.index + current.token.length > next.index
    ) {
      // If the next token overlaps and is longer, skip the current and continue (otherwise the next is skipped)
      if (next.token.length > current.token.length) {
        current = next;
      }
    } else {
      // No more overlaps, add the current and move on to the next
      filteredTokenOccurrences.push(current);
      current = next;
    }
  }
  if (current) {
    filteredTokenOccurrences.push(current);
  }

  // Create an array of elements wrapping the tokenized content
  const content: React.ReactNode[] = [];
  let offset = 0;
  filteredTokenOccurrences.forEach((occurrence) => {
    content.push(children.substring(offset, occurrence.index));
    content.push(
      <span key={`${occurrence.token}_${occurrence.index}`}>
        {wrap(
          children.substring(
            occurrence.index,
            occurrence.index + occurrence.token.length,
          ),
          occurrence.token,
        )}
      </span>,
    );

    offset = occurrence.index + occurrence.token.length;
  });

  // Push the rest of the content
  content.push(children.substring(offset));

  return <>{content}</>;
};

export default TokenizedText;
