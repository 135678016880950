/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import LocationGroupings from 'common/types/LocationGroupings';

import DetailsList from 'components/DetailsList';
import DetailsSubList from 'components/DetailsSubList';
import FacilityDetails from 'components/FacilityDetails';
import Modal from 'components/Modal';
import ResourceLinks from 'components/ResourceLinks';
import SectionSubheading from 'components/SectionSubheading';
import TextButton from 'components/TextButton';

// Styled Components
const StyledResourceSection = styled.div`
  margin-top: 10px;
`;

export type LocationListModalProps = {
  locationsByCountryState: LocationGroupings;
  onClose: () => void;
  theme?: DefaultTheme;
};

// Component
const LocationListModal = ({
  locationsByCountryState,
  onClose,
}: LocationListModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      controls={<TextButton onClick={onClose}>{t('common:close')}</TextButton>}
      heading={t('trial:all_locations')}
    >
      <DetailsList>
        {Object.keys(locationsByCountryState).map((country) =>
          Object.keys(locationsByCountryState[country]).map((state) => (
            <li key={`${country}_${state}`}>
              <SectionSubheading>
                {state === 'other' ? `${country}` : `${country}, ${state}`}
              </SectionSubheading>
              <DetailsSubList>
                {locationsByCountryState[country][state].map(
                  ({ facility = null, links = [] }, index) =>
                    facility && (
                      <li key={`facility-${index}`}>
                        <FacilityDetails facility={facility} />
                        {links && links.length > 0 && (
                          <StyledResourceSection>
                            <ResourceLinks links={links} />
                          </StyledResourceSection>
                        )}
                      </li>
                    ),
                )}
              </DetailsSubList>
            </li>
          )),
        )}
      </DetailsList>
    </Modal>
  );
};

LocationListModal.propTypes = {
  locationsByCountryState: PropTypes.shape({}).isRequired,

  onClose: PropTypes.func.isRequired,
};

export default LocationListModal;
