import TrialSearchResult from 'common/types/TrialSearchResult';
import { BASE_URL } from './constants';
import authenticatedFetch from './util/authenticatedFetch';

export type LikedTrialsResponse = {
  trials: TrialSearchResult[];
  page: number;
  pages: number;
  per_page: number;
  total: number;
};

export type LikeTrialResponse = {
  id?: string;
  success: boolean;
};

export type UnlikeTrialResponse = {
  success: boolean;
};

export type LikedTrialResponse = {
  id: string;
  created: string;
  trial_id: string;
};

/**
 * Get liked trials for the current user
 * @param {number} page The page to retrieve
 * @param {number} perPage The number of results to include on each page
 * @returns {Promise<LikedTrialsResponse | null>} The trials liked by the current user
 */
export async function getLikedTrials(
  page: number = 1,
  perPage: number = 10,
): Promise<LikedTrialsResponse | null> {
  const response = await authenticatedFetch(
    `${BASE_URL}/liked_trials?page=${page}&per_page=${perPage}`,
  );

  if (!response.ok) {
    return null;
  }
  return response.json();
}

/**
 * Mark a trial as liked
 * @param {string} id The ID of the trial
 * @returns {Promise<LikeTrialResponse | null>} Whether or not the trial was successfully liked
 */
export async function likeTrial(id: string): Promise<LikeTrialResponse | null> {
  const headers: HeadersInit = new Headers();
  headers.set('Content-Type', 'application/json');
  const response = await authenticatedFetch(`${BASE_URL}/liked_trials`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      trial_id: id,
    }),
  });

  if (!response.ok) {
    return null;
  }
  return response.json();
}

/**
 * Un-mark a trial as liked
 * @param {string} id The ID of the trial
 * @returns {Promise<UnlikeTrialResponse | null>} Whether or not the trial was successfully un-liked
 */
export async function unlikeTrial(
  id: string,
): Promise<UnlikeTrialResponse | null> {
  const response = await authenticatedFetch(`${BASE_URL}/liked_trials/${id}`, {
    method: 'DELETE',
  });

  if (!response.ok) {
    return null;
  }
  return response.json();
}

/**
 * Check if a trial is liked by the current user
 * @param {string} id The ID of the trial
 * @returns {Promise<boolean>} Whether or not the trial is currently liked
 */
export async function isTrialLiked(id: string): Promise<boolean> {
  const response = await authenticatedFetch(`${BASE_URL}/liked_trials/${id}`);

  if (!response.ok) {
    return false;
  }

  const isLikedResponse = (await response.json()) as LikedTrialResponse;
  return isLikedResponse.id !== undefined;
}
