import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';

// Styled Components
const StyledFaq = styled.main`
  ${(props) => props.theme.paddedContent};
  max-width: 900px;
  margin: 60px auto;
`;

export type FaqProps = {
  theme?: DefaultTheme;
};

// Component
// Need to include props to support StyledComponents useTheme
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Faq = (props: FaqProps) => {
  const { t } = useTranslation(['faq']);

  return (
    <StyledFaq className="infopage">
      <Helmet>
        <title>{t('faq:browser_title')}</title>
      </Helmet>

      <h1>{t('faq:frequently_asked_questions')}</h1>

      <section>
        <h2>{t('faq:what_is_a_clinical_trial')}</h2>
        <p>{t('faq:what_is_a_clinical_trial_answer')}</p>

        <h2>{t('faq:why_would_i_participate')}</h2>
        <p>
          <Trans i18nKey="faq:why_would_i_participate_answer">
            <span />
            <ul>
              <li />
              <li />
              <li />
            </ul>
          </Trans>
        </p>

        <h2>{t('faq:why_are_clinical_trials_important')}</h2>
        <Trans i18nKey="faq:why_are_clinical_trials_important_answer">
          <p />
          <p />
        </Trans>

        <h2>{t('faq:what_are_the_types')}</h2>
        <p>
          <Trans i18nKey="faq:what_are_the_types_answer">
            <span>
              <a
                href="https://www.cancerresearchuk.org/about-cancer/find-a-clinical-trial/what-clinical-trials-are/types-of-clinical-trials"
                target="_blank"
                rel="noopener noreferrer"
              >
                CancerResearch UK
              </a>
            </span>
            <ul>
              <li />
              <li />
              <li />
            </ul>
          </Trans>
        </p>

        <h2>{t('faq:what_is_sponsor')}</h2>
        <p>{t('faq:what_is_sponsor_answer')}</p>

        <h2>{t('faq:how_to_get_in_touch')}</h2>
        <Trans i18nKey="faq:how_to_get_in_touch_answer">
          <p>
            <a
              href="mailto:hello@healmaryapp.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              hello@healmaryapp.com
            </a>
          </p>
        </Trans>

        <h2>{t('faq:what_should_i_do_to_take_part')}</h2>
        <p>{t('faq:what_should_i_do_to_take_part_answer')}</p>

        <h2>{t('faq:how_does_heal_mary_make_money')}</h2>
        <p>{t('faq:how_does_heal_mary_make_money_answer')}</p>
      </section>
    </StyledFaq>
  );
};

export default Faq;
