import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { ELIGIBILITY_VALUES } from 'common/constants';
import EligibilityChecklistItem from 'common/types/EligibilityChecklistItem';

import ClickableDefinitions from 'components/ClickableDefinitions';

import checkImg from './assets/check.svg';

const { YES, NO, UNSURE } = ELIGIBILITY_VALUES;

// Styled Components
const StyledItem = styled.div<{ hasError: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 25px 20px;
  margin-top: 5px;

  font-size: ${(props) => props.theme.fontSizes.small};
  background-color: ${(props) => props.theme.rawColors.offWhite};

  border: 1px solid transparent;

  white-space: pre-line;

  ${(props) =>
    props.hasError &&
    css`
      border-color: ${props.theme.colors.error};
      color: ${props.theme.colors.error};
    `}

  @media ${(props) => props.theme.devices.mobile} {
    flex-direction: column;
    align-items: stretch;
  }
`;

const StyledOptionBox = styled.div`
  width: 20px;
  height: 20px;

  text-align: center;

  background-color: ${(props) => props.theme.rawColors.white};
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  box-shadow: 0 0 10px -5px ${(props) => props.theme.rawColors.black20};
  border-radius: 5px;

  @media ${(props) => props.theme.devices.mobile} {
    display: inline-block;
  }
`;

const StyledOptions = styled.div`
  white-space: nowrap;

  @media ${(props) => props.theme.devices.mobile} {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
  }

  label {
    display: inline-block;
    margin-left: 40px;
    font-weight: ${(props) => props.theme.fontWeights.bold};
    font-size: ${(props) => props.theme.fontSizes.xsmall};

    cursor: pointer;

    @media ${(props) => props.theme.devices.mobile} {
      display: flex;
      margin-left: 0;
    }

    input {
      opacity: 0;
      width: 0;
      height: 0;
      margin: 0;
      padding: 0;

      &:focus + ${StyledOptionBox} {
        border-color: ${(props) => props.theme.colors.active};
      }
    }

    span {
      display: block;
      margin-top: 10px;

      @media ${(props) => props.theme.devices.mobile} {
        display: inline-block;
        margin-top: 0;
        margin-left: 10px;
      }
    }
  }
`;

export type ItemProps = {
  id: string;
  index: number;
  item: EligibilityChecklistItem;
  onValueChange: (index: number, value: string) => void;
  refFunc: (index: number, ref: HTMLDivElement | null) => void;
  theme?: DefaultTheme;
};

// Component
const Item = ({
  id,
  index,
  item: { hasError = false, text, value },

  onValueChange,
  refFunc,
}: ItemProps) => {
  const { t } = useTranslation(['trialEligibility']);

  return (
    <StyledItem
      key={text}
      hasError={hasError}
      ref={(ref) => refFunc(index, ref)}
    >
      <ClickableDefinitions>
        {text.replace(/\$/g, '\n - ')}
      </ClickableDefinitions>
      <StyledOptions>
        <label htmlFor={`${id}_${YES}`}>
          <input
            id={`${id}_${YES}`}
            type="radio"
            name={`${id}_value`}
            value={YES}
            checked={value === YES}
            onChange={() => onValueChange(index, YES)}
          />
          <StyledOptionBox>
            {value === YES && <img src={checkImg} alt="" />}
          </StyledOptionBox>
          <span>{t('trialEligibility:yes')}</span>
        </label>
        <label htmlFor={`${id}_${NO}`}>
          <input
            id={`${id}_${NO}`}
            type="radio"
            name={`${id}_value`}
            value={NO}
            checked={value === NO}
            onChange={() => onValueChange(index, NO)}
          />
          <StyledOptionBox>
            {value === NO && <img src={checkImg} alt="" />}
          </StyledOptionBox>
          <span>{t('trialEligibility:no')}</span>
        </label>
        <label htmlFor={`${id}_${UNSURE}`}>
          <input
            id={`${id}_${UNSURE}`}
            type="radio"
            name={`${id}_value`}
            value={UNSURE}
            checked={value === UNSURE}
            onChange={() => onValueChange(index, UNSURE)}
          />
          <StyledOptionBox>
            {value === UNSURE && <img src={checkImg} alt="" />}
          </StyledOptionBox>
          <span>{t('trialEligibility:unsure')}</span>
        </label>
      </StyledOptions>
    </StyledItem>
  );
};

export default Item;
