/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import styled, { css, DefaultTheme } from 'styled-components/macro';

import notifyParent from 'common/util/notifyParent';
import ContentCard from 'components/ContentCard';

// Styled Components
const StyledModal = styled.div<{ depth: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: ${(props) => props.theme.rawColors.black50};

  z-index: ${(props) => props.depth * 100};
`;

const StyledModalContainer = styled(ContentCard)<{ small: boolean }>`
  display: flex;
  flex-direction: column;

  width: 98%;
  max-width: ${(props) => (props.small ? '600px' : '800px')};
  margin: 40px auto;
  padding: 0;
  background-color: ${(props) => props.theme.colors.background};

  overflow: hidden;

  @media ${(props) => props.theme.devices.mobile} {
    border-radius: 20px;
  }
`;

const StyledModalScrollContainer = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const StyledModalContent = styled.div<{
  isScrollable: boolean;
  hasControls: boolean;
}>`
  padding-left: 40px;
  padding-right: 40px;
  ${(props) =>
    props.isScrollable
      ? css`
          padding-top: 40px;
        `
      : css`
          padding-top: 20px;
        `}
  ${(props) =>
    !props.hasControls &&
    css`
      padding-bottom: 40px;
    `}

  @media ${(props) => props.theme.devices.mobile} {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const StyledHeader = styled.div<{ isScrollable: boolean }>`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 20px 40px;

  ${(props) =>
    props.isScrollable &&
    css`
      box-shadow: 0 0 20px -5px ${props.theme.rawColors.black20};
    `}

  @media ${(props) => props.theme.devices.mobile} {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const StyledHeading = styled.h2`
  text-align: center;
`;

const StyledSubheading = styled.span`
  margin-top: 10px;
  text-align: center;
`;

const StyledControls = styled.div<{ isScrollable: boolean }>`
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 30px;
  padding-bottom: 30px;

  z-index: 100;

  ${(props) =>
    props.isScrollable &&
    css`
      box-shadow: 0 0 20px -5px ${props.theme.rawColors.black20};
    `}
`;

export type ModalProps = {
  controls?: React.ReactNode;
  heading?: React.ReactNode;
  depth?: number;
  small?: boolean;
  subheading?: React.ReactNode;
  theme?: DefaultTheme;
  onClose?: () => void;
} & Omit<React.ComponentProps<'div'>, 'ref'>;

// Component
const Modal = React.forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      children,
      controls = null,
      depth = 1,
      heading,
      small = false,
      subheading,
      onClose,
      ...rest
    }: ModalProps,
    ref,
  ) => {
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);
    const [isScrollable, setIsScrollable] = useState<boolean>(false);

    function onResize() {
      // IE11: The +1 is to add a 1 pixel tollerance (scrollHeight is clientHeight + 1 when there is no scroll on IE11)
      if (
        scrollContainerRef.current &&
        scrollContainerRef.current.scrollHeight >
          scrollContainerRef.current.clientHeight + 1
      ) {
        setIsScrollable(true);
      } else {
        setIsScrollable(false);
      }
    }
    useEffect(() => {
      function onKeyPress(evt: KeyboardEvent) {
        if (onClose && evt.key === 'Escape') {
          onClose();
        }
      }
      window.addEventListener('resize', onResize);
      window.addEventListener('keydown', onKeyPress);
      onResize();

      return () => {
        window.removeEventListener('resize', onResize);
        window.removeEventListener('keydown', onKeyPress);
      };
    }, [onClose]);

    useEffect(() => {
      notifyParent({ action: 'modal', data: { isShown: true } });

      return () => {
        notifyParent({ action: 'modal', data: { isShown: false } });
      };
    }, []);

    return (
      <StyledModal depth={depth} ref={ref} {...rest}>
        <StyledModalContainer small={small}>
          {(heading || subheading) && (
            <StyledHeader isScrollable={isScrollable}>
              {heading && <StyledHeading>{heading}</StyledHeading>}
              {subheading && <StyledSubheading>{subheading}</StyledSubheading>}
            </StyledHeader>
          )}

          <StyledModalScrollContainer ref={scrollContainerRef}>
            <StyledModalContent
              hasControls={controls !== null}
              isScrollable={isScrollable}
            >
              {children}
            </StyledModalContent>
          </StyledModalScrollContainer>

          {controls && (
            <StyledControls isScrollable={isScrollable}>
              {controls}
            </StyledControls>
          )}
        </StyledModalContainer>
      </StyledModal>
    );
  },
);

export default Modal;
