import * as Sentry from '@sentry/react';
import {
  getTrialsAppliedFor,
  setTrialApplicationArchived,
  TrialsAppliedForResponse,
} from 'common/api/trialsAppliedFor';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import PageController from 'components/PageController';
import PillToggle from 'components/PillToggle';
import useIsMounted from 'hooks/useIsMounted';
import AppliedTrialsList from 'modules/Dashboard/components/AppliedTrialsList';
import DashboardPageHeading from 'modules/Dashboard/components/DashboardPageHeading';
import NoTrials from 'modules/Dashboard/components/NoTrials';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledAppliedTrials = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledHeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 64px;

  @media ${(props) => props.theme.devices.mobile} {
    flex-direction: column;
    align-items: center;
    gap: 0;
    margin-bottom: 32px;

    h1 {
      width: 100%;
    }
  }
`;

const StyledAppliedForTrialsContent = styled.div`
  ${(props) => props.theme.dashboardContent}
`;

const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
`;

const StyledTrials = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;

  @media ${(props) => props.theme.devices.tablet} {
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;

const StyledNoTrials = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledPageControls = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 40px;

  @media ${(props) => props.theme.devices.mobile} {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

enum TrialApplicationStateEnum {
  active = 'active',
  archived = 'archived',
}

export type AppliedTrialsProps = {
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'div'>;

const AppliedTrials = ({ ...rest }: AppliedTrialsProps) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [areTrialsChecked, setAreTrialsChecked] = useState<boolean>(false);
  const [trialsAppliedForDetails, setTrialsAppliedForResponse] =
    useState<TrialsAppliedForResponse | null>(null);
  const [page, setPage] = useState<number>(1);
  const [applicationState, setApplicationState] = useState<string>(
    TrialApplicationStateEnum.active,
  );

  useEffect(() => {
    const loadAppliedForTrials = async () => {
      try {
        const trialsAppliedForResponse = await getTrialsAppliedFor(
          page,
          applicationState === TrialApplicationStateEnum.archived,
        );
        if (!isMounted.current || !trialsAppliedForResponse) {
          return;
        }
        setAreTrialsChecked(true);
        setTrialsAppliedForResponse(trialsAppliedForResponse);
      } catch (ex) {
        setAreTrialsChecked(true);
      }
    };

    loadAppliedForTrials();
  }, [isMounted, page, applicationState]);

  const onSetArchived = async (
    trialApplicationId: string,
    isArchived: boolean,
  ) => {
    try {
      await setTrialApplicationArchived(trialApplicationId, isArchived);
      // If the last trial of the current page was removed, navigate back a page
      // which will refresh the details automatically
      if (trialsAppliedForDetails?.trials.length === 1 && page > 1) {
        setPage(page - 1);
        return;
      }
      const trialsAppliedForResponse = await getTrialsAppliedFor(
        page,
        applicationState === TrialApplicationStateEnum.archived,
      );
      if (!isMounted.current || !trialsAppliedForResponse) {
        return;
      }
      setTrialsAppliedForResponse(trialsAppliedForResponse);
    } catch (ex) {
      Sentry.captureException(ex);
    }
  };

  const onApplicationStateChange = (value: string) => {
    setApplicationState(value);
    setPage(1);
  };

  return (
    <StyledAppliedTrials {...rest}>
      <StyledAppliedForTrialsContent>
        {!areTrialsChecked ? (
          <StyledLoading>{t('common:loading')}</StyledLoading>
        ) : (
          <StyledTrials>
            <StyledHeaderSection>
              <DashboardPageHeading>
                {t('appliedTrials:trials_ive_applied_for')}
              </DashboardPageHeading>
              <PillToggle
                name="application_state"
                options={[
                  {
                    label: t('appliedTrials:active'),
                    value: TrialApplicationStateEnum.active,
                  },
                  {
                    label: t('appliedTrials:archived'),
                    value: TrialApplicationStateEnum.archived,
                  },
                ]}
                value={applicationState}
                onValueChange={onApplicationStateChange}
              />
            </StyledHeaderSection>
            {trialsAppliedForDetails?.trials &&
            trialsAppliedForDetails.trials.length > 0 ? (
              <>
                <AppliedTrialsList
                  areArchived={
                    applicationState === TrialApplicationStateEnum.archived
                  }
                  trials={trialsAppliedForDetails?.trials ?? []}
                  onArchive={(id: string) => onSetArchived(id, true)}
                  onUnarchive={(id: string) => onSetArchived(id, false)}
                />
                <StyledPageControls>
                  <PageController
                    page={trialsAppliedForDetails?.page ?? 0}
                    pageCount={trialsAppliedForDetails?.pages ?? 0}
                    onPageSelect={setPage}
                  />
                </StyledPageControls>
              </>
            ) : (
              <StyledNoTrials>
                <NoTrials
                  message={
                    applicationState === TrialApplicationStateEnum.archived
                      ? t('appliedTrials:no_archived_trials')
                      : t('appliedTrials:no_trials_applied_for')
                  }
                />
              </StyledNoTrials>
            )}
          </StyledTrials>
        )}
      </StyledAppliedForTrialsContent>
      <LoadingOverlay isShown={!areTrialsChecked} />
    </StyledAppliedTrials>
  );
};

export default AppliedTrials;
