import {
  CancerConditionDetails,
  Condition,
  ConditionTypeEnum,
  OtherConditionDetails,
} from 'common/api/conditions';
import Button from 'components/Button';
import TextButton from 'components/TextButton';
import { CONDITIONS } from 'modules/Search/constants';
import { Trans, useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledUserConditionSelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const StyledHeading = styled.h1`
  font-size: ${(props) => props.theme.fontSizes.h2};

  @media ${(props) => props.theme.devices.mobile} {
    font-size: ${(props) => props.theme.fontSizes.mobile.h2};
  }
`;

const StyledConditionsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0;
  padding: 0;
  list-style: none;

  @media ${(props) => props.theme.devices.mobile} {
    gap: 16px;
  }
`;

const StyledListItem = styled.li`
  button {
    width: 100%;
    max-width: 380px;
  }
`;

export type UserConditionSelectProps = {
  conditions: Condition[];
  conditionTypes?: ConditionTypeEnum[];
  onCancel: () => void;
  onConditionSelected: (condition: Condition) => void;
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'div'>;

const UserConditionSelect = ({
  conditions,
  conditionTypes = [ConditionTypeEnum.cancer, ConditionTypeEnum.other],
  onCancel,
  onConditionSelected,
  ...rest
}: UserConditionSelectProps) => {
  const { t } = useTranslation();

  return (
    <StyledUserConditionSelect {...rest}>
      <StyledHeading>
        {t('search:select_which_condition_to_search_with')}
      </StyledHeading>
      <StyledConditionsList>
        {conditions
          .filter((condition) => conditionTypes.includes(condition.type))
          .map((condition) => (
            <StyledListItem key={condition.id}>
              <Button dark onClick={() => onConditionSelected(condition)}>
                {condition.type === ConditionTypeEnum.cancer ? (
                  <Trans
                    i18nKey="common:cancer_type"
                    values={{
                      type: CONDITIONS[
                        (condition.details as CancerConditionDetails).condition
                      ]
                        ? t(
                            CONDITIONS[
                              (condition.details as CancerConditionDetails)
                                .condition
                            ].i18nKey,
                          )
                        : (condition.details as CancerConditionDetails)
                            .condition,
                    }}
                  />
                ) : (
                  (condition.details as OtherConditionDetails).condition
                )}
              </Button>
            </StyledListItem>
          ))}
      </StyledConditionsList>
      <TextButton onClick={onCancel}>{t('common:cancel')}</TextButton>
    </StyledUserConditionSelect>
  );
};

export default UserConditionSelect;
