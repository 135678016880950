import IntegratorClient from 'common/types/IntegratorClient';

export default function getUrlToShare(
  isEmbedded: boolean,
  integratorClient?: IntegratorClient,
) {
  const currentUrl = window.location.href;
  let urlToShare = currentUrl;
  if (isEmbedded && integratorClient && integratorClient.embed_url) {
    // Get the Heal Mary path without the integrator ID
    const hmPath = new URL(currentUrl).pathname.replace(
      `/${integratorClient.client_id}`,
      '',
    );
    urlToShare = `${integratorClient.embed_url}${
      integratorClient.embed_url.indexOf('?') === -1 ? '?' : '&'
    }hm_path=${hmPath}`;
  }

  return urlToShare;
}
