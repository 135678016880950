import { PATHS } from 'common/constants';
import AuthPage from 'components/AuthPage';
import MagicLinkForm from 'components/MagicLinkForm';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components/macro';
import accentImg from './assets/accent_image.jpg';

const StyledHeading = styled.h1`
  white-space: pre-line;
  margin-bottom: 16px;
`;

const StyledSignInForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledMessage = styled.div`
  margin-bottom: 40px;
`;

const StyledSignUp = styled.div`
  margin-top: 40px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  text-align: center;
`;

export type SignInMagicProps = {
  theme?: DefaultTheme;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SignInMagic = (props: SignInMagicProps) => {
  const { t } = useTranslation();

  return (
    <AuthPage accentImage={accentImg}>
      <StyledSignInForm>
        <StyledHeading>{t('signIn:welcome_back')}</StyledHeading>
        <StyledMessage>{t('signIn:magic_message')}</StyledMessage>

        <MagicLinkForm />
      </StyledSignInForm>
      <StyledSignUp>
        <Trans i18nKey="signIn:dont_have_an_account">
          <span />
          <Link to={PATHS.SIGN_UP} />
        </Trans>
      </StyledSignUp>
    </AuthPage>
  );
};

export default SignInMagic;
