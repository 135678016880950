import Profile from 'common/types/Profile';
import DashboardPageHeading from 'modules/Dashboard/components/DashboardPageHeading';
import NotificationPreferencesForm from 'modules/Dashboard/components/NotificationPreferencesForm';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledNotificationSettings = styled.div``;

const StyledHeading = styled(DashboardPageHeading)`
  @media ${(props) => props.theme.devices.tablet} {
    display: none;
  }
`;

export type NotificationSettingsProps = {
  profile: Profile;
  onProfileUpdated: (profile: Profile) => void;
  theme?: DefaultTheme;
};

const NotificationSettings = ({
  profile,
  onProfileUpdated,
  ...rest
}: NotificationSettingsProps) => {
  const { t } = useTranslation();

  return (
    <StyledNotificationSettings {...rest}>
      <StyledHeading>{t('profile:notification_settings')}</StyledHeading>
      <NotificationPreferencesForm
        currentNotificationTypes={profile.notification_types}
        onProfileUpdated={onProfileUpdated}
      />
    </StyledNotificationSettings>
  );
};

export default NotificationSettings;
