import * as Sentry from '@sentry/react';
import {
  getLikedTrials,
  LikedTrialsResponse,
  unlikeTrial,
} from 'common/api/likedTrials';
import { getResourceLinks, ResourceLink } from 'common/api/resourceLinks';
import { getNumberOfTrialsAdded } from 'common/api/trials';
import {
  getTrialsAppliedFor,
  setTrialApplicationArchived,
  TrialsAppliedForResponse,
} from 'common/api/trialsAppliedFor';
import { PATHS } from 'common/constants';
import LoadingOverlay from 'components/LoadingOverlay';
import ResourceLinksModal from 'components/ResourceLinksModal/ResourceLinksModal';
import useIsMounted from 'hooks/useIsMounted';
import AppliedTrialsList from 'modules/Dashboard/components/AppliedTrialsList';
import LikedTrialsList from 'modules/Dashboard/components/LikedTrialsList';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components/macro';

import iconStarImg from './assets/icon-star.svg';

// Styled Components
const StyledDashboardHome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 72px;
  margin-top: 80px;
  margin-bottom: 80px;

  @media ${(props) => props.theme.devices.mobile} {
    gap: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;

const StyledSection = styled.section`
  ${(props) => props.theme.dashboardContent}
`;

const StyledTiles = styled(StyledSection)`
  display: flex;
  gap: 40px;

  @media ${(props) => props.theme.devices.mobile} {
    gap: 16px;
  }
`;

const StyledTile = styled.div`
  flex: 1 1 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;

  box-sizing: border-box;
  height: 160px;
  padding: 8px;
  text-align: center;
  background-color: ${(props) => props.theme.colors.background};

  border: 2px solid ${(props) => props.theme.colors.inputBorder};
  box-shadow: 0 0 10px 0 ${(props) => props.theme.rawColors.black20};
  border-radius: 8px;};

  color: ${(props) => props.theme.colors.heading};
  font-weight: ${(props) => props.theme.fontWeights.bold};

  @media ${(props) => props.theme.devices.mobile} {
    gap: 8px;
    height: 128px;

    font-size: ${(props) => props.theme.fontSizes.small};
  }
`;

const StyledStat = styled.span`
  font-family: ${(props) => props.theme.fonts.title};
  font-size: ${(props) => props.theme.fontSizes.h1};
  line-height: 42px;

  @media ${(props) => props.theme.devices.mobile} {
    gap: 16px;
    font-size: ${(props) => props.theme.fontSizes.mobile.h1};
    line-height: 32px;
  }
`;

const StyledTileIcon = styled.img`
  width: 42px;
  height: 42px;

  @media ${(props) => props.theme.devices.mobile} {
    width: 32px;
    height: 32px;
  }
`;

const StyledHeadingSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;

  a {
    flex: 0 0 auto;
    font-size: ${(props) => props.theme.fontSizes.small};
  }
`;

const StyledSectionHeading = styled.h1`
  margin-bottom: 32px;
  font-size: ${(props) => props.theme.fontSizes.h4};

  @media ${(props) => props.theme.devices.mobile} {
    margin-bottom: 16px;
  }
`;

const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 80px 20px;
`;

const StyledNoTrials = styled.div``;

export type DashboardHomeProps = {
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'div'>;

const LATEST_TRIALS_COUNT = 3;

const DashboardHome = ({ ...rest }: DashboardHomeProps) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [areTrialsChecked, setAreTrialsChecked] = useState<boolean>(false);
  const [latestAppliedDetails, setLatestAppliedDetails] =
    useState<TrialsAppliedForResponse | null>(null);
  const [latestLikedDetails, setLatestLikedDetails] =
    useState<LikedTrialsResponse | null>(null);
  const [trialsAddedCount, setTrialsAddedCount] = useState<number | null>(null);
  const [resourceLinks, setResourceLinks] = useState<ResourceLink[] | null>(
    null,
  );
  const [isResourcesModalShown, setIsResourcesModalShown] =
    useState<boolean>(false);

  useEffect(() => {
    const loadResourceLinks = async () => {
      try {
        const resourceLinksResponse = await getResourceLinks();
        if (!isMounted.current || !resourceLinksResponse) {
          return;
        }
        setResourceLinks(resourceLinksResponse.resource_links);
      } catch (ex) {
        Sentry.captureException(ex);
      }
    };

    loadResourceLinks();
  }, [isMounted]);

  useEffect(() => {
    const loadTrials = async () => {
      // Get recent applied for trials
      try {
        const trialsAppliedForResponse = await getTrialsAppliedFor(
          1,
          false,
          LATEST_TRIALS_COUNT,
        );
        if (!isMounted.current || !trialsAppliedForResponse) {
          return;
        }
        setLatestAppliedDetails(trialsAppliedForResponse);
      } catch (ex) {
        Sentry.captureException(ex);
      }

      // Get recent liked trials
      try {
        const likedTrialsResponse = await getLikedTrials(
          1,
          LATEST_TRIALS_COUNT,
        );
        if (!isMounted.current || !likedTrialsResponse) {
          return;
        }
        setLatestLikedDetails(likedTrialsResponse);
      } catch (ex) {
        Sentry.captureException(ex);
      }

      setAreTrialsChecked(true);

      try {
        const trialsAddedResponse = await getNumberOfTrialsAdded();
        setTrialsAddedCount(trialsAddedResponse?.count ?? null);
      } catch (ex) {
        Sentry.captureException(ex);
      }
    };

    loadTrials();
  }, [isMounted]);

  const onArchiveTrialApplication = async (trialApplicationId: string) => {
    try {
      await setTrialApplicationArchived(trialApplicationId, true);
      const trialsAppliedForResponse = await getTrialsAppliedFor(
        1,
        false,
        LATEST_TRIALS_COUNT,
      );
      if (!isMounted.current || !trialsAppliedForResponse) {
        return;
      }
      setLatestAppliedDetails(trialsAppliedForResponse);
    } catch (ex) {
      Sentry.captureException(ex);
    }
  };

  const onUnlikeTrial = async (trialId: string) => {
    try {
      await unlikeTrial(trialId);
      const likedTrialsResponse = await getLikedTrials(1, LATEST_TRIALS_COUNT);
      if (!isMounted.current || !likedTrialsResponse) {
        return;
      }
      setLatestLikedDetails(likedTrialsResponse);
    } catch (ex) {
      Sentry.captureException(ex);
    }
  };

  return (
    <StyledDashboardHome {...rest}>
      <StyledTiles>
        <StyledTile>
          <Trans
            i18nKey={'dashboard:trials_added_since_last_login'}
            values={{ trialsAdded: trialsAddedCount ?? '-' }}
          >
            <StyledStat />
            <span />
          </Trans>
        </StyledTile>
        <StyledTile
          as={'button'}
          onClick={() => setIsResourcesModalShown(true)}
        >
          <StyledTileIcon src={iconStarImg} alt="" />
          <span>{t('dashboard:helpful_resources')}</span>
        </StyledTile>
      </StyledTiles>

      <StyledSection>
        <StyledHeadingSection>
          <StyledSectionHeading>
            {t('dashboard:latest_applied_trials')}
          </StyledSectionHeading>
          <Link to={PATHS.DASHBOARD_APPLIED_TRIALS}>
            {t('dashboard:see_all')}
          </Link>
        </StyledHeadingSection>
        {!areTrialsChecked ? (
          <StyledLoading>
            {t('common:loading')}
            <LoadingOverlay isShown={!areTrialsChecked} />
          </StyledLoading>
        ) : latestAppliedDetails?.trials &&
          latestAppliedDetails.trials.length > 0 ? (
          <AppliedTrialsList
            areArchived={false}
            trials={latestAppliedDetails?.trials ?? []}
            onArchive={onArchiveTrialApplication}
            onUnarchive={() => {}}
          />
        ) : (
          <StyledNoTrials>
            {t('appliedTrials:no_trials_applied_for')}
          </StyledNoTrials>
        )}
      </StyledSection>

      <StyledSection>
        <StyledHeadingSection>
          <StyledSectionHeading>
            {t('dashboard:latest_liked_trials')}
          </StyledSectionHeading>
          <Link to={PATHS.DASHBOARD_LIKED_TRIALS}>
            {t('dashboard:see_all')}
          </Link>
        </StyledHeadingSection>
        {!areTrialsChecked ? (
          <StyledLoading>
            {t('common:loading')}
            <LoadingOverlay isShown={!areTrialsChecked} />
          </StyledLoading>
        ) : latestLikedDetails?.trials &&
          latestLikedDetails.trials.length > 0 ? (
          <LikedTrialsList
            trials={latestLikedDetails.trials}
            onUnlikeTrial={onUnlikeTrial}
          />
        ) : (
          <StyledNoTrials>{t('likedTrials:no_liked_trials')}</StyledNoTrials>
        )}
      </StyledSection>
      {isResourcesModalShown && resourceLinks && (
        <ResourceLinksModal
          resourceLinks={resourceLinks}
          onClose={() => setIsResourcesModalShown(false)}
        />
      )}
    </StyledDashboardHome>
  );
};

export default DashboardHome;
