/* eslint-disable react/prop-types */
import CheckboxGroup from 'components/CheckboxGroup';
import { CheckboxGroupProps } from 'components/CheckboxGroup/CheckboxGroup';
import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledLegend = styled.legend<{ hasError: boolean }>`
  margin: 0;
  margin-bottom: 24px;
  padding: 0;
  color: ${(props) => props.theme.colors.heading};
  font-family: ${(props) => props.theme.fonts.title};
  font-size: ${(props) => props.theme.fontSizes.h4};
  font-weight: ${(props) => props.theme.fontWeights.bold};

  @media ${(props) => props.theme.devices.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.h4};
  }

  @media ${(props) => props.theme.devices.mobile} {
    font-size: ${(props) => props.theme.fontSizes.mobile.h4};
    margin-bottom: 20px;
  }

  ${(props) =>
    props.hasError &&
    css`
      color: ${props.theme.colors.error};
    `}
`;

export type LabeledCheckboxGroupProps = {
  hasError?: boolean;
  label: React.ReactNode;
  theme?: DefaultTheme;
} & CheckboxGroupProps;

const LabeledCheckboxGroup = React.forwardRef<
  HTMLLegendElement,
  LabeledCheckboxGroupProps
>(({ hasError = false, label, ...rest }: LabeledCheckboxGroupProps, ref) => (
  <CheckboxGroup
    legend={
      <StyledLegend hasError={hasError} ref={ref}>
        {label}
      </StyledLegend>
    }
    {...rest}
  />
));

export default LabeledCheckboxGroup;
