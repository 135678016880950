import * as Sentry from '@sentry/react';
import {
  getLikedTrials,
  LikedTrialsResponse,
  unlikeTrial,
} from 'common/api/likedTrials';
import LoadingOverlay from 'components/LoadingOverlay';
import PageController from 'components/PageController';
import useIsMounted from 'hooks/useIsMounted';
import DashboardPageHeading from 'modules/Dashboard/components/DashboardPageHeading';
import LikedTrialsList from 'modules/Dashboard/components/LikedTrialsList';
import NoTrials from 'modules/Dashboard/components/NoTrials';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledLikedTrials = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledLikedTrialsContent = styled.div`
  ${(props) => props.theme.dashboardContent}
`;

const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
`;

const StyledTrials = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;

  @media ${(props) => props.theme.devices.tablet} {
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;

const StyledNoTrials = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledPageControls = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 40px;

  @media ${(props) => props.theme.devices.mobile} {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

export type LikedTrialsProps = {
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'div'>;

const LikedTrials = ({ ...rest }: LikedTrialsProps) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [areTrialsChecked, setAreTrialsChecked] = useState<boolean>(false);
  const [likedTrialDetails, setLikedTrialDetails] =
    useState<LikedTrialsResponse | null>(null);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    const loadLikedTrials = async () => {
      try {
        const likedTrialsResponse = await getLikedTrials(page);
        if (!isMounted.current || !likedTrialsResponse) {
          return;
        }
        setAreTrialsChecked(true);
        setLikedTrialDetails(likedTrialsResponse);
      } catch (ex) {
        setAreTrialsChecked(true);
      }
    };

    loadLikedTrials();
  }, [isMounted, page]);

  const onUnlikeTrial = async (trialId: string) => {
    try {
      await unlikeTrial(trialId);
      // If the last trial of the current page was removed, navigate back a page
      // which will refresh the details automatically
      if (likedTrialDetails?.trials.length === 1 && page > 1) {
        setPage(page - 1);
        return;
      }
      const likedTrialsResponse = await getLikedTrials(page);
      if (!isMounted.current || !likedTrialsResponse) {
        return;
      }
      setLikedTrialDetails(likedTrialsResponse);
    } catch (ex) {
      Sentry.captureException(ex);
    }
  };

  return (
    <StyledLikedTrials {...rest}>
      <StyledLikedTrialsContent>
        {!areTrialsChecked ? (
          <StyledLoading>{t('common:loading')}</StyledLoading>
        ) : likedTrialDetails?.trials && likedTrialDetails.trials.length > 0 ? (
          <StyledTrials>
            <DashboardPageHeading>
              {t('likedTrials:liked_trials')}
            </DashboardPageHeading>
            <LikedTrialsList
              trials={likedTrialDetails.trials}
              onUnlikeTrial={onUnlikeTrial}
            />
            <StyledPageControls>
              <PageController
                page={likedTrialDetails.page ?? 0}
                pageCount={likedTrialDetails.pages}
                onPageSelect={setPage}
              />
            </StyledPageControls>
          </StyledTrials>
        ) : (
          <StyledNoTrials>
            <NoTrials message={t('likedTrials:no_liked_trials')} />
          </StyledNoTrials>
        )}
      </StyledLikedTrialsContent>
      <LoadingOverlay isShown={!areTrialsChecked} />
    </StyledLikedTrials>
  );
};

export default LikedTrials;
