import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';

import ButtonAnchor from 'components/ButtonAnchor';
import Divider from 'components/Divider';
import PageHeader from 'components/PageHeader';
import SubscribeForm from 'components/SubscribeForm';

import { CONTACT_EMAIL, CONTACT_PHONE, SURVEY_LINK } from 'common/constants';

import headerImg from './assets/header.jpg';
import mailImg from './assets/mail.svg';
import feedbackImg from './assets/feedback.svg';

// Styled Components
const StyledContact = styled.main``;

const StyledContent = styled.div`
  ${(props) => props.theme.paddedContent};
  max-width: 900px;
  margin: 0 auto;
`;

const StyledSection = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  margin-top: 60px;
  margin-bottom: 60px;

  @media ${(props) => props.theme.devices.mobile} {
    flex-direction: column;
    align-items: center;

    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 30px;
    padding-bottom: 30px;

    text-align: center;
  }
`;

const StyledIcon = styled.img`
  margin-right: 40px;
  min-width: 60px; /* Firefox Fix for stretching */
  width: 60px; /* IE11 Fix for stretching */

  @media ${(props) => props.theme.devices.mobile} {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const StyledSectionContent = styled.div`
  width: 100%; /* IE11 Fix for overflow */

  @media ${(props) => props.theme.devices.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledButtonAnchor = styled(ButtonAnchor)`
  display: inline-block;
  margin-top: 22px;

  @media ${(props) => props.theme.devices.mobile} {
    align-self: stretch;
  }
`;

export type ContactProps = {
  theme: DefaultTheme;
};

// Component
const Contact = ({ theme }: ContactProps) => {
  const { t } = useTranslation(['contact']);

  return (
    <StyledContact>
      <Helmet>
        <title>{t('contact:browser_title')}</title>
      </Helmet>
      <PageHeader backgroundImage={headerImg} color={theme.colors.lightText}>
        <h1>{t('contact:title')}</h1>
      </PageHeader>

      <StyledContent>
        <StyledSection>
          <StyledIcon src={mailImg} alt="" />
          <StyledSectionContent>
            <h1>{t('contact:contact_us')}</h1>
            <Divider />
            <p>
              <Trans
                i18nKey="contact:contact_us_email"
                values={{ email: CONTACT_EMAIL }}
              >
                &nbsp;
                <a href={`mailto:${CONTACT_EMAIL}`}>email</a>
              </Trans>
            </p>
            <p>
              <Trans
                i18nKey="contact:contact_us_phone"
                values={{ phone: CONTACT_PHONE }}
              />
            </p>
          </StyledSectionContent>
        </StyledSection>

        <StyledSection>
          <StyledIcon src={feedbackImg} alt="" />
          <StyledSectionContent>
            <h1>{t('contact:share_feedback_with_us')}</h1>
            <Divider />
            <Trans i18nKey="contact:share_feedback_content">
              <p />
              <p />
              <p />
              <p />
            </Trans>
            <StyledButtonAnchor
              href={SURVEY_LINK}
              target="_blank"
              rel="noopener noreferrer"
              dark
            >
              {t('contact:share_feedback')}
            </StyledButtonAnchor>
          </StyledSectionContent>
        </StyledSection>
      </StyledContent>

      <SubscribeForm />
    </StyledContact>
  );
};

export default Contact;
