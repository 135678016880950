import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledLoadingOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.5;
  z-index: 10000;
`;

export type LoadingOverlayProps = {
  isShown: boolean;
  theme?: DefaultTheme;
};

const LoadingOverlay = ({ isShown }: LoadingOverlayProps) =>
  isShown ? <StyledLoadingOverlay /> : null;

export default LoadingOverlay;
