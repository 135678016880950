export default {
  'about_us': 'About Us',
  'back': 'Back',
  'cancer_trials': 'Cancer clinical trials',
  'cancer_type': '{{type}} Cancer',
  'cancel': 'Cancel',
  'close': 'Close',
  'collapse': 'Collapse',
  'condition_cancer': 'Cancer',
  'condition_covid': 'COVID 19',
  'condition_other': 'Other',
  'contact_us': 'Contact Us',
  'copied': 'URL Copied',
  'copy_url': 'Copy URL',
  'copyright': '©2022 Heal Mary. All rights reserved.',
  'covid_19': 'Covid 19',
  'covid_trials': 'COVID-19 trials',
  'create_a_profile_banner': 'Create a profile to easily search and apply for trials. <0>Sign up now!</0>',
  'complete_profile_banner': '<0>Complete your profile</0> to easily search, save and apply for trials.',
  'complete_profile': 'Complete Your Profile',
  'complete_profile_to_like': 'In order to like trials you must first complete your profile.',
  'currently_working_on_diversity': '<0>We are currently working on our diversity and inclusion capabilities which will allow you to customize our platform to meet your needs. Please </0><1>sign up</1><2> to find out about our new features when they get released.</2>',
  'dismiss_banner': 'Dismiss banner',
  'done': 'Done',
  'email': 'Email',
  'email_placeholder': 'your.email@domain.com',
  'ellipsis': '...',
  'expand': 'Expand',
  'facebook': 'Facebook',
  'faq': 'FAQ',
  'heal_mary': 'Heal Mary',
  'help': 'Help',
  'helpful_articles_and_resources': 'Helpful Articles and Resources',
  'hide_password': 'HIDE',
  'home': 'Home',
  'instagram': 'Instagram',
  'intervention_behavioral': 'Behavioral',
  'intervention_biological': 'Biological',
  'intervention_combination_product': 'Combination Product',
  'intervention_device': 'Device',
  'intervention_diagnostic_test': 'Diagnostic Test',
  'intervention_dietary_supplement': 'Dietary Supplement',
  'intervention_drug': 'Drug',
  'intervention_genetic': 'Genetic',
  'intervention_procedure': 'Procedure',
  'intervention_radiation': 'Radiation',
  'linkedin': 'LinkedIn',
  'loading': 'Loading...',
  'magic_link_check_your_email': 'Check your email.',
  'magic_link_expired': 'The link is invalid or has expired. Please request for another one-click login link email and try again.',
  'magic_link_resend': 'Resend Login Email',
  'magic_link_send': 'Send One-Click Login Link',
  'magic_link_sent': '<0>We have sent an email to </0><1>{{email}}</1>',
  'magic_link_sent_details': '<0>You will receive an email containing a one-click login link that will give you access to the clinical trials on </0><1>healmary.com</1>',
  'magic_link_modal_title': 'Enter your email to gain access to clinical trials',
  'magic_link_description': '<0>We’ll email you a link that will sign you in instantly. Or you can </0><1>sign in with a password</1>.',
  'medium': 'Medium',
  'my_profile': 'My Profile',
  'next': 'Next',
  'no': 'No',
  'ok': 'OK',
  'other': 'Other',
  'other_trials': 'Other clinical trials',
  'password': 'Password',
  'plus_number': '+{{number}}',
  'print': 'Print',
  'privacy_policy': 'Privacy Policy',
  'remove': 'Remove',
  'remove_condition': 'Remove Condition',
  'save_changes': 'Save Changes',
  'search_clinical_trials': 'Search clinical trials',
  'search_trials_title_case': 'Search Trials',
  'share': 'Share',
  'share_via_email': 'Share via Email',
  'show_password': 'SHOW',
  'sign_out': 'Sign Out',
  'sign_up_now': 'Sign Up Now',
  'sign_up_cta': 'Sign Up, it\'s Free',
  'subscribe_thank_you': 'Thank you for subscribing.',
  'subscribe_title': 'Subscribe to our list to stay up to date with new features and updates',
  'subscribe': 'Subscribe',
  'subscribe_disclaimer': 'By subscribing, you agree to our <1>Terms of Service</1> and <3>Privacy Policy</3>',
  'terms_of_service': 'Terms of Service',
  'twitter': 'Twitter',
  'unknown_error': 'An unknown error occurred, please try again.',
  'view_details': 'View Details',
  'what_type_of_clinical_trials': 'What type of clinical trials are you looking for?',
  'yes': 'Yes',
  'your_email': 'Your email address',
};
