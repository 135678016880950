/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import TrialLocation from 'common/types/TrialLocation';

import Button from 'components/Button';
import FacilityDetails from 'components/FacilityDetails';
import ResourceLinks from 'components/ResourceLinks';
import SectionSubheading from 'components/SectionSubheading';
import SummaryList from 'components/SummaryList';

// Styled Components
const StyledLocationSummaryList = styled.div``;

const StyledMoreListItem = styled.div`
  padding-left: 25px;
`;

const StyledMore = styled.span`
  color: ${(props) => props.theme.colors.accentText};
`;

const StyledLocationsButton = styled(Button)`
  margin-top: 30px;

  @media ${(props) => props.theme.devices.mobile} {
    width: 100%;
  }
`;

const StyledResourceSection = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
`;

export type LocationSummaryListProps = {
  locations: TrialLocation[];
  onViewAll: () => void;
  theme?: DefaultTheme;
};

// Component
const LocationSummaryList = ({
  locations,
  onViewAll,
}: LocationSummaryListProps) => {
  const { t } = useTranslation();

  return (
    <StyledLocationSummaryList>
      <SectionSubheading>{t('trial:locations_label')}</SectionSubheading>
      <SummaryList>
        {locations.slice(0, 5).map(
          ({ facility = null, links = [] }, index) =>
            facility && (
              <li key={`facility-${index}`}>
                <FacilityDetails facility={facility} isContentOnly />
                {links && links.length > 0 && (
                  <StyledResourceSection>
                    <ResourceLinks links={links} />
                  </StyledResourceSection>
                )}
              </li>
            ),
        )}
      </SummaryList>
      {locations.length > 5 && (
        <>
          <StyledMoreListItem>
            <StyledMore>
              {t('trial:plus_more', { count: locations.length - 5 })}
            </StyledMore>
          </StyledMoreListItem>

          <StyledLocationsButton onClick={onViewAll}>
            {t('trial:view_all_locations')}
          </StyledLocationsButton>
        </>
      )}
    </StyledLocationSummaryList>
  );
};

export default LocationSummaryList;
