import ButtonLink from 'components/ButtonLink';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledSearchLinks = styled.div`
  display: flex;
  justify-content: space-around;

  @media ${(props) => props.theme.devices.mobile} {
    flex-direction: column;
  }

  a {
    box-sizing: border-box;
    width: 44%;

    @media ${(props) => props.theme.devices.mobile} {
      width: 100%;

      &:nth-of-type(n + 2) {
        margin-top: 12px;
      }
    }
  }
`;

export type SearchLinksProps = {
  onLinkClicked?: () => void;
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'div'>;

const SearchLinks = ({ onLinkClicked, ...rest }: SearchLinksProps) => {
  const { t } = useTranslation();

  return (
    <StyledSearchLinks {...rest}>
      <ButtonLink to="/covid" onClick={onLinkClicked} dark>
        {t('common:covid_trials')}
      </ButtonLink>
      <ButtonLink to="/search" onClick={onLinkClicked} dark>
        {t('common:other_trials')}
      </ButtonLink>
    </StyledSearchLinks>
  );
};

export default SearchLinks;
