import {
  CancerConditionDetails,
  Condition,
  ConditionTypeEnum,
  CovidConditionDetails,
  OtherConditionDetails,
} from 'common/api/conditions';
import Button from 'components/Button';
import { CONDITIONS } from 'modules/Search/constants';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';
import EditCancerConditionForm from '../EditCancerConditionForm';
import EditCovidConditionForm from '../EditCovidConditionForm';
import EditOtherConditionForm from '../EditOtherConditionForm';

import iconDeleteImg from './assets/icon-delete.svg';

// Styled Components
const StyledConditionsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const StyledListItem = styled.li`
  position: relative;
  border: 2px solid ${(props) => props.theme.colors.darkButtonBorder};
  border-radius: 5px;
`;

const StyledConditionButton = styled.button`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 25px 30px;
  border: none;
  background-color: ${(props) => props.theme.colors.darkButtonBackground};
  color: ${(props) => props.theme.colors.lightText};
  font-weight: ${(props) => props.theme.fontWeights.bold};

  @media ${(props) => props.theme.devices.mobile} {
    padding: 20px;
    font-size: ${(props) => props.theme.fontSizes.small};
  }
`;

const StyledDeleteButton = styled.button<{ isExpanded: boolean }>`
  position: absolute;
  top: 24px;
  right: -52px;
  padding: 0;
  background-color: transparent;
  border: none;

  // Special resolution to avoid squishing on some tablets
  @media (max-width: 1360px) {
    display: ${(props) => (props.isExpanded ? 'block' : 'none')};
    position: relative;
    top: initial;
    right: initial;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
    padding: 24px;
  }
`;

const StyledViewDetails = styled.span`
  font-size: ${(props) => props.theme.fontSizes.small};
  text-decoration: underline;

  @media ${(props) => props.theme.devices.mobile} {
    font-size: ${(props) => props.theme.fontSizes.xsmall};
  }
`;

const StyledEditConditionSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 56px;
  padding: 40px;

  // Special resolution to avoid squishing on some tablets
  @media (max-width: 1360px) {
    gap: 40px;
    padding: 20px;
  }
`;

const StyledButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${(props) => props.theme.devices.mobile} {
    button {
      width: 100%;
    }
  }
`;

export type ConditionsListProps = {
  conditions: Condition[];
  disabled?: boolean;
  onUpdateConditionDetails: (
    id: number,
    details: CancerConditionDetails | CovidConditionDetails,
  ) => void;
  onRemoveCondition: (id: number) => void;
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'ul'>;

const ConditionsList = ({
  conditions,
  disabled = false,
  onUpdateConditionDetails,
  onRemoveCondition,
  ...rest
}: ConditionsListProps) => {
  const { t } = useTranslation();
  const [activeConditionId, setActiveConditionId] = useState<number | null>(
    null,
  );
  const [modifiedConditionDetails, setModifiedConditionDetails] = useState<
    Partial<CancerConditionDetails> | CovidConditionDetails | null
  >(null);

  const onSaveChanges = () => {
    if (!activeConditionId || !modifiedConditionDetails) {
      return;
    }
    onUpdateConditionDetails(
      activeConditionId,
      modifiedConditionDetails as
        | CancerConditionDetails
        | CovidConditionDetails
        | OtherConditionDetails,
    );
    setActiveConditionId(null);
    setModifiedConditionDetails(null);
  };

  const cancerConditions = conditions.filter(
    ({ type }) => type === ConditionTypeEnum.cancer,
  );
  const covidCondition = conditions.find(
    ({ type }) => type === ConditionTypeEnum.covid,
  );
  const otherConditions = conditions.filter(
    ({ type }) => type === ConditionTypeEnum.other,
  );

  return (
    <StyledConditionsList {...rest}>
      {cancerConditions.map(({ id, details }) => (
        <StyledListItem key={id}>
          <StyledConditionButton
            onClick={() => {
              setActiveConditionId(activeConditionId === id ? null : id);
              setModifiedConditionDetails({ ...details });
            }}
          >
            <Trans
              i18nKey="common:cancer_type"
              values={{
                type: CONDITIONS[(details as CancerConditionDetails).condition]
                  ? t(
                      CONDITIONS[(details as CancerConditionDetails).condition]
                        .i18nKey,
                    )
                  : (details as CancerConditionDetails).condition,
              }}
            />
            <StyledViewDetails>{t('common:view_details')}</StyledViewDetails>
          </StyledConditionButton>
          {activeConditionId === id && (
            <StyledEditConditionSection>
              <EditCancerConditionForm
                cancerDetails={
                  modifiedConditionDetails as CancerConditionDetails
                }
                onValueChange={(conditionDetails) =>
                  setModifiedConditionDetails(conditionDetails)
                }
              />
              <StyledButtonRow>
                <Button
                  disabled={
                    !(
                      modifiedConditionDetails as Partial<CancerConditionDetails>
                    ).condition || disabled
                  }
                  type="button"
                  dark
                  onClick={onSaveChanges}
                >
                  {t('common:save_changes')}
                </Button>
              </StyledButtonRow>
            </StyledEditConditionSection>
          )}
          <StyledDeleteButton
            isExpanded={activeConditionId === id}
            onClick={() => onRemoveCondition(id)}
          >
            <img src={iconDeleteImg} alt={t('common:remove_condition')} />
          </StyledDeleteButton>
        </StyledListItem>
      ))}
      {covidCondition && (
        <StyledListItem>
          <StyledConditionButton
            onClick={() => {
              setActiveConditionId(
                activeConditionId === covidCondition.id
                  ? null
                  : covidCondition.id,
              );
              setModifiedConditionDetails({ ...covidCondition.details });
            }}
          >
            <span>{t('common:covid_19')}</span>
            <StyledViewDetails>{t('common:view_details')}</StyledViewDetails>
          </StyledConditionButton>
          {activeConditionId === covidCondition.id && (
            <StyledEditConditionSection>
              <EditCovidConditionForm
                covidDetails={modifiedConditionDetails as CovidConditionDetails}
                onValueChange={(conditionDetails) =>
                  setModifiedConditionDetails(conditionDetails)
                }
              />
              <StyledButtonRow>
                <Button
                  disabled={disabled}
                  type="button"
                  dark
                  onClick={onSaveChanges}
                >
                  {t('common:save_changes')}
                </Button>
              </StyledButtonRow>
            </StyledEditConditionSection>
          )}
          <StyledDeleteButton
            isExpanded={activeConditionId === covidCondition.id}
            onClick={() => onRemoveCondition(covidCondition.id)}
          >
            <img src={iconDeleteImg} alt={t('common:remove_condition')} />
          </StyledDeleteButton>
        </StyledListItem>
      )}
      {otherConditions.map(({ id, details }) => (
        <StyledListItem key={id}>
          <StyledConditionButton
            onClick={() => {
              setActiveConditionId(activeConditionId === id ? null : id);
              setModifiedConditionDetails({ ...details });
            }}
          >
            {(details as OtherConditionDetails).condition}
            <StyledViewDetails>{t('common:view_details')}</StyledViewDetails>
          </StyledConditionButton>
          {activeConditionId === id && (
            <StyledEditConditionSection>
              <EditOtherConditionForm
                conditionDetails={
                  modifiedConditionDetails as OtherConditionDetails
                }
                onValueChange={(conditionDetails) =>
                  setModifiedConditionDetails(conditionDetails)
                }
              />
              <StyledButtonRow>
                <Button
                  disabled={
                    !(
                      modifiedConditionDetails as Partial<OtherConditionDetails>
                    ).condition || disabled
                  }
                  type="button"
                  dark
                  onClick={onSaveChanges}
                >
                  {t('common:save_changes')}
                </Button>
              </StyledButtonRow>
            </StyledEditConditionSection>
          )}
          <StyledDeleteButton
            isExpanded={activeConditionId === id}
            onClick={() => onRemoveCondition(id)}
          >
            <img src={iconDeleteImg} alt={t('common:remove_condition')} />
          </StyledDeleteButton>
        </StyledListItem>
      ))}
    </StyledConditionsList>
  );
};

export default ConditionsList;
