import { getIntegratorClient } from 'common/api/integrator';
import IntegratorClient from 'common/types/IntegratorClient';
import React, { ReactNode, useEffect, useState } from 'react';

export type IntegratorContextState = {
  clientId?: string;
  integratorClient?: IntegratorClient;
  isIntegratorClientLoaded: boolean;
  isIntegratorMode: boolean;
  isEmbedded: boolean;
};

export const DEFAULT_INTEGRATOR_CONTEXT_VALUE: IntegratorContextState = {
  integratorClient: undefined,
  isIntegratorClientLoaded: false,
  isIntegratorMode: false,
  isEmbedded: false,
};

export const IntegratorContext = React.createContext(
  DEFAULT_INTEGRATOR_CONTEXT_VALUE,
);

export type IntegratorContextWrapperProps = {
  children: ReactNode;
  isIntegratorMode: boolean;
};

export const IntegratorContextWrapper = ({
  children,
  isIntegratorMode,
}: IntegratorContextWrapperProps) => {
  const [isIntegratorClientLoaded, setIsIntegratorClientLoaded] =
    useState<boolean>(false);
  const [integratorClient, setIntegratorClient] = useState<IntegratorClient>();
  const isEmbedded = window.self !== window.top;

  useEffect(() => {
    const loadIntegratorClient = async () => {
      try {
        const loadedIntegratorClient = await getIntegratorClient();
        setIntegratorClient(loadedIntegratorClient ?? undefined);
        setIsIntegratorClientLoaded(true);
      } catch (ex) {
        console.error(ex);
      }
    };

    if (isIntegratorMode) {
      loadIntegratorClient();
    } else {
      setIsIntegratorClientLoaded(true);
    }
  }, [isEmbedded, isIntegratorMode]);

  return (
    <IntegratorContext.Provider
      value={{
        integratorClient,
        isIntegratorClientLoaded,
        isIntegratorMode,
        isEmbedded,
      }}
    >
      {children}
    </IntegratorContext.Provider>
  );
};

export default IntegratorContext;
