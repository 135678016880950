import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';

import Button from 'components/Button';
import CopyUrlButton from 'components/CopyUrlButton';

import getUrlToShare from 'common/util/getUrlToShare';
import IntegratorContext from 'contexts/IntegratorContext';
import emailUrlHighlightedImg from './assets/icon-email-highlighted.svg';
import emailUrlImg from './assets/icon-email.svg';
import shareHighlightedImg from './assets/icon-share-highlighted.svg';
import shareImg from './assets/icon-share.svg';

// Styled Components
const StyledSharePageControl = styled.div``;

const StyledTitle = styled.div`
  margin-bottom: 12px;
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

const StyledButtons = styled.div`
  button:nth-child(n + 2) {
    margin-left: 16px;
  }

  @media ${(props) => props.theme.devices.mobile} {
    button {
      display: inline-block;
    }
  }

  @media (max-width: 375px) {
    button {
      display: block;
      width: 100%;
    }

    button:nth-child(n + 2) {
      margin-left: 0;
      margin-top: 12px;
    }
  }
`;

export type SharePageControlProps = {
  shareDescription: string;
  shareTitle: string;
  title: string;
  theme?: DefaultTheme;
};

// Component
const SharePageControl = ({
  shareDescription,
  shareTitle,
  title,
  ...rest
}: SharePageControlProps) => {
  const { t } = useTranslation();
  const { integratorClient, isEmbedded } = useContext(IntegratorContext);

  const canWebShare = navigator.share !== undefined;

  const shareViaEmail = () => {
    const urlToShare = getUrlToShare(isEmbedded, integratorClient);
    window.location.href = `mailto:?body=${encodeURIComponent(
      `${shareDescription}\n${urlToShare}`,
    )}&subject=${encodeURIComponent(shareTitle)}`;
  };

  const webShare = async () => {
    const urlToShare = getUrlToShare(isEmbedded, integratorClient);
    try {
      await navigator.share({
        title: shareTitle,
        text: shareDescription,
        url: urlToShare,
      });
    } catch (ex) {
      // No action
    }
  };

  if (canWebShare) {
    return (
      <StyledSharePageControl {...rest}>
        <StyledTitle>{title}</StyledTitle>
        <StyledButtons>
          <Button
            icon={shareImg}
            iconHover={shareHighlightedImg}
            onClick={webShare}
            small
          >
            {t('common:share')}
          </Button>
        </StyledButtons>
      </StyledSharePageControl>
    );
  }

  return (
    <StyledSharePageControl {...rest}>
      <StyledTitle>{title}</StyledTitle>
      <StyledButtons>
        <CopyUrlButton small />
        <Button
          icon={emailUrlImg}
          iconHover={emailUrlHighlightedImg}
          onClick={shareViaEmail}
          small
        >
          {t('common:share_via_email')}
        </Button>
      </StyledButtons>
    </StyledSharePageControl>
  );
};

export default SharePageControl;
