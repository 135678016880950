import { ResourceLink } from 'common/api/resourceLinks';
import Modal from 'components/Modal';
import TextButton from 'components/TextButton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';

import iconMinusImg from './assets/icon-minus.svg';
import iconPlusImg from './assets/icon-plus.svg';

// Styled Components
const StyledResourceLinks = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const StyledResourceItem = styled.li`
  padding: 22px;
  border: 2px solid ${(props) => props.theme.colors.altLightButtonBorder};
  border-radius: 5px;
`;

const StyledTitleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
`;

const StyledTitle = styled.h1`
  color: ${(props) => props.theme.colors.text};
  font-family: ${(props) => props.theme.fonts.main};
  font-size: ${(props) => props.theme.fontSizes.regular};
  text-align: left;
`;

const StyledResourceItemContent = styled.div<{ collapsed: boolean }>`
  height: ${(props) => (props.collapsed ? 0 : 'auto')};
  margin-top: ${(props) => (props.collapsed ? 0 : '8px')};
  overflow: hidden;
`;

const StyledResourceDetails = styled.p`
  margin: 0;
  white-space: pre-line;
`;

const StyledLinkList = styled.ul`
  margin: 0;
  margin-top: 16px;
  padding: 0;
  list-style: none;
`;

const StyledLink = styled.a`
  border-bottom: 1px solid ${(props) => props.theme.colors.textLinkButton};
  color: ${(props) => props.theme.colors.textLinkButton};
  font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export type ResourceLinksModalProps = {
  resourceLinks: ResourceLink[];
  onClose: () => void;
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'ul'>;

const sortResourceLinks = (a: ResourceLink, b: ResourceLink) =>
  a.order - b.order;

const ResourceLinksModal = ({
  resourceLinks,
  onClose,
  ...rest
}: ResourceLinksModalProps) => {
  const { t } = useTranslation();
  const [expandedIndex, setExpandedIndex] = useState<number>(-1);
  const sortedResourceLinks = resourceLinks.sort(sortResourceLinks);

  return (
    <Modal
      heading={t('common:helpful_articles_and_resources')}
      controls={<TextButton onClick={onClose}>{t('common:close')}</TextButton>}
    >
      <StyledResourceLinks {...rest}>
        {sortedResourceLinks.map(({ id, title, content, links }, index) => (
          <StyledResourceItem key={id}>
            <StyledTitleButton
              onClick={() => {
                if (expandedIndex === index) {
                  setExpandedIndex(-1);
                } else {
                  setExpandedIndex(index);
                }
              }}
            >
              <StyledTitle>{title}</StyledTitle>
              {expandedIndex === index ? (
                <img src={iconMinusImg} alt={t('common:collapse')} />
              ) : (
                <img src={iconPlusImg} alt={t('common:expand')} />
              )}
            </StyledTitleButton>
            <StyledResourceItemContent collapsed={expandedIndex !== index}>
              <StyledResourceDetails>{content}</StyledResourceDetails>
              {links && links.length > 0 && (
                <StyledLinkList>
                  {links.map(({ title, url }, linkIndex) => (
                    <li key={linkIndex}>
                      <StyledLink
                        href={url}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {title}
                      </StyledLink>
                    </li>
                  ))}
                </StyledLinkList>
              )}
            </StyledResourceItemContent>
          </StyledResourceItem>
        ))}
      </StyledResourceLinks>
    </Modal>
  );
};

export default ResourceLinksModal;
