export default {
  'are_you_a_healthcare_worker': 'Are you a healthcare worker?',
  'are_you_hospitalized': 'Are you currently hospitalized with a confirmed diagnosis of COVID-19?',
  'are_you_in_other_trial': 'Are you enrolled in or do you have plans to participate in another clinical trial?',
  'are_you_on_chemo': 'Are you currently on chemotherapy?',
  'are_you_pregnant': 'Are you pregnant or breastfeeding?',
  'are_you_taking_immuno': 'Are you currently taking immunosuppressants?',
  'browser_title': 'Heal Mary - Search COVID-19 Trials',
  'disclaimer': 'We are updating trials as quickly as possible but this may not be an exhaustive list of all available trials. Please visit World Health Organization for all up to date information.',
  'do_you_have_immune_disease': 'Do you have any immune related diseases?',
  'do_you_require_a_ventilator': 'Do you require the use of a mechanical ventilator?',
  'going_to_ask_questions': 'We\'re going to ask you a few questions to narrow down your COVID-19 search results.',
  'have_previous_trial': 'Have you previously participated in another clinical trial?',
  'how_old_are_you': 'How old are you?',
  'how_would_you_like_to_search_covid_trials': 'How would you like to search for COVID-19 trials?',
  'modify_criteria': 'Modify search criteria',
  'modify_your_criteria': 'Modify your search criteria',
  'results_based_on_medical_profile': 'Using your Medical Information, here are the results for COVID-19 trials',
  'results_based_on_selection': 'Based on your selection, here are all the COVID-19 clinical trials we’ve found for you so far.',
  'step_of_steps': 'Step {{step}}/{{steps}}',
  'updated_selection': 'Update Selection',
  'where_to_search': 'Where are you currently located?',
  'years_old': 'years old',
};
