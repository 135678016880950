import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Modal from 'components/Modal/Modal';
import TextButton from 'components/TextButton';

// Styled Components
const StyledContent = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  text-align: center;
`;

const StyledMessage = styled.div`
  padding-bottom: 30px;
  white-space: pre-line;
`;

export type RedirectModalProps = {
  onCancel: () => void;
  onGoNow: () => void;
  theme?: DefaultTheme;
};

// Component
const RedirectModal = ({ onCancel, onGoNow }: RedirectModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      controls={
        <TextButton onClick={onCancel}>{t('common:cancel')}</TextButton>
      }
      small
    >
      <StyledContent>
        <StyledMessage>{t('trial:please_hold')}</StyledMessage>
        <Button onClick={onGoNow} dark>
          {t('trial:go_now')}
        </Button>
      </StyledContent>
    </Modal>
  );
};

RedirectModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onGoNow: PropTypes.func.isRequired,
};

RedirectModal.defaultProps = {};

export default RedirectModal;
