import TrialSearchResult from 'common/types/TrialSearchResult';
import clampText from 'common/util/clampText';
import hasDisplayableIntervention from 'common/util/hasDisplayableIntervention';
import InterventionIconSet from 'components/InterventionIconSet/InterventionIconSet';
import StatusIndicator from 'components/StatusIndicator';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { css, DefaultTheme } from 'styled-components/macro';

import iconHeartImg from './assets/icon-heart.svg';

// Styled Components
const StyledLikedTrialsList = styled.div``;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6%;
  padding-left: 30px;
  padding-right: 30px;

  @media ${(props) => props.theme.devices.tablet} {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

const StyledCell = styled.div`
  max-height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 22px;

  &:nth-of-type(1) {
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
    width: 124px;
  }

  &:nth-of-type(2) {
    flex: 1 1 auto;
  }

  &:nth-of-type(3) {
    flex-shrink: 0;
    width: 28%;
  }

  &:nth-of-type(4) {
    flex-shrink: 0;
    width: 160px;
    text-align: center;
  }

  &:nth-of-type(5) {
    flex-shrink: 0;
    width: 24px;
  }

  @media ${(props) => props.theme.devices.tablet} {
    &:nth-of-type(1) {
      display: flex;
      align-items: center;
      order: 2;
      width: 100%;
    }

    &:nth-of-type(2) {
      width: 100%;
      order: 1;
    }

    &:nth-of-type(3) {
      flex-shrink: 1;
      width: 100%;
      order: 3;
    }

    &:nth-of-type(4) {
      flex-shrink: 1;
      width: 100%;
      order: 2;
    }

    &:nth-of-type(5) {
      justify-content: center;
      flex-shrink: 0;
      width: 100%;
      order: 4;
    }
  }
`;

const StyledHeadingRow = styled(StyledRow)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  // Needed to keep percentage sizing alignment with cards
  border: 2px solid transparent;
  color: ${(props) => props.theme.colors.subheading};
  font-weight: ${(props) => props.theme.fontWeights.bold};

  @media ${(props) => props.theme.devices.tablet} {
    display: none;
  }
`;

const StyledTrials = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;

  margin: 0;
  padding: 0;
  list-style: none;

  a {
    color: ${(props) => props.theme.colors.text};
    font-weight: ${(props) => props.theme.fontWeights.regular};
  }
`;

const StyledTrialCard = styled(StyledRow)`
  height: 68px;
  padding-top: 28px;
  padding-bottom: 28px;

  background-color: ${(props) => props.theme.colors.background};

  border: 2px solid ${(props) => props.theme.colors.inputBorder};
  box-shadow: 0 0 10px 0 ${(props) => props.theme.rawColors.black20};
  border-radius: 8px;

  @media ${(props) => props.theme.devices.tablet} {
    flex-direction: column;
    height: auto;
  }
`;

const StyledTrialName = styled(StyledCell)`
  color: ${(props) => props.theme.colors.heading};
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

const StyledStatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${(props) => props.theme.devices.tablet} {
    justify-content: flex-start;
  }
`;

const StyledMobileLabel = styled.div<{ vertical?: boolean }>`
  display: none;
  ${(props) =>
    props.vertical
      ? css`
          margin-bottom: 8px;
        `
      : css`
          margin-right: 16px;
        `}
  color: ${(props) => props.theme.colors.subheading};
  font-weight: ${(props) => props.theme.fontWeights.bold};

  @media ${(props) => props.theme.devices.tablet} {
    display: block;
  }
`;

const StyledUnlikeButton = styled.button`
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
`;

const MAX_CELL_LENGTH = 120;

export type LikedTrialsListProps = {
  trials: TrialSearchResult[];
  onUnlikeTrial: (id: string) => void;
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'div'>;

const LikedTrialsList = ({
  trials,
  onUnlikeTrial,
  ...rest
}: LikedTrialsListProps) => {
  const { t } = useTranslation();

  return (
    <StyledLikedTrialsList {...rest}>
      <StyledHeadingRow>
        <StyledCell>{t('likedTrials:type')}</StyledCell>
        <StyledCell>{t('likedTrials:trial_name')}</StyledCell>
        <StyledCell>{t('likedTrials:trial_objective')}</StyledCell>
        <StyledCell>{t('likedTrials:status')}</StyledCell>
        <StyledCell>&nbsp;</StyledCell>
      </StyledHeadingRow>
      <StyledTrials>
        {trials.map(
          ({
            brief_title,
            id,
            is_covid,
            interventions,
            objective_description,
            overall_status,
          }) => (
            <li key={id}>
              <Link to={`${is_covid ? '/covidtrials' : '/trials'}/${id}`}>
                <StyledTrialCard>
                  <StyledCell>
                    {hasDisplayableIntervention(interventions) && (
                      <>
                        <StyledMobileLabel>
                          {t('likedTrials:type')}
                        </StyledMobileLabel>
                        <InterventionIconSet interventions={interventions} />
                      </>
                    )}
                  </StyledCell>
                  <StyledTrialName>
                    {clampText(brief_title, MAX_CELL_LENGTH)}
                  </StyledTrialName>
                  <StyledCell>
                    <StyledMobileLabel vertical>
                      {t('likedTrials:trial_objective')}
                    </StyledMobileLabel>
                    {objective_description ? (
                      clampText(objective_description, MAX_CELL_LENGTH)
                    ) : interventions && interventions.length > 0 ? (
                      clampText(
                        interventions
                          .map(
                            ({ intervention_type, intervention_name }) =>
                              `${intervention_type}: ${intervention_name}`,
                          )
                          .join(', '),
                        MAX_CELL_LENGTH,
                      )
                    ) : (
                      <>&ndash;</>
                    )}
                  </StyledCell>
                  <StyledCell>
                    <StyledStatusContainer>
                      <StyledMobileLabel>
                        {t('likedTrials:status')}
                      </StyledMobileLabel>
                      <StatusIndicator status={overall_status} />
                    </StyledStatusContainer>
                  </StyledCell>
                  <StyledCell>
                    <StyledUnlikeButton
                      title={t('likedTrials:unlike_trial')}
                      onClick={(evt) => {
                        evt.preventDefault();
                        onUnlikeTrial(id);
                      }}
                    >
                      <img
                        src={iconHeartImg}
                        alt={t('likedTrials:unlike_trial')}
                      />
                    </StyledUnlikeButton>
                  </StyledCell>
                </StyledTrialCard>
              </Link>
            </li>
          ),
        )}
      </StyledTrials>
    </StyledLikedTrialsList>
  );
};

export default LikedTrialsList;
