import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

// Styled Components
const StyledTitle = styled.h4`
  color: ${(props) => props.theme.colors.text};
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

const StyledLabel = styled.span`
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export type ContactDetailsContact = {
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  degrees?: string;
  email?: string;
  phone?: string;
  phone_ext?: string;
};

export type ContactDetailsProps = {
  contact: ContactDetailsContact;
  isContentOnly?: boolean;
  theme?: DefaultTheme;
};

// Component
const ContactDetails = ({
  contact,
  isContentOnly = false,
}: ContactDetailsProps) => {
  const { t } = useTranslation(['trial']);

  return (
    <>
      {(contact.first_name || contact.middle_name || contact.last_name) && (
        <StyledTitle as={isContentOnly ? 'div' : 'h4'}>
          {contact.first_name && contact.first_name}
          {contact.middle_name && ` ${contact.middle_name}`}
          {contact.last_name && ` ${contact.last_name}`}
          {contact.degrees && `, ${contact.degrees}`}
        </StyledTitle>
      )}
      {contact.email && (
        <div>
          <StyledLabel>{t('trial:email_label')}</StyledLabel>
          {contact.email}
        </div>
      )}
      {contact.phone && (
        <div>
          <StyledLabel>{t('trial:phone_label')}</StyledLabel>
          {contact.phone}
          {contact.phone_ext && (
            <>
              &nbsp;
              {t('trial:extension')}
              {contact.phone_ext}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ContactDetails;
