import { useEffect } from 'react';

/**
 * Hookup a resize listener to the window or a target element
 * @param {(evt?: UIEvent) => void} callback The callback to call on resize (should be memoized with `useCallback` to avoid re-registering each render)
 * @param {boolean} callOnMount Whether to trigger the callback on mount
 * @param {HTMLElement | undefined} elementToWatch The element to watch for size changes
 */
export default function useOnResize(
  callback: () => void,
  callOnMount: boolean = false,
  elementToWatch?: HTMLElement,
) {
  useEffect(() => {
    // Watch a particular element if specified
    if (elementToWatch) {
      const resizeObserver = new ResizeObserver(callback);
      resizeObserver.observe(elementToWatch);
      if (callOnMount) {
        callback();
      }

      return () => {
        resizeObserver.disconnect();
      };
    }

    // If no element is specified, watch for window size changes
    window.addEventListener('resize', callback);
    if (callOnMount) {
      callback();
    }
    return () => {
      window.removeEventListener('resize', callback);
    };
  }, [callback, callOnMount, elementToWatch]);
}
