export default {
  'browser_title': 'Heal Mary - FAQ',

  'frequently_asked_questions': 'Frequently Asked Questions',

  'what_is_a_clinical_trial': 'What is a Clinical Trial?',
  'what_is_a_clinical_trial_answer': 'A clinical trial is a test of medicine and treatments, scientific study, involving patient volunteers that match a set of criteria. They are the ways in which researchers or practitioners can confirm that their treatments and safe to introduce as a treatment for specific diseases.',

  'why_would_i_participate': 'Why would I participate in a Clinical Trial?',
  'why_would_i_participate_answer': `
      <0>
        We strongly encourage you to speak to your doctor/practitioner to understand the full benefits and risks that come with participating in clinical trials. A few reasons why a patient may want to participate:
      </0>
      <1>
        <0>Access to new medicine and therapeutic alternatives</0>
        <1>The patient receives the medicine or therapy at no cost for the duration of the trial</1>
        <2>Help researchers and practitioners develop new medicine and therapeutics for other patients with the same diseases or conditions</2>
      </1>
  `,

  'why_are_clinical_trials_important': 'Why are Clinical Trials important?',
  'why_are_clinical_trials_important_answer': `
    <0>Clinical trials are tested in laboratories prior to being introduced to humans. To understand if a treatment works for human beings, the new methods or treatments need to undergo testing with humans to understand if it is more effective than current treatments to help manage diseases or conditions.</0>
    <1>A large enough patient population needs to receive the treatment to prove that the new method or medicine is safe, effective and works better than what is available in market which is why trials have strict recruitment criteria and are often monitored very closely.</1>
  `,

  'what_are_the_types': 'What are the types of Clinical Trials?',
  'what_are_the_types_answer': `
      <0>
        via <0>CancerResearch UK</0>
      </0>
      <1>
        <0>There are two main types of trials or studies - interventional and observational.</0>
        <1>Interventional trials aim to find out more about a particular intervention, or treatment. People taking part are put into different treatment groups, so that the research team can compare the results.</1>
        <2>Observational studies aim to find out what happens to people in different situations. The research team observe the people taking part, but they don’t influence what treatments people have. The people taking part aren’t put into treatment groups.</2>
      </1>
  `,

  'what_is_sponsor': 'What is a clinical trial "sponsor" and who conducts clinical trials?',
  'what_is_sponsor_answer': 'A clinical trial sponsor is an individual, corporation, institution or organization that is running the treatment research or study. The sponsor is responsible for the trial including good clinical and ethical practices at each site. Each clinical trial site has their own qualified investigator (health care practitioner) who is responsible for the trial onsite.',

  'how_to_get_in_touch': 'I have more questions not answered here, how do I get in touch?',
  'how_to_get_in_touch_answer': `
    <0>
      We're happy to help answer any other questions you might have. Please send an email to our support desk
      <0>hello@healmaryapp.com.</0>
    </0>
  `,

  'what_should_i_do_to_take_part': 'What should I do if I want to take part in clinical trial?',
  'what_should_i_do_to_take_part_answer': 'If you are patient or family member and you find a trial that is of interest we suggest that you copy the link and discuss it with your health care provider to help you apply for the trial.',

  'how_does_heal_mary_make_money': 'How does Heal Mary make money?',
  'how_does_heal_mary_make_money_answer': 'Heal Mary makes it transparent to users when we are being paid to help recruit for a study with a "sponsored" icon located beside the trial. For any person interested in participating in a sponsored trial, they will still need to meet the acceptance criteria and consent with us to share that information with the study sponsor. We have this model to ensure that we are open with all of our users and to ensure that only patients who do truly match a sponsored study are referred. ',
};
