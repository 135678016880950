/**
 * Check if a password is valid
 * @param {string} password The password to check
 * @returns {boolean} Whether the password is valid
 */
export default function isPasswordValid(password: string) {
  /**
   * Check for:
   *  - 1 number
   *  - 1 Uppercase letter
   *  - 1 Lowercase letter
   *  - 1 Special character
   *  - Minimum 8 characters total
   */
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
    password,
  );
}
