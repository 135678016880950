import { OtherConditionDetails } from 'common/api/conditions';
import LabeledInput from 'components/LabeledInput';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledEditOtherConditionForm = styled.div``;

const StyledHeading = styled.h1`
  font-size: ${(props) => props.theme.fontSizes.h4};
  margin-bottom: 24px;

  @media ${(props) => props.theme.devices.mobile} {
    margin-bottom: 20px;
    font-size: ${(props) => props.theme.fontSizes.mobile.h4};
  }
`;

export type EditOtherConditionFormProps = {
  disabled?: boolean;
  conditionDetails: Partial<OtherConditionDetails>;
  onValueChange: (conditionDetails: Partial<OtherConditionDetails>) => void;
  theme?: DefaultTheme;
};

const EditOtherConditionForm = ({
  disabled,
  conditionDetails,
  onValueChange,
  ...rest
}: EditOtherConditionFormProps) => {
  const { t } = useTranslation();

  return (
    <StyledEditOtherConditionForm {...rest}>
      <StyledHeading>{t('profile:please_enter_condition_name')}</StyledHeading>
      <LabeledInput
        disabled={disabled}
        label={t('profile:condition')}
        type="text"
        value={conditionDetails.condition ?? ''}
        onChange={(evt) => {
          const { value } = evt.target;
          onValueChange({
            ...conditionDetails,
            condition: value ? value : undefined,
          });
        }}
      />
    </StyledEditOtherConditionForm>
  );
};

export default EditOtherConditionForm;
