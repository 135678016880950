import { checkIsSignedIn } from 'common/api/auth';
import { PATHS } from 'common/constants';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function useRedirectIfSignedIn(
  redirectPath: string = PATHS.DASHBOARD,
  shouldCheck: boolean = true,
) {
  const history = useHistory();

  useEffect(() => {
    const checkSession = async () => {
      const isSignedIn = await checkIsSignedIn();
      if (isSignedIn) {
        history.replace(redirectPath);
      }
    };

    if (shouldCheck) {
      checkSession();
    }
  }, [history, redirectPath, shouldCheck]);
}
