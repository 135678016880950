/* eslint-disable react/prop-types */
import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

import LabeledField from 'components/LabeledField';
import { LabeledFieldProps } from 'components/LabeledField/LabeledField';
import Select from 'components/Select';
import { SelectProps } from 'components/Select/Select';

// Styled Components
const StyledSelect = styled(Select)`
  box-sizing: border-box;
  width: 100%;
`;

export type LabeledSelectProps = {
  hasError?: boolean;
  theme?: DefaultTheme;
} & Omit<LabeledFieldProps, 'onChange'> &
  SelectProps;

// Component
const LabeledSelect = React.forwardRef<HTMLLabelElement, LabeledSelectProps>(
  (
    {
      children,
      hasError,
      label,
      isRequired = false,
      tooltip,
      ...rest
    }: LabeledSelectProps,
    ref,
  ) => (
    <LabeledField
      hasError={hasError}
      label={label}
      isRequired={isRequired}
      tooltip={tooltip}
      ref={ref}
    >
      <StyledSelect hasError={hasError} {...rest}>
        {children}
      </StyledSelect>
    </LabeledField>
  ),
);

export default LabeledSelect;
