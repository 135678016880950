import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

import { EVENT_CLICK, gtmPush } from 'common/analytics/gtm';
import EligibilityChecklistItem from 'common/types/EligibilityChecklistItem';

import Item from './components/Item';

// Styled Components
const StyledEligibilityCheckList = styled.ul`
  padding: 0;
  margin: 0;

  list-style: none;
`;

export type EligibilityCheckListProps = {
  id: string;
  items: EligibilityChecklistItem[];
  eligibilityItemCount: number;
  trialId: string;
  refFunc: (index: number, ref: HTMLDivElement | null) => void;
  onChange: (items: EligibilityChecklistItem[]) => void;
  theme?: DefaultTheme;
};

// Component
const EligibilityCheckList = ({
  id,
  items,
  eligibilityItemCount,
  trialId,
  refFunc,
  onChange,
}: EligibilityCheckListProps) => {
  const onValueChange = (index: number, value: string) => {
    // Shallow copy the item replacing the updated value
    const updatedItem = {
      ...items[index],
      hasError: false,
      value,
    };

    // Shallow copy the items replacing the updated item
    const updatedItems = [...items];
    updatedItems[index] = updatedItem;

    onChange(updatedItems);

    gtmPush({
      event: EVENT_CLICK,
      surveyName: trialId,
      surveyQuestions: eligibilityItemCount,
      eventCategory: items[index].text,
      question: items[index].text,
      eventAction: value,
    });
  };

  return (
    <StyledEligibilityCheckList>
      {items.map((eligibilityItem, index) => (
        <Item
          key={eligibilityItem.text}
          id={`${id}_${index}`}
          index={index}
          item={eligibilityItem}
          refFunc={refFunc}
          onValueChange={onValueChange}
        />
      ))}
    </StyledEligibilityCheckList>
  );
};

export default EligibilityCheckList;
