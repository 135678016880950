/* eslint-disable react/prop-types */
import Label from 'components/Label';
import Modal from 'components/Modal';
import TextButton from 'components/TextButton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, DefaultTheme } from 'styled-components/macro';

import iconQuestionImg from './assets/icon-question.svg';

// Styled Components
const StyledLabel = styled(Label)`
  position: relative;
`;

const StyledTooltipButton = styled.button`
  position: absolute;
  right: 0;
  top: 5px;
  margin: 0;
  margin-left: auto;
  padding: 0;
  background-color: transparent;
  border: none;
`;

const StyledLabelText = styled.span<{ hasError: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  ${(props) =>
    props.hasError &&
    css`
      border-color: ${props.theme.colors.error};
      color: ${props.theme.colors.error};
    `}
`;

const StyledRequiredIndicator = styled.span`
  color: ${(props) => props.theme.colors.requiredIndicator};
`;

const StyledTooltipContent = styled.div`
  color: ${(props) => props.theme.colors.text};
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  white-space: normal;
`;

export type LabeledFieldProps = {
  hasError?: boolean;
  isRequired?: boolean;
  label: React.ReactNode;
  tooltip?: string;
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'label'>;

// Component
const LabeledField = React.forwardRef<HTMLLabelElement, LabeledFieldProps>(
  (
    {
      children,
      hasError = false,
      isRequired = false,
      label,
      tooltip,
      ...rest
    }: LabeledFieldProps,
    ref,
  ) => {
    const { t } = useTranslation();
    const [isTooltipShown, setIsTooltipShown] = useState<boolean>(false);
    const onClose = () => setIsTooltipShown(false);
    return (
      <StyledLabel ref={ref} {...rest}>
        <StyledLabelText hasError={hasError}>
          <span>
            {label}
            {isRequired && (
              <StyledRequiredIndicator>&nbsp;*</StyledRequiredIndicator>
            )}
          </span>
        </StyledLabelText>
        {children}

        {isTooltipShown && (
          <Modal
            controls={
              <TextButton
                onClick={(evt) => {
                  evt.preventDefault();
                  evt.stopPropagation();
                  onClose();
                }}
              >
                {t('common:close')}
              </TextButton>
            }
            depth={10}
            small
            onClose={onClose}
          >
            <StyledTooltipContent>{tooltip}</StyledTooltipContent>
          </Modal>
        )}
        {tooltip && (
          <StyledTooltipButton
            title={t('common:help')}
            onClick={() => setIsTooltipShown(true)}
          >
            <img src={iconQuestionImg} alt={t('common:help')} />
          </StyledTooltipButton>
        )}
      </StyledLabel>
    );
  },
);

export default LabeledField;
