import ApplicationContactDetails from 'common/types/ApplicationContactDetails';
import EligibilityChecklistItem from 'common/types/EligibilityChecklistItem';
import TrialFilters from 'common/types/TrialFilters';
import TrialResponse from 'common/types/TrialResponse';
import TrialSearchResponse from 'common/types/TrialSearchResponse';
import queryString from 'query-string';
import { checkIsSignedIn } from './auth';
import { BASE_URL, RESULTS_PER_PAGE } from './constants';
import authenticatedFetch from './util/authenticatedFetch';

export type ApplyResponse = {
  success?: boolean;
  redirect_url?: string;
};

export type InterestResponse = {
  success?: boolean;
};

export type TrialsAddedResponse = {
  count: number;
};

/**
 * Get a list of trials given a set of filters
 * @param {Object} filters The filters
 * @param {int} page The page number to get
 */
export async function getTrials(
  filters: TrialFilters,
  page = 1,
): Promise<TrialSearchResponse> {
  // Transform the filters into query param format (snake case) with page #
  const {
    age = null,
    condition = null,
    phase = null,
    country = null,
    state = null,
    gender = null,
    tags = null,
  } = filters;

  const queryParams = {
    age,
    condition,
    phase,
    country,
    state,
    gender,
    tags,

    page,
    per_page: RESULTS_PER_PAGE,
  };

  // Build query string with non-empty values
  const queryParamsString = queryString.stringify(queryParams, {
    arrayFormat: 'bracket',
    skipEmptyString: true,
    skipNull: true,
  });

  const response = await fetch(`${BASE_URL}/trials?${queryParamsString}`);

  return response.json();
}

/**
 * Get the details of a trial
 * @param {string} id The ID of the trial
 */
export async function getTrial(id: string): Promise<TrialResponse> {
  const response = await fetch(`${BASE_URL}/trials/${id}`);

  return response.json();
}

/**
 * Submit user's contact details for a trial and get back the application URL
 * @param {string} id The ID of the trial
 * @param {object} contactDetails The user's contact details
 * @param {object[]} inclusionResults The user's inclusion results
 * @param {object[]} exclusionResults The user's exclusion results
 */
export async function applyToTrial(
  id: string,
  contactDetails: ApplicationContactDetails,
  inclusionResults: EligibilityChecklistItem[],
  exclusionResults: EligibilityChecklistItem[],
  clientId: string | null = null,
): Promise<ApplyResponse> {
  const details = {
    ...contactDetails,
    exclusionResults,
    inclusionResults,
    clientId,
  };

  const isLoggedIn = await checkIsSignedIn();
  const fetchFunc = isLoggedIn ? authenticatedFetch : fetch;

  const headers: HeadersInit = new Headers();
  headers.set('Content-Type', 'application/json');

  const response = await fetchFunc(`${BASE_URL}/trials/${id}/redirect`, {
    method: 'POST',
    headers,
    body: JSON.stringify(details),
  });

  return response.json();
}

/**
 * Submit user's contact details for a trial interest
 * @param {string} id The ID of the trial
 * @param {object} contactDetails The user's contact details
 * @param {object[]} inclusionResults The user's inclusion results
 * @param {object[]} exclusionResults The user's exclusion results
 */
export async function submitTrialInterest(
  id: string,
  contactDetails: ApplicationContactDetails,
  inclusionResults: EligibilityChecklistItem[],
  exclusionResults: EligibilityChecklistItem[],
  clientId: string | null = null,
): Promise<InterestResponse> {
  const details = {
    ...contactDetails,
    exclusionResults,
    inclusionResults,
    clientId,
  };

  const isLoggedIn = await checkIsSignedIn();
  const fetchFunc = isLoggedIn ? authenticatedFetch : fetch;

  const headers: HeadersInit = new Headers();
  headers.set('Content-Type', 'application/json');

  const response = await fetchFunc(`${BASE_URL}/trials/${id}/interest`, {
    method: 'POST',
    headers,
    body: JSON.stringify(details),
  });

  return response.json();
}

/**
 * Get the number of trials added since a user last logged in
 * @returns {Promise<TrialsAddedResponse | null>} The number of trials added since the user last logged in
 */
export async function getNumberOfTrialsAdded(): Promise<TrialsAddedResponse | null> {
  const response = await authenticatedFetch(`${BASE_URL}/added_trials_count`);

  if (!response.ok) {
    return null;
  }
  return response.json();
}
