import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import ButtonAnchor from 'components/ButtonAnchor';

import { MAILCHIMP_LINK } from 'common/constants';

// Styled Components
const StyledSubscribeForm = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;

  background-color: ${(props) => props.theme.colors.mediumBackground};

  text-align: center;
`;

const StyledTitle = styled.h3`
  @media ${(props) => props.theme.devices.mobile} {
    text-align: center;
  }
`;

const StyledSubscribeContainer = styled.div`
  ${(props) => props.theme.paddedContent};
  max-width: 640px;
`;

const StyledSignUpSectionAction = styled.div`
  a {
    display: inline-block;
    box-sizing: border-box;
    min-width: 240px;
  }
`;

const StyledDisclaimer = styled.div`
  margin-top: 20px;

  font-size: ${(props) => props.theme.fontSizes.small};

  & > a {
    border-bottom: 2px solid ${(props) => props.theme.colors.link};
  }

  @media ${(props) => props.theme.devices.mobile} {
    text-align: center;
  }
`;

export type SubscribeFormProps = {
  theme?: DefaultTheme;
};

// Component
// Need to include theme prop for StyledComponents useTheme
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SubscribeForm = (props: SubscribeFormProps) => {
  const { t } = useTranslation(['common']);

  return (
    <StyledSubscribeForm>
      <StyledSubscribeContainer>
        <StyledTitle>{t('common:subscribe_title')}</StyledTitle>
        <StyledSignUpSectionAction>
          <ButtonAnchor href={MAILCHIMP_LINK} dark>
            {t('common:sign_up_now')}
          </ButtonAnchor>
        </StyledSignUpSectionAction>
        <StyledDisclaimer>
          <Trans i18nKey="common:subscribe_disclaimer">
            &nbps;
            <Link to="/terms" />
            &nbsp;
            <Link to="/privacy" />
          </Trans>
        </StyledDisclaimer>
      </StyledSubscribeContainer>
    </StyledSubscribeForm>
  );
};

export default SubscribeForm;
