import * as Sentry from '@sentry/react';
import { getTags } from 'common/api/tags';
import Tag from 'common/types/Tag';
import getTagsByCategory from 'common/util/getTagsByCategory';
import LabeledMultiSelect from 'components/LabeledMultiSelect';
import useIsMounted from 'hooks/useIsMounted';
import { TAG_CATEGORIES } from 'modules/Search/constants';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components';

// Styled Components
const StyledTagsSelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type TagsSelection = {
  stageTags: number[];
  phaseTags: number[];
  typeTags: number[];
  otherTags: number[];
};

export type TagsSelectProps = {
  className?: string;
  selectedTags: number[];
  onTagsChange: (tags: number[]) => void;
  theme?: DefaultTheme;
};

const TagsSelect = ({
  className,
  selectedTags,
  onTagsChange,
}: TagsSelectProps) => {
  const { t } = useTranslation();
  const [availableTags, setAvailableTags] = useState<Tag[]>([]);
  const isMounted = useIsMounted();

  useEffect(() => {
    const callLoadTags = async () => {
      try {
        const tagsResponse = await getTags();
        if (!isMounted.current) {
          return;
        }

        setAvailableTags(tagsResponse.tags);
      } catch (ex) {
        Sentry.captureException(ex);
      }
    };

    callLoadTags();
  }, [isMounted]);

  const onTagsSelectionChange = (updatedTagsSelection: TagsSelection) => {
    onTagsChange([
      ...updatedTagsSelection.otherTags,
      ...updatedTagsSelection.phaseTags,
      ...updatedTagsSelection.stageTags,
      ...updatedTagsSelection.typeTags,
    ]);
  };

  // Group selected tags by category
  const tagsSelection: TagsSelection = {
    otherTags: [],
    phaseTags: [],
    stageTags: [],
    typeTags: [],
  };
  selectedTags.forEach((tagId) => {
    const tag = availableTags.find((availableTag) => availableTag.id === tagId);
    if (!tag) {
      return;
    }

    switch (tag.category) {
      case TAG_CATEGORIES.PHASE: {
        tagsSelection.phaseTags.push(tagId);
        break;
      }
      case TAG_CATEGORIES.STAGE: {
        tagsSelection.stageTags.push(tagId);
        break;
      }
      case TAG_CATEGORIES.TYPE: {
        tagsSelection.typeTags.push(tagId);
        break;
      }
      case TAG_CATEGORIES.UNCATEGORIZED: {
        tagsSelection.otherTags.push(tagId);
        break;
      }
      default: {
        // No-op - invalid tags ignored
      }
    }
  });

  return (
    <StyledTagsSelect className={className}>
      <LabeledMultiSelect<number>
        defaultValue={t('search:empty_tags')}
        label={t('search:tag_stage')}
        tooltip={t('search:tooltip_tags_stage')}
        options={getTagsByCategory(availableTags, TAG_CATEGORIES.STAGE).map(
          (tag) => ({
            label: tag.name,
            value: tag.id,
          }),
        )}
        values={tagsSelection.stageTags}
        showOther={false}
        onValuesChange={(values: number[]) =>
          onTagsSelectionChange({
            ...tagsSelection,
            stageTags: values,
          })
        }
      />

      <LabeledMultiSelect<number>
        defaultValue={t('search:empty_tags')}
        label={t('search:tag_phase')}
        tooltip={t('search:tooltip_tags_phase')}
        options={getTagsByCategory(availableTags, TAG_CATEGORIES.PHASE).map(
          (tag) => ({
            label: tag.name,
            value: tag.id,
          }),
        )}
        values={tagsSelection.phaseTags}
        showOther={false}
        onValuesChange={(values: number[]) =>
          onTagsSelectionChange({
            ...tagsSelection,
            phaseTags: values,
          })
        }
      />

      <LabeledMultiSelect<number>
        defaultValue={t('search:empty_tags')}
        label={t('search:tag_type')}
        tooltip={t('search:tooltip_tags_type')}
        options={getTagsByCategory(availableTags, TAG_CATEGORIES.TYPE).map(
          (tag) => ({
            label: tag.name,
            value: tag.id,
          }),
        )}
        values={tagsSelection.typeTags}
        showOther={false}
        onValuesChange={(values: number[]) =>
          onTagsSelectionChange({
            ...tagsSelection,
            typeTags: values,
          })
        }
      />

      <LabeledMultiSelect<number>
        defaultValue={t('search:empty_tags')}
        label={t('search:tag_other')}
        tooltip={t('search:tooltip_tags_other')}
        options={getTagsByCategory(
          availableTags,
          TAG_CATEGORIES.UNCATEGORIZED,
        ).map((tag) => ({
          label: tag.name,
          value: tag.id,
        }))}
        values={tagsSelection.otherTags}
        showOther={false}
        onValuesChange={(values: number[]) =>
          onTagsSelectionChange({
            ...tagsSelection,
            otherTags: values,
          })
        }
      />
    </StyledTagsSelect>
  );
};

export default TagsSelect;
