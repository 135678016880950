import 'common/i18n';
import { createBrowserHistory as createHistory } from 'history';
import { Router, withRouter } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { ThemeProvider } from 'styled-components/macro';

// Theme
import healMaryTheme, { GlobalStyle } from 'themes/HealMary';

import { AuthContextWrapper } from 'contexts/AuthContext';
import { IntegratorContextWrapper } from 'contexts/IntegratorContext';
import HealMary from './HealMary';

// Router setup
const history = createHistory();
const HealMaryWithRouter = withRouter(HealMary);

export default () => (
  <Router history={history}>
    <LastLocationProvider>
      <ThemeProvider theme={healMaryTheme}>
        <>
          <GlobalStyle />
          <IntegratorContextWrapper isIntegratorMode={false}>
            <AuthContextWrapper>
              <HealMaryWithRouter />
            </AuthContextWrapper>
          </IntegratorContextWrapper>
        </>
      </ThemeProvider>
    </LastLocationProvider>
  </Router>
);
