import * as Sentry from '@sentry/react';
import { postProfile } from 'common/api/profile';
import Profile, {
  MedicalDetails,
  MedicalDetailsEnum,
} from 'common/types/Profile';
import Button from 'components/Button';
import CheckboxRadioGroup from 'components/CheckboxRadioGroup';
import SuccessMessage from 'components/SuccessMessage';
import useIsMounted from 'hooks/useIsMounted';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledEditMedicalDetailsForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media ${(props) => props.theme.devices.mobile} {
    gap: 40px;
  }
`;

const StyledQuestionsSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media ${(props) => props.theme.devices.mobile} {
    gap: 32px;
    font-size: ${(props) => props.theme.fontSizes.small};
  }
`;

const StyledButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${(props) => props.theme.devices.mobile} {
    button {
      width: 100%;
    }
  }
`;

const StyledError = styled.span`
  color: ${(props) => props.theme.colors.error};
  text-align: center;
  margin-bottom: 32px;
`;

export type EditMedicalDetailsFormProps = {
  currentMedicalDetails: MedicalDetails;
  onProfileUpdated: (profile: Profile) => void;
  theme?: DefaultTheme;
};

const EditMedicalDetailsForm = ({
  currentMedicalDetails,
  onProfileUpdated,
  ...rest
}: EditMedicalDetailsFormProps) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorKey, setErrorKey] = useState<string | null>(null);
  const [preferencesSaved, setPreferencesSaved] = useState<boolean>(false);
  const [medicalDetails, setMedicalDetails] = useState<MedicalDetails>(
    currentMedicalDetails,
  );

  const onSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    return false;
  };

  const onUpdateNotificationPreferences = async () => {
    setErrorKey(null);

    try {
      setIsSubmitting(true);
      const profile = await postProfile({
        medical_details: medicalDetails,
      });
      if (!isMounted.current) {
        return;
      }
      setIsSubmitting(false);

      if (!profile) {
        setErrorKey('common:unknown_error');
        return;
      }

      onProfileUpdated(profile);
      setPreferencesSaved(true);
      setTimeout(() => {
        if (!isMounted.current) {
          return;
        }
        setPreferencesSaved(false);
      }, 5000);
    } catch (ex) {
      if (!isMounted.current) {
        return;
      }

      setErrorKey('common:unknown_error');
      setIsSubmitting(false);
      Sentry.captureException(ex);
    }
  };

  return (
    <StyledEditMedicalDetailsForm onSubmit={onSubmit} {...rest}>
      <StyledQuestionsSection>
        {Object.values(MedicalDetailsEnum).map((medicalDetail) => (
          <CheckboxRadioGroup
            key={medicalDetail}
            legend={
              <legend>{t(`profile:medical_detail_${medicalDetail}`)}</legend>
            }
            options={[
              { label: t('common:yes'), value: true.toString() },
              { label: t('common:no'), value: false.toString() },
            ]}
            value={
              medicalDetails[medicalDetail] !== undefined
                ? medicalDetails[medicalDetail]!.toString()
                : ''
            }
            onValueChange={(value) =>
              setMedicalDetails({
                ...medicalDetails,
                [medicalDetail]: value === 'true',
              })
            }
          />
        ))}
      </StyledQuestionsSection>
      <StyledButtonRow>
        {errorKey && <StyledError>{t(errorKey)}</StyledError>}
        <SuccessMessage
          message={t('profile:medical_history_updated')}
          shown={preferencesSaved}
        />
        <Button
          disabled={isSubmitting}
          type="button"
          dark
          onClick={onUpdateNotificationPreferences}
        >
          {t('profile:save_medical_history')}
        </Button>
      </StyledButtonRow>
    </StyledEditMedicalDetailsForm>
  );
};

export default EditMedicalDetailsForm;
