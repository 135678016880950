import {
  CovidConditionDetails,
  CovidDetailsEnumWithoutMedical,
} from 'common/api/conditions';
import CheckboxRadioGroup from 'components/CheckboxRadioGroup';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledEditCovidConditionForm = styled.div``;

const StyledHeading = styled.h1`
  font-size: ${(props) => props.theme.fontSizes.h4};
  margin-bottom: 24px;

  @media ${(props) => props.theme.devices.mobile} {
    margin-bottom: 20px;
    font-size: ${(props) => props.theme.fontSizes.mobile.h4};
  }
`;

const StyledQuestions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media ${(props) => props.theme.devices.mobile} {
    gap: 32px;
    font-size: ${(props) => props.theme.fontSizes.small};
  }
`;

export type EditCovidConditionFormProps = {
  disabled?: boolean;
  error?: string;
  covidDetails: CovidConditionDetails;
  onValueChange: (covidConditionDetails: CovidConditionDetails) => void;
  theme?: DefaultTheme;
};

const EditCovidConditionForm = ({
  disabled,
  covidDetails,
  onValueChange,
  ...rest
}: EditCovidConditionFormProps) => {
  const { t } = useTranslation();

  return (
    <StyledEditCovidConditionForm {...rest}>
      <StyledHeading>{t('profile:please_answer_the_following')}</StyledHeading>
      <StyledQuestions>
        {CovidDetailsEnumWithoutMedical.map((covidDetail) => (
          <CheckboxRadioGroup
            key={covidDetail}
            disabled={disabled}
            legend={<legend>{t(`profile:covid_detail_${covidDetail}`)}</legend>}
            options={[
              { label: t('common:yes'), value: true.toString() },
              { label: t('common:no'), value: false.toString() },
            ]}
            value={
              covidDetails[covidDetail] !== undefined
                ? covidDetails[covidDetail]!.toString()
                : ''
            }
            onValueChange={(value) =>
              onValueChange({
                ...covidDetails,
                [covidDetail]: value === 'true',
              })
            }
          />
        ))}
      </StyledQuestions>
    </StyledEditCovidConditionForm>
  );
};

export default EditCovidConditionForm;
