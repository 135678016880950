import { useEffect, useState } from 'react';

/**
 * Hook to track if the page has scrolled
 * @param threshold How far scrolling has to happen before considered as scrolled
 * @returns {boolean} If the page has been scrolled
 */
export default function useIsScrolled(threshold: number = 5) {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  useEffect(() => {
    const onScroll = () => {
      setIsScrolled(window.pageYOffset >= threshold);
    };

    window.addEventListener('scroll', onScroll, { passive: true });

    onScroll();
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [threshold]);

  return isScrolled;
}
