import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled, { css, DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledTabNavigation = styled.nav<{ dark: boolean; isVertical: boolean }>`
  display: flex;
  justify-content: center;
  ${(props) =>
    !props.isVertical &&
    css`
      height: 88px;
    `}

  ${(props) =>
    props.dark &&
    css`
      background-color: ${(props) => props.theme.colors.darkBackground};
      color: ${props.theme.colors.lightText};
    `}

  @media ${(props) => props.theme.devices.mobile} {
    ${(props) =>
      !props.isVertical &&
      css`
        height: 80px;
      `}
  }
`;

const StyledTabNavigationContent = styled.div<{
  dark: boolean;
  isVertical: boolean;
  navItemWidth?: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;

  ${(props) =>
    props.isVertical &&
    css`
      flex-direction: column;
      flex: 1;
    `}

  @media ${(props) => props.theme.devices.mobile} {
    width: 100%;
  }

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: ${(props) =>
      props.navItemWidth ? `${props.navItemWidth}px` : '100%'};
    height: 100%;

    font-size: ${(props) => props.theme.fontSizes.small};
    text-align: center;
    text-decoration: none;
    border-bottom: none;

    ${(props) =>
      props.isVertical &&
      css`
        box-sizing: border-box;
        flex-direction: row;
        justify-content: flex-start;
        height: 72px;
        padding-left: 40px;
        font-size: ${(props) => props.theme.fontSizes.regular};
        text-align: left;
      `}

    ${(props) =>
      props.dark &&
      css`
        color: ${props.theme.colors.lightText};
      `}

    @media ${(props) => props.theme.devices.mobile} {
      width: 25%;
      padding-left: 4px;
      padding-right: 4px;
      font-size: ${(props) => props.theme.fontSizes.xxsmall};
    }

    img {
      width: 24px;
      height: 24px;
      margin-bottom: 8px;

      ${(props) =>
        props.isVertical &&
        css`
          width: 30px;
          height: 30px;
          margin-bottom: 0;
          margin-right: 20px;
        `}

      @media ${(props) => props.theme.devices.mobile} {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

const StyledLabel = styled.span`
  display: block;

  @media ${(props) => props.theme.devices.mobile} {
    display: none;
  }
`;

const StyledLabelMobile = styled.span`
  display: none;

  @media ${(props) => props.theme.devices.mobile} {
    display: block;
  }
`;

const StyledActiveIndicator = styled.div<{
  activeIndex?: number;
  navItemWidth?: number;
}>`
  position: absolute;
  left: 0;
  bottom: 0;
  width: ${(props) =>
    props.activeIndex !== undefined && props.navItemWidth
      ? `${props.navItemWidth}px`
      : 0};
  height: 6px;

  ${(props) =>
    props.navItemWidth &&
    css`
      transform: translateX(${(props.activeIndex ?? 0) * props.navItemWidth}px);
    `}

  background-color: ${(props) => props.theme.colors.active};

  transition: transform 0.25s, width 0.25s;

  @media ${(props) => props.theme.devices.mobile} {
    width: ${(props) => (props.activeIndex !== undefined ? '25%' : 0)};
    transform: translateX(${(props) => (props.activeIndex ?? 0) * 25}vw);
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledLink = styled(({ isActive, isVertical, ...rest }) => (
  <Link {...rest} />
))<{ isActive: boolean; isVertical: boolean }>`
  background-color: ${(props) =>
    props.isActive && props.isVertical
      ? props.theme.colors.backgroundActive
      : 'transparent'};
  transition: background-color 0.25s;
`;

export type NavItem = {
  icon: string;
  textKey: string;
  textKeyMobile?: string;
  path: string;
};

export type TabNavigationProps = {
  dark?: boolean;
  isVertical?: boolean;
  navItems: NavItem[];
  navItemWidth?: number;
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'nav'>;

const TabNavigation = ({
  dark = false,
  isVertical = false,
  navItems,
  navItemWidth,
  ...rest
}: TabNavigationProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  let activeIndex = -1;
  let matchLength = 0;
  navItems.forEach(({ path }, index) => {
    if (location.pathname.startsWith(path)) {
      if (path.length > matchLength) {
        matchLength = path.length;
        activeIndex = index;
      }
    }
  });

  return (
    <StyledTabNavigation dark={dark} isVertical={isVertical} {...rest}>
      <StyledTabNavigationContent
        dark={dark}
        isVertical={isVertical}
        navItemWidth={navItemWidth}
      >
        {navItems.map(({ icon, textKey, textKeyMobile, path }, index) => (
          <StyledLink
            isActive={activeIndex === index}
            isVertical={isVertical}
            key={path}
            to={path}
          >
            <img src={icon} alt="" />
            <StyledLabel>{t(textKey)}</StyledLabel>
            <StyledLabelMobile>
              {textKeyMobile ? t(textKeyMobile) : t(textKey)}
            </StyledLabelMobile>
          </StyledLink>
        ))}
        <StyledActiveIndicator
          activeIndex={activeIndex}
          navItemWidth={navItemWidth}
        />
      </StyledTabNavigationContent>
    </StyledTabNavigation>
  );
};

export default TabNavigation;
