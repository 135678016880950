import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledSummaryList = styled.ul`
  margin: 0;
  padding-left: 25px;
  list-style: initial;

  & > li {
    margin-bottom: 10px;
  }
`;

export type SummaryListProps = {
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'ul'>;

// Component
const SummaryList = ({ children }: SummaryListProps) => (
  <StyledSummaryList>{children}</StyledSummaryList>
);

export default SummaryList;
