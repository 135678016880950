import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import SelectTitle from 'components/SelectTitle';

import { GENDERS } from 'modules/Search/constants';
import SearchIconSelect from '../SearchIconSelect';

// Styled Components
const StyledGenderSelect = styled.div`
  display: flex;
  flex-direction: column;
`;

export type GenderSelectProps = {
  selectedGenders: string[];
  onGendersChange: (values: string[]) => void;
  theme?: DefaultTheme;
};

// Component
const GenderSelect = ({
  selectedGenders,
  onGendersChange,
}: GenderSelectProps) => {
  const { t } = useTranslation(['search']);

  const genderOptions = Object.values(GENDERS).map((gender) => ({
    text: t(gender.i18nKey),
    value: gender.value,
  }));

  return (
    <StyledGenderSelect>
      <SelectTitle>{t('search:gender_title')}</SelectTitle>
      <SearchIconSelect
        options={genderOptions}
        selectedValues={selectedGenders}
        onChange={onGendersChange}
        light
      />
    </StyledGenderSelect>
  );
};

GenderSelect.propTypes = {
  selectedGenders: PropTypes.arrayOf(PropTypes.string).isRequired,
  onGendersChange: PropTypes.func.isRequired,
};

export default GenderSelect;
