import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledDetailsSubList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-wrap: break-word;

  & > li {
    padding: 20px;
    margin: 10px 0;

    &:nth-child(even) {
      background-color: ${(props) => props.theme.colors.tableEvenRow};
    }

    &:nth-child(odd) {
      background-color: ${(props) => props.theme.colors.tableOddRow};
    }
  }
`;

export type DetailsSubListProps = {
  children: React.ReactNode;
  theme?: DefaultTheme;
};

// Component
const DetailsSubList = ({ children }: DetailsSubListProps) => (
  <StyledDetailsSubList>{children}</StyledDetailsSubList>
);

export default DetailsSubList;
