import * as Sentry from '@sentry/react';
import { validateMagicLink } from 'common/api/auth';
import { PATHS } from 'common/constants';
import LoadingOverlay from 'components/LoadingOverlay';
import AuthContext from 'contexts/AuthContext';
import QueryString from 'qs';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  ${(props) => props.theme.paddedContent};
  padding-top: 40px;
  padding-bottom: 40px;
`;

type MagicLinkDetails = {
  email?: string;
  code?: string;
};

export type MagicLinkProps = {
  theme?: DefaultTheme;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MagicLink = (props: MagicLinkProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { recheckSession } = useContext(AuthContext);
  const { email, code } = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as MagicLinkDetails;

  useEffect(() => {
    if (!email || !code) {
      history.replace(PATHS.SEARCH);
      return;
    }
    const handleMagicLink = async () => {
      try {
        await validateMagicLink(email, code);
        await recheckSession();
        history.replace(PATHS.SEARCH);
      } catch (err) {
        Sentry.captureException(err);
        history.replace(`${PATHS.SEARCH}?magicExpired=true`);
      }
    };

    handleMagicLink();
    // Disabling here to ensure only a single run
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledContainer>
      <h1>{t('common:loading')}</h1>
      <LoadingOverlay isShown />
    </StyledContainer>
  );
};

export default MagicLink;
