import PhoneInputWithCountrySelect, {
  Props as ReactPhoneInputProps,
} from 'react-phone-number-input';
import styled, { css, DefaultTheme } from 'styled-components/macro';

// Styled Components
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledPhoneInput = styled(({ hasError, ...rest }) => (
  <PhoneInputWithCountrySelect {...rest} />
))<{
  hasError: boolean;
}>`
  input {
    padding: 12px 15px;
    font-family: ${(props) => props.theme.fonts.main};
    font-size: 18px;

    border: 1px solid ${(props) => props.theme.colors.inputBorder};
    border-radius: 5px;

    box-shadow: 0 0 10px -5px ${(props) => props.theme.rawColors.black20};

    &:focus {
      outline: none;
      border-color: ${(props) => props.theme.colors.active};
    }

    ${(props) =>
      props.hasError &&
      css`
        border-color: ${props.theme.colors.error};
        color: ${props.theme.colors.error};
      `}
  }
`;

export type PhoneInputProps = {
  hasError?: boolean;
  theme?: DefaultTheme;
} & ReactPhoneInputProps;

// Note: When translations support is added, the 'labels' property can be specified
// see the following for details: https://gitlab.com/catamphetamine/react-phone-number-input#localization
const PhoneInput = ({ hasError = false, ...rest }: PhoneInputProps) => (
  <StyledPhoneInput
    hasError={hasError}
    international
    limitMaxLength
    {...rest}
  />
);

export default PhoneInput;
