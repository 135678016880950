/* eslint-disable react/prop-types */
import LabeledField from 'components/LabeledField';
import PhoneInput from 'components/PhoneInput';
import { PhoneInputProps } from 'components/PhoneInput/PhoneInput';
import React from 'react';
import { DefaultTheme } from 'styled-components/macro';

// Styled Components

export type LabeledPhoneInputProps = {
  hasError?: boolean;
  label: React.ReactNode;
  isRequired?: boolean;
  theme?: DefaultTheme;
} & PhoneInputProps;

const LabeledPhoneInput = React.forwardRef<
  HTMLLabelElement,
  LabeledPhoneInputProps
>(({ hasError, isRequired, label, ...rest }: LabeledPhoneInputProps, ref) => (
  <LabeledField
    hasError={hasError}
    label={label}
    isRequired={isRequired}
    ref={ref}
  >
    <PhoneInput hasError={hasError} {...rest} />
  </LabeledField>
));

export default LabeledPhoneInput;
