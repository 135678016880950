import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledContentCard = styled.div`
  margin-top: 30px;
  padding: 40px 70px;

  background-color: ${(props) => props.theme.colors.background};
  border: 2px solid ${(props) => props.theme.colors.inputBorder};
  border-radius: 20px;

  box-shadow: 0 0 20px -5px ${(props) => props.theme.rawColors.black20};

  @media ${(props) => props.theme.devices.tablet} {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media ${(props) => props.theme.devices.mobile} {
    ${(props) => props.theme.paddedContent}

    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;

    margin-top: 0;
    padding-top: 30px;
    padding-bottom: 30px;

    box-shadow: none;
  }
`;

export type ContentCardProps = {
  theme?: DefaultTheme;
} & Omit<React.ComponentProps<'div'>, 'ref'>;

// Component
const ContentCard = ({ children, ...rest }: ContentCardProps) => (
  <StyledContentCard {...rest}>{children}</StyledContentCard>
);

export default ContentCard;
