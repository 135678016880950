import React, { useContext, useEffect, useState } from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Condition,
  ConditionTypeEnum,
  CovidConditionDetails,
  getConditions,
} from 'common/api/conditions';
import { getCovidTrials } from 'common/api/covidTrials';
import { getProfile } from 'common/api/profile';
import CovidFilters from 'common/types/CovidFilters';
import Profile from 'common/types/Profile';

import AgeInput from 'components/AgeInput';
import { AgeOption } from 'components/AgeInput/AgeInput';
import Button from 'components/Button';
import LocationSelect from 'components/LocationSelect';
import SearchPage from 'components/SearchPage';
import YesNoInput from 'components/YesNoInput';

import AuthContext from 'contexts/AuthContext';

import { DEFAULT_FILTERS } from './constants';

import ModifySearchModal from './components/ModifySearchModal';
import useIsMounted from 'hooks/useIsMounted';
import { COUNTRIES_BY_ALPHA_2 } from 'common/countries';
import SearchSourceControl from 'components/SearchSourceControl';

// Styled Components
const StyledActionContainer = styled.div`
  margin-bottom: 40px;
  text-align: center;

  button {
    margin-left: auto;
    margin-right: auto;
  }
`;

const StyledLoading = styled.div``;

// Helper
const getFilterUrl = (step: number, filters: CovidFilters) => {
  const filterParams = queryString.stringify(
    { ...filters, step },
    { arrayFormat: 'bracket', skipEmptyString: true, skipNull: true },
  );
  return `/covidsearch?${filterParams}`;
};

export type SearchCovidProps = {
  theme?: DefaultTheme;
};

// Component
// Need to include props to support StyledComponents useTheme
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SearchCovid = (props: SearchCovidProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const isMounted = useIsMounted();

  const queryParams = queryString.parse(location.search, {
    arrayFormat: 'bracket',
    parseNumbers: true,
  });
  const currentStep = (queryParams.step as number) || 0;
  const filters = {
    ...DEFAULT_FILTERS,
    ...queryString.parse(location.search, {
      arrayFormat: 'bracket',
      parseNumbers: true,
      parseBooleans: true,
    }),
  } as CovidFilters;
  const [isCriteriaModalShown, setIsCriteriaModalShown] =
    useState<boolean>(false);

  const [isSearchSourceShown, setIsSearchSourceShown] = useState<boolean>(
    currentStep === 0,
  );
  const { isAuthChecked, isAuthenticated } = useContext(AuthContext);
  const [conditions, setConditions] = useState<Condition[] | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [isProfileChecked, setIsProfileChecked] = useState<boolean>(false);
  const [isSearchingWithMedicalInfo, setIsSearchingWithMedicalInfo] =
    useState<boolean>(false);

  useEffect(() => {
    const loadConditions = async () => {
      try {
        const response = await getConditions();
        if (!isMounted.current) {
          return;
        }
        setConditions(response.conditions);
      } catch (ex) {
        if (!isMounted.current) {
          return;
        }
        // On error, ignore user's conditions
        setConditions([]);
      }

      try {
        const response = await getProfile();
        if (!isMounted.current) {
          return;
        }
        setProfile(response);
      } catch (ex) {
        if (!isMounted.current) {
          return;
        }
      }
      setIsProfileChecked(true);
    };
    if (isAuthChecked && isAuthenticated) {
      loadConditions();
    }
  }, [isAuthChecked, isAuthenticated, isMounted]);

  const onNewSearch = () => {
    setIsSearchingWithMedicalInfo(false);
    setIsSearchSourceShown(false);
  };

  const onSearchWithProfile = () => {
    if (!profile) {
      return;
    }

    const covidCondition = conditions
      ? conditions.find(({ type }) => type === ConditionTypeEnum.covid)
      : null;
    const conditionDetails = covidCondition?.details as
      | CovidConditionDetails
      | undefined;
    setIsSearchSourceShown(false);
    setIsSearchingWithMedicalInfo(true);

    history.replace(
      getFilterUrl(stepControls.length, {
        age: profile.age,
        country: profile.address_country_alpha_2
          ? COUNTRIES_BY_ALPHA_2[profile.address_country_alpha_2]
          : undefined,
        state: profile.address_state,
        inHospital: conditionDetails?.in_hospital,
        healthcareWorker: conditionDetails?.healthcare_worker,
        requireVentilator: conditionDetails?.require_ventilator,
        onChemotherapy: conditionDetails?.on_chemotherapy,
        breastfeeding: profile.medical_details.breastfeeding,
        inClinicalTrial: profile.medical_details.in_clinical_trial,
        previousClinicalTrial: profile.medical_details.previous_clinical_trial,
        haveImmuneDiseases: profile.medical_details.have_immune_diseases,
        takingImmunosuppressants:
          profile.medical_details.taking_immunosuppressants,
      }),
    );
  };

  const setUpdatedFilters = (updatedFilters: CovidFilters, advance = false) => {
    // Update the URL for linking
    history.replace(
      getFilterUrl(currentStep + (advance ? 1 : 0), updatedFilters),
    );
  };

  const getSetFilter =
    (filter: string, advance = true) =>
    (value?: string | boolean | number) => {
      // Reset page filter if other filter changes
      const updatedPage = filter !== 'page' ? 1 : filters.page;
      const updatedFilters = {
        ...filters,
        page: updatedPage,
        [filter]: value,
      };

      setUpdatedFilters(updatedFilters, advance);
    };

  const onCountryAndStateChange = (
    country: string,
    state?: string,
    countryHasStates?: boolean,
  ) => {
    const updatedFilters = {
      ...filters,
      country,
      state,
    };

    const advance =
      country !== '' &&
      countryHasStates !== undefined &&
      ((countryHasStates && state !== undefined) || !countryHasStates);

    setUpdatedFilters(updatedFilters, advance);
  };

  const onBack = () => {
    if (currentStep === 0) {
      setIsSearchSourceShown(true);
    } else {
      history.replace(getFilterUrl(currentStep - 1, filters));
    }
  };

  const onNext = () => {
    history.replace(getFilterUrl(currentStep + 1, filters));
  };

  // Define controls for each step
  // Need to include props to support StyledComponents useTheme
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const stepControls = [
    // Step #1: Location
    <LocationSelect
      key="location"
      selectedCountry={filters.country}
      selectedState={filters.state}
      title={t('searchCovid:where_to_search')}
      onStateChange={getSetFilter('state')}
      onCountryAndStateChange={onCountryAndStateChange}
    />,

    // Step #2: Age
    <AgeInput
      key="age"
      value={filters.age}
      onChange={(newValue) =>
        getSetFilter('age')((newValue as AgeOption)?.value)
      }
    />,

    // Step #3: Other clinical
    <YesNoInput
      key="other_clinical"
      question={t('searchCovid:are_you_in_other_trial')}
      value={filters.inClinicalTrial}
      onChange={getSetFilter('inClinicalTrial')}
    />,

    // Step #4: Previous clinical trial
    <YesNoInput
      key="previous_clinical_trial"
      question={t('searchCovid:have_previous_trial')}
      value={filters.previousClinicalTrial}
      onChange={getSetFilter('previousClinicalTrial')}
    />,

    // Step #5: Hospitalized
    <YesNoInput
      key="hospitalized"
      question={t('searchCovid:are_you_hospitalized')}
      value={filters.inHospital}
      onChange={getSetFilter('inHospital')}
    />,

    // Step #6: Healthcare Worker
    <YesNoInput
      key="healthcare_worker"
      question={t('searchCovid:are_you_a_healthcare_worker')}
      value={filters.healthcareWorker}
      onChange={getSetFilter('healthcareWorker')}
    />,

    // Step #7: Ventilator
    <YesNoInput
      key="ventilator"
      question={t('searchCovid:do_you_require_a_ventilator')}
      value={filters.requireVentilator}
      onChange={getSetFilter('requireVentilator')}
    />,

    // Step #8: Have immune related diseases
    <YesNoInput
      key="immune_related_diseases"
      question={t('searchCovid:do_you_have_immune_disease')}
      value={filters.haveImmuneDiseases}
      onChange={getSetFilter('haveImmuneDiseases')}
    />,

    // Step #9: On Chemo
    <YesNoInput
      key="on_chemo"
      question={t('searchCovid:are_you_on_chemo')}
      value={filters.onChemotherapy}
      onChange={getSetFilter('onChemotherapy')}
    />,

    // Step #10: Taking immunosuppressants
    <YesNoInput
      key="immunosuppressants"
      question={t('searchCovid:are_you_taking_immuno')}
      value={filters.takingImmunosuppressants}
      onChange={getSetFilter('takingImmunosuppressants')}
    />,

    // Step #11: Pregnant or breastfeeding
    <YesNoInput
      key="pregnant_or_breastfeeding"
      question={t('searchCovid:are_you_pregnant')}
      value={filters.breastfeeding}
      onChange={getSetFilter('breastfeeding')}
    />,
  ];

  return (
    <SearchPage
      currentStep={currentStep}
      disclaimer={t('searchCovid:disclaimer')}
      filters={filters}
      filterControls={
        <StyledActionContainer>
          <Button onClick={() => setIsCriteriaModalShown(true)} dark>
            {t('searchCovid:modify_criteria')}
          </Button>
          {isCriteriaModalShown && (
            <ModifySearchModal
              filters={filters}
              onUpdateFilters={setUpdatedFilters}
              onClose={() => setIsCriteriaModalShown(false)}
            />
          )}
        </StyledActionContainer>
      }
      hasPreSearchControls={isAuthenticated}
      preSearchControls={
        !isAuthChecked ||
        (isAuthChecked && isAuthenticated && !isProfileChecked) ? (
          <StyledLoading>{t('common:loading')}</StyledLoading>
        ) : (
          isAuthenticated &&
          isSearchSourceShown && (
            <SearchSourceControl
              title={t('searchCovid:how_would_you_like_to_search_covid_trials')}
              onSearchWithConditions={onSearchWithProfile}
              onStartNew={onNewSearch}
            />
          )
        )
      }
      resultsTitle={
        isSearchingWithMedicalInfo
          ? t('searchCovid:results_based_on_medical_profile')
          : t('searchCovid:results_based_on_selection')
      }
      searchTitle={t('searchCovid:going_to_ask_questions')}
      stepControls={stepControls}
      title={t('searchCovid:browser_title')}
      trialBaseUrl="/covidtrials"
      isStepWithNext={(step) => step === 0}
      isStepWithSkip={() => false}
      onBack={onBack}
      onNext={onNext}
      onPageSelect={getSetFilter('page', false)}
      searchTrials={() =>
        getCovidTrials(
          {
            age: filters.age,
            country: filters.country,
            state: filters.state,
            in_clinical_trial: filters.inClinicalTrial,
            previous_clinical_trial: filters.previousClinicalTrial,
            in_hospital: filters.inHospital,
            healthcare_worker: filters.healthcareWorker,
            require_ventilator: filters.requireVentilator,
            have_immune_diseases: filters.haveImmuneDiseases,
            on_chemotherapy: filters.onChemotherapy,
            taking_immunosuppressants: filters.takingImmunosuppressants,
            breastfeeding: filters.breastfeeding,
          },
          filters.page,
        )
      }
    />
  );
};

export default SearchCovid;
