export const EVENT_CLOSE_SURVEY = 'Close: Survey';
export const EVENT_CLOSE_CONTACT_INFORMATION = 'Close: Contact Information';
export const EVENT_SUBMIT = 'SUBMIT';
export const EVENT_CHECK = 'CHECK';
export const EVENT_PAGE_VIEW = 'pageview';
export const EVENT_CLICK = 'gtm.click';
export const EVENT_INCOMPLETE = 'INCOMPLETE';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

/**
 * Pushes data to the GTM data layer for tracking
 * @param {object} data The GTM details to push
 */
export function gtmPush(data: any) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
  // Flush data layer values
  window.dataLayer.push({
    surveyName: undefined,
    surveyQuestions: undefined,
    eventAction: undefined,
    eventStatus: undefined,
    eventCategory: undefined,
    question: undefined,
    eventLabel: undefined,
  });
}
