export default {
  'about_alexandra': '<0>Dr. Alexandra T. Greenhill is one of Canada\'s leading physicians in digital health innovation.</0><1>After a 15+ year career in director and C-level leadership roles, she has spent the last few years leading and advising Canada\'s most innovative technology companies. She is currently the CEO / Chief Medical Officer of Careteam Technologies, the virtual health collaboration platform.</1>',
  'about_cassandra': 'Cassandra Hui (the founder heart) has had several years in creating in-person and digital engagements including fostering the community at the Princess Margaret Cancer Foundation by removing the barriers of access between surgeons and the patient and philanthropic communities.',
  'about_chirag': 'Chirag Kariya has 10+ years of experience in working at the intersection of science and business including strategic planning, engaging key opinion leaders, establishing and fostering strategic collaborations and partnerships with Biopharma. He has expertise in managing drug development (preclinical and clinical) programs to support and strategize commercialization of novel therapeutics. Chirag has research experience in therapeutic  areas such as Respiratory/Pulmonary, Women’s Health, and Oncology.',
  'about_hao': 'The backend brain. Hao is an innovative software engineer who is passion about creating real life applications that extend over multi-channels. Hao has worked previously  as a team lead at growing startups in Toronto.',
  'about_timothy': 'Timothy Yeung is a seasoned product management professional with a decade of professional experience in the digital space. He has a record of delivering innovative products, including a smart recommendation tool for smart home, an app that helps families manage the care of their aging loved ones, and an NLP-powered health app. Timothy serves as a board member at ProductBC, and also has extensive experience as a mentor, at organizations such as TELUS, ProductBC, and Sauder School of Business.',

  'about_us': 'About Heal Mary',
  'about_us_content': '<0>Heal Mary started when our founding team rallied behind the cancer cause after our founder (Cassandra) had witnessed her sisters journey through their early stage breast cancer diagnosis. Cassandra\'s mom was already a Stage IV cancer survivor.</0><1>The team knew there had to be a better way to find out about clinical trials and more importantly, to enrol into Clinical Trials. The team had already found success after winning Scotiabank\'s Financial Hackathon in 2016 and united, they came together to work on building the concept and user experience for an application that would help sort through clinical trials from all over the world.</1><2>After a year of research and interviews with patients, caregivers and doctors to better understand their needs, the team was able to create and release this MVP. But, this is only the beginning of what Heal Mary will do to ease the burden of clinical trials recruitment.</2><3>Welcome to Heal Mary. We\'re ready to take this journey with you and your loved ones.</3>',
  'advisors_mentors': 'Advisors / Mentors',
  'alexandra_greenhill': 'Alexandra T. Greenhill',

  'browser_title': 'Heal Mary - About',

  'cassandra_hui': 'Cassandra Hui',
  'chirag_kariya': 'Chirag Kariya',
  'hao_ling': 'Hao Ling',
  'md': 'M.D.',
  'meet_the_founder': 'Meet the Founder',
  'meet_the_team': 'Meet the team',
  'mba_cspo_icp': 'CSPO, CSM',
  'phd': 'Ph.D.',
  'timothy_yeung': 'Timothy Yeung',
  'title': 'Finding clinical trials doesn\'t have to be a difficult process. We are here to help.',
};
