import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Divider from 'components/Divider';
import PageHeader from 'components/PageHeader';
import SubscribeForm from 'components/SubscribeForm';

import headerImg from './assets/header.jpg';
import alexandraImg from './assets/alexandra.jpg';
import cassImg from './assets/cass.jpg';
import chiragImg from './assets/chirag.jpg';
import timothyImg from './assets/timothy.jpg';

// Styled Components
const StyledAbout = styled.main``;

const StyledAboutSectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 60px;
  padding-bottom: 60px;

  background-color: ${(props) => props.theme.rawColors.offWhite};

  @media ${(props) => props.theme.devices.mobile} {
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
  }
`;

const StyledAboutSection = styled.section`
  ${(props) => props.theme.paddedContent};
  max-width: 800px;
`;

const StyledSectionTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 20px;
`;

const StyledBoldParagraph = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

const StyledSectionTeamTitle = styled.div`
  margin-bottom: 40px;

  @media ${(props) => props.theme.devices.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 20px;
    text-align: center;
  }
`;

const StyledTeamSection = styled.section`
  display: flex;
  flex-direction: column;

  max-width: 900px;
  margin: 0 auto;
  padding: 80px 6.2%;

  &:nth-of-type(n + 2) {
    padding-top: 0;
  }

  @media ${(props) => props.theme.devices.mobile} {
    ${(props) => props.theme.paddedContent};
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
  }
`;

const StyledTeamMembers = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  max-width: 1220px;

  @media ${(props) => props.theme.devices.tablet} {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }
`;

const StyledTeamMember = styled.div`
  display: flex;
  align-items: center;

  &:nth-of-type(n + 2) {
    margin-top: 80px;
  }

  hr {
    margin-top: 10px;
  }

  @media ${(props) => props.theme.devices.mobile} {
    flex-direction: column;
  }
`;

const StyledTeamMemberImage = styled.img`
  flex-shrink: 0; /* IE11 Fix for stretching */
  width: 74%;
  margin-right: 50px;
  max-width: 200px;
  max-height: 200px;

  @media ${(props) => props.theme.devices.mobile} {
    margin-right: 0;
  }
`;

const StyledTeamMemberName = styled.h2`
  margin: 0;

  @media ${(props) => props.theme.devices.mobile} {
    padding-top: 20px;
    padding-bottom: 5px;
  }
`;

const StyledTeamMemberTitle = styled.h3`
  margin-bottom: 0;
  color: ${(props) => props.theme.colors.subheading};
`;

const StyledTeamMemberAbout = styled.div`
  margin-top: 20px;
  max-width: 100%; /* IE11 Fix for content overflow */

  p:last-of-type {
    margin-bottom: 0;
  }

  @media ${(props) => props.theme.devices.mobile} {
    /* margin-top: 10px; */
  }
`;

export type AboutProps = {
  theme: DefaultTheme;
};

// Component
const About = ({ theme }: AboutProps) => {
  const { t } = useTranslation(['about']);

  return (
    <StyledAbout>
      <Helmet>
        <title>{t('about:browser_title')}</title>
      </Helmet>
      <PageHeader backgroundImage={headerImg} color={theme.colors.lightText}>
        <h1>{t('about:title')}</h1>
      </PageHeader>

      <StyledAboutSectionContainer>
        <StyledAboutSection>
          <StyledSectionTitle>
            <h1>{t('about:about_us')}</h1>
            <Divider />
          </StyledSectionTitle>
          <Trans i18nKey="about:about_us_content">
            <p />
            <p />
            <p />
            <StyledBoldParagraph />
          </Trans>
        </StyledAboutSection>
      </StyledAboutSectionContainer>

      <StyledTeamSection>
        <StyledSectionTeamTitle>
          <h1>{t('about:meet_the_founder')}</h1>
          <Divider />
        </StyledSectionTeamTitle>

        <StyledTeamMembers>
          <StyledTeamMember>
            <StyledTeamMemberImage
              src={cassImg}
              alt={t('about:cassandra_hui')}
            />
            <div>
              <StyledTeamMemberName>
                {t('about:cassandra_hui')}
              </StyledTeamMemberName>
              <StyledTeamMemberAbout>
                {t('about:about_cassandra')}
              </StyledTeamMemberAbout>
            </div>
          </StyledTeamMember>
        </StyledTeamMembers>
      </StyledTeamSection>

      <StyledTeamSection>
        <StyledSectionTeamTitle>
          <h1>{t('about:advisors_mentors')}</h1>
          <Divider />
        </StyledSectionTeamTitle>

        <StyledTeamMembers>
          <StyledTeamMember>
            <StyledTeamMemberImage
              src={alexandraImg}
              alt={t('about:alexandra_greenhill')}
            />
            <div>
              <StyledTeamMemberName>
                {t('about:alexandra_greenhill')}
              </StyledTeamMemberName>
              <StyledTeamMemberTitle>{t('about:md')}</StyledTeamMemberTitle>
              <StyledTeamMemberAbout>
                <Trans i18nKey="about:about_alexandra">
                  <p />
                  <p />
                </Trans>
              </StyledTeamMemberAbout>
            </div>
          </StyledTeamMember>

          <StyledTeamMember>
            <StyledTeamMemberImage
              src={chiragImg}
              alt={t('about:chirag_kariya')}
            />
            <div>
              <StyledTeamMemberName>
                {t('about:chirag_kariya')}
              </StyledTeamMemberName>
              <StyledTeamMemberTitle>{t('about:phd')}</StyledTeamMemberTitle>
              <StyledTeamMemberAbout>
                {t('about:about_chirag')}
              </StyledTeamMemberAbout>
            </div>
          </StyledTeamMember>

          <StyledTeamMember>
            <StyledTeamMemberImage
              src={timothyImg}
              alt={t('about:timothy_yeung')}
            />
            <div>
              <StyledTeamMemberName>
                {t('about:timothy_yeung')}
              </StyledTeamMemberName>
              <StyledTeamMemberTitle>
                {t('about:mba_cspo_icp')}
              </StyledTeamMemberTitle>
              <StyledTeamMemberAbout>
                {t('about:about_timothy')}
              </StyledTeamMemberAbout>
            </div>
          </StyledTeamMember>
        </StyledTeamMembers>
      </StyledTeamSection>

      <SubscribeForm />
    </StyledAbout>
  );
};

export default About;
