/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */

import React from 'react';
import { Link } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components/macro';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ELIGIBILITY_RESULTS } from 'common/constants';

import Button from 'components/Button';
import CopyUrlButton from 'components/CopyUrlButton';
import Modal from 'components/Modal';
import TextButton from 'components/TextButton';

// Styled Components
const StyledResult = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  text-align: center;
`;

const StyledResultActions = styled.div`
  margin-top: 20px;
  text-align: center;

  a {
    display: inline-block;
  }

  button,
  a {
    width: 290px;
    box-sizing: border-box;

    &:nth-of-type(n + 2) {
      margin-top: 12px;
    }

    @media ${(props) => props.theme.devices.mobile} {
      width: 100%;
    }
  }
`;

const StyledCopyUrlButton = styled(CopyUrlButton)`
  padding-left: 20px;
`;

export type EligibilityResultModalProps = {
  isSponsored?: boolean;
  result: string;

  onClose: () => void;
  onShowApply: () => void;
  theme?: DefaultTheme;
};

// Component
const EligibilityResultModal = ({
  isSponsored = false,
  result,

  onClose,
  onShowApply,
}: EligibilityResultModalProps) => {
  const { t } = useTranslation(['trialEligibility']);

  return (
    <Modal
      depth={2}
      controls={<TextButton onClick={onClose}>{t('common:close')}</TextButton>}
      small
    >
      <StyledResult>
        {result === ELIGIBILITY_RESULTS.MAY_NOT_QUALIFY && (
          <>
            <Trans i18nKey="trialEligibility:may_not_qualify">
              <span />
              <Link to="/" />
              <span />
            </Trans>
            <StyledResultActions>
              <Button dark onClick={onShowApply}>
                {t('trialEligibility:get_in_touch')}
              </Button>
            </StyledResultActions>
          </>
        )}

        {result === ELIGIBILITY_RESULTS.MAY_QUALIFY &&
          (isSponsored ? (
            <>
              {t('trialEligibility:might_qualify_apply')}
              <StyledResultActions>
                <Button dark onClick={onShowApply}>
                  {t('trialEligibility:apply')}
                </Button>
              </StyledResultActions>
            </>
          ) : (
            <>
              {t('trialEligibility:might_qualify')}
              <StyledResultActions>
                <Button dark onClick={onShowApply}>
                  {t('trial:contact_clinical_trial')}
                </Button>
              </StyledResultActions>
            </>
          ))}

        {result === ELIGIBILITY_RESULTS.UNSURE && (
          <>
            {t('trialEligibility:looks_like_unsure')}
            <StyledResultActions>
              <Button dark onClick={onShowApply}>
                {t('trialEligibility:get_in_touch')}
              </Button>
              <StyledCopyUrlButton />
            </StyledResultActions>
          </>
        )}
      </StyledResult>
    </Modal>
  );
};

EligibilityResultModal.propTypes = {
  isSponsored: PropTypes.bool,
  result: PropTypes.oneOf(Object.values(ELIGIBILITY_RESULTS)).isRequired,

  onClose: PropTypes.func.isRequired,
  onShowApply: PropTypes.func.isRequired,
};

EligibilityResultModal.defaultProps = {
  isSponsored: false,
};

export default EligibilityResultModal;
