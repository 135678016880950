import styled, { css, DefaultTheme } from 'styled-components/macro';
import CheckboxOption from 'components/CheckboxOption';
import { ChangeEvent } from 'react';

// Styled Components
const StyledCheckboxGroup = styled.fieldset<{ vertical: boolean }>`
  display: flex;
  gap: 16px;
  margin: 0;
  padding: 0;
  border: none;
  ${(props) =>
    props.vertical &&
    css`
      flex-direction: column;
    `}
`;

const StyledCheckboxOption = styled(CheckboxOption)<{ vertical: boolean }>`
  padding-bottom: 10px;

  ${(props) =>
    props.vertical &&
    css`
      padding-bottom: 0;
    `}
`;

export type CheckboxOption = {
  label: React.ReactNode;
  value: string;
};

export type CheckboxGroupProps = {
  legend?: React.ReactNode;
  options: CheckboxOption[];
  values?: string[];
  theme?: DefaultTheme;
  vertical?: boolean;
  onChange: (values: string[]) => void;
} & Omit<React.ComponentPropsWithoutRef<'fieldset'>, 'onChange'>;

const CheckboxGroup = ({
  legend,
  options,
  values = [],
  vertical = false,
  onChange,
  ...rest
}: CheckboxGroupProps) => {
  const onOptionChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = evt.target;

    const updatedValues = [...values];
    if (checked && !updatedValues.includes(value)) {
      updatedValues.push(value);
    } else if (!checked && updatedValues.includes(value)) {
      updatedValues.splice(updatedValues.indexOf(value), 1);
    }

    onChange(updatedValues);
  };

  return (
    <StyledCheckboxGroup vertical={vertical} {...rest}>
      {legend}
      {options.map(({ label, value }) => (
        <StyledCheckboxOption
          key={value}
          checked={values.includes(value)}
          label={label}
          value={value}
          vertical={vertical}
          onChange={onOptionChange}
        />
      ))}
    </StyledCheckboxGroup>
  );
};

export default CheckboxGroup;
