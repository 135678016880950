import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledPageHeading = styled.h1`
  font-size: ${(props) => props.theme.fontSizes.h2};

  margin-bottom: 56px;

  @media ${(props) => props.theme.devices.tablet} {
    margin-bottom: 32px;
  }
`;

export type PageHeadingProps = {
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'h1'>;

const DashboardPageHeading = ({ children, ...rest }: PageHeadingProps) => (
  <StyledPageHeading {...rest}>{children}</StyledPageHeading>
);

export default DashboardPageHeading;
