import React, { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled, { DefaultTheme, css } from 'styled-components/macro';

import { getIntegratorImageUrl } from 'common/api/integrator';
import { BANNER_SETTINGS, PATHS } from 'common/constants';

import ButtonLink from 'components/ButtonLink';
import SearchSelectModal from 'components/SearchSelectModal';
import SocialIcons from 'components/SocialIcons';
import TextButton from 'components/TextButton';

import AuthContext from 'contexts/AuthContext';

import useIsScrolled from 'hooks/useIsScrolled';
import useOnClickOutside from 'hooks/useOnClickOutside';

import logoImg from 'assets/HealMary.svg';
import IntegratorContext from 'contexts/IntegratorContext';
import iconAlertImg from './assets/icon-alert.svg';
import iconAppliedImg from './assets/icon-applied.svg';
import iconChevronImg from './assets/icon-chevron.svg';
import iconDashboardImg from './assets/icon-dashboard.svg';
import iconLikedImg from './assets/icon-liked.svg';
import iconProfileImg from './assets/icon-profile.svg';
import iconSignOutImg from './assets/icon-signout.svg';
import closeImg from './assets/icon-x.svg';

// Styled Components
const StyledBanner = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.theme.paddedContent}
  height: 50px;

  background-color: ${(props) => props.theme.colors.darkBackground};
  text-align: center;

  z-index: 1000;

  @media ${(props) => props.theme.devices.tablet} {
    height: 80px;
    line-height: 18px;
  }

  @media ${(props) => props.theme.devices.mobile} {
    justify-content: flex-start;
    font-size: ${(props) => props.theme.fontSizes.small};
  }

  a {
    color: ${(props) => props.theme.colors.lightText};

    @media ${(props) => props.theme.devices.mobile} {
      margin-right: 20px;
    }

    span {
      &:nth-of-type(1) {
        text-decoration: underline;
      }
    }
  }
`;

const StyledBannerTextButton = styled(TextButton)`
  color: ${(props) => props.theme.colors.darkButtonText};
  font-size: ${(props) => props.theme.fontSizes.regular};
  text-decoration: none;

  @media ${(props) => props.theme.devices.mobile} {
    margin-right: 20px;
    font-size: ${(props) => props.theme.fontSizes.small};
  }

  span {
    &:nth-of-type(1) {
      text-decoration: underline;
    }
  }
`;

const StyledBannerCloseButton = styled.button`
  display: inline-block;
  position: absolute;
  right: 0;

  border: none;
  background-color: transparent;

  img {
    width: 30px;
    height: 30px;
  }
`;

const StyledHeaderBar = styled.header<{
  dark: boolean;
  isBannerShown: boolean;
  isIntegratorModeWithoutLogo: boolean;
  isScrolled: boolean;
}>`
  ${(props) => props.theme.paddedContent}

  display: flex;
  align-items: center;
  ${(props) =>
    props.isIntegratorModeWithoutLogo
      ? css`
          justify-content: flex-end;
          height: 70px;
        `
      : css`
          justify-content: space-between;
          height: 112px;
        `}

  padding-top: ${(props) => (props.isBannerShown ? '50px' : '0')};

  background-color: ${(props) =>
    props.dark ? props.theme.colors.headerBackgroundDark : 'transparent'};

  transition: padding-top 0.5s;

  @media ${(props) => props.theme.devices.tablet} {
    position: fixed;
    top: ${(props) => (props.isBannerShown ? '80px' : '0')};
    left: 0;
    right: 0;
    height: 70px;
    padding-top: 0;

    background-color: ${(props) =>
      props.dark
        ? props.theme.colors.headerBackgroundDark
        : props.theme.colors.headerBackground};

    ${(props) =>
      props.isScrolled &&
      css`
        box-shadow: 0 0 10px 0 ${props.theme.rawColors.black20};
      `}

    transition: top 0.5s, box-shadow 0.5s;

    z-index: 100;
  }
`;

const StyledHeaderPlaceholder = styled.div<{
  isBannerShown: boolean;
  isInIframe?: boolean;
}>`
  height: 0;

  @media ${(props) => props.theme.devices.tablet} {
    height: ${(props) => (props.isBannerShown ? '120px' : '70px')};
    ${(props) =>
      !props.isInIframe
        ? css`
            transition: height 0.5s;
          `
        : ''}
  }
`;

const StyledLogoLink = styled.a`
  border-bottom: none;
  z-index: 100;
`;

const StyledLogo = styled.img`
  display: block;

  @media ${(props) => props.theme.devices.tablet} {
    height: 31px;
  }
`;

const StyledIntegratorLogo = styled.img`
  max-height: 52px;
  max-width: 256px;
  object-fit: contain;
`;

const StyledSocialIcons = styled.li``;

const StyledNav = styled.nav<{
  isBannerShown: boolean;
  isMenuExpanded: boolean;
}>`
  & > ul {
    list-style: none;
    margin: 0;
    padding: 0;

    & > li {
      display: inline;
      margin-left: 40px;
    }

    & > ${StyledSocialIcons} {
      display: none;
    }
  }

  @media (max-width: 1040px) {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: ${(props) => (props.isMenuExpanded ? '0' : '100%')};
    max-height: 100vh;
    overflow-y: auto;

    background-color: ${(props) => props.theme.rawColors.black50};
    opacity: ${(props) => (props.isMenuExpanded ? 1 : 0)};

    transition: ${(props) =>
      props.isMenuExpanded ? 'opacity 0.5s' : 'opacity 0.5s, bottom 0.5s 0.5s'};
    z-index: 1;

    & > ul {
      width: 100%;
      padding-top: ${(props) => (props.isBannerShown ? '180px' : '90px')};
      padding-bottom: 30px;

      background-color: ${(props) => props.theme.colors.headerBackground};
      text-align: center;

      box-shadow: 0 2px 10px 2px ${(props) => props.theme.rawColors.black50};

      transform: ${(props) =>
        props.isMenuExpanded ? 'translateY(0)' : 'translateY(-100%)'};
      transition: transform 0.5s;

      & > li,
      & > ${StyledSocialIcons} {
        display: list-item;
        margin: 0;
        padding-top: 30px;
        padding-bottom: 30px;

        font-size: ${(props) => props.theme.fontSizes.mobile.h2};
      }
    }
  }
`;

const StyledExternalLink = styled.a`
  transition: color 0.4s;
  border-bottom: none;

  &:hover {
    color: ${(props) => props.theme.colors.hover};
  }
`;

const StyledLink = styled(Link)`
  transition: color 0.4s;
  border-bottom: none;

  &:hover {
    color: ${(props) => props.theme.colors.hover};
  }
`;

const StyledTextButton = styled(TextButton)`
  text-decoration: none;
  font-size: inherit;

  &:hover {
    color: ${(props) => props.theme.colors.hover};
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  display: inline;
  font-size: ${(props) => props.theme.fontSizes.regular};
`;

const StyledProfileMenu = styled.span<{ isExpanded: boolean }>`
  position: relative;
  padding: 16px 0;

  @media (max-width: 1040px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  button {
    padding: 0;
    border: none;
    background-color: transparent;
    color: ${(props) => props.theme.colors.link};
    font-weight: ${(props) => props.theme.fontWeights.bold};

    transition: color 0.4s;

    @media (max-width: 1040px) {
      font-size: ${(props) => props.theme.fontSizes.mobile.h2};
    }

    &:hover {
      color: ${(props) => props.theme.colors.hover};
    }

    > img {
      margin-left: 10px;
      margin-bottom: 2px;

      transition: transform 0.25s;

      ${(props) =>
        props.isExpanded &&
        css`
          transform: rotate(180deg);
        `}
    }
  }

  ul {
    position: absolute;
    top: 100%;
    right: 0;
    max-height: 0;
    margin: 0;
    padding: 0;
    list-style: none;

    background-color: ${(props) => props.theme.colors.background};
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);

    font-size: ${(props) => props.theme.fontSizes.small};
    white-space: nowrap;

    opacity: 0;
    overflow: hidden;

    z-index: 1000;

    transition: opacity 0.25s;

    ${(props) =>
      props.isExpanded &&
      css`
        padding: 12px 0;
        opacity: 1;
        max-height: 300px;
      `}

    @media (max-width: 1040px) {
      position: relative;
      margin-top: 8px;
      text-align: center;
      box-shadow: none;

      transition: max-height 0.5s, opacity 0s 0.5s, padding 0.25s;

      ${(props) =>
        props.isExpanded &&
        css`
          transition: max-height 0.5s;
        `}
    }

    li {
      > a {
        display: flex;
        gap: 16px;
        padding: 12px 24px;

        @media (max-width: 1040px) {
          padding: 16px 24px;
        }
      }

      &:last-of-type {
        border-top: 1px solid #f2f2f2;

        > a {
          padding-top: 16px;
        }
      }
    }
  }
`;

const StyledMenuButton = styled.button<{ isMenuExpanded: boolean }>`
  display: none;

  padding: 10px 0;

  border: none;
  background-color: transparent;

  z-index: 100;

  & > span {
    display: block;
    width: 30px;
    height: 4px;
    margin: 6px;

    border-radius: 3px;
    background-color: ${(props) => props.theme.rawColors.blue};

    transition: transform 0.5s;
  }

  & > span:nth-child(1) {
    transform: ${(props) =>
      props.isMenuExpanded ? 'rotate(45deg) translate(4px, 3px)' : null};
  }

  & > span:nth-child(2) {
    transform: ${(props) =>
      props.isMenuExpanded ? 'rotate(-45deg) translate(4px, -3px)' : null};
  }

  @media (max-width: 1040px) {
    display: block;
  }
`;

export type HeaderBarProps = {
  isIntegratorMode?: boolean;
  theme?: DefaultTheme;
};

// Component
const HeaderBar = ({ isIntegratorMode = false }: HeaderBarProps) => {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const navRef = useRef<HTMLUListElement | null>(null);
  const [isBannerShown, setIsBannerShown] = useState(
    BANNER_SETTINGS.ENABLED && !isIntegratorMode,
  );
  const [isSearchModalShown, setIsSearchModalShown] = useState(false);
  const [isProfileMenuExpanded, setIsProfileMenuExpanded] =
    useState<boolean>(false);
  const menuRef = useOnClickOutside<HTMLUListElement>(() =>
    setIsProfileMenuExpanded(false),
  );
  const authState = useContext(AuthContext);
  const [bannerSettings, setBannerSettings] = useState({
    link: BANNER_SETTINGS.LINK,
    isInternal: BANNER_SETTINGS.IS_INTERNAL,
    messageKey: BANNER_SETTINGS.MESSAGE_KEY,
  });

  const { t } = useTranslation();
  const location = useLocation();
  const isScrolled = useIsScrolled();
  const history = useHistory();

  const { isEmbedded, integratorClient } = useContext(IntegratorContext);

  // Collapse the menu when navigating to a new location
  useEffect(() => {
    setIsMenuExpanded(false);
    setIsProfileMenuExpanded(false);
  }, [location]);

  useEffect(() => {
    if (authState.isAuthChecked && authState.isAuthenticated) {
      if (authState.profile) {
        setIsBannerShown(false);
      } else {
        setBannerSettings({
          isInternal: true,
          link: PATHS.DASHBOARD,
          messageKey: 'common:complete_profile_banner',
        });
      }
    }
  }, [authState.isAuthChecked, authState.isAuthenticated, authState.profile]);

  // Collapse the menu when clicking outside of the menu
  const onNavClicked = (evt: React.MouseEvent<HTMLDivElement>) => {
    if (navRef.current && !navRef.current.contains(evt.target as Node)) {
      setIsMenuExpanded(false);
    }
  };

  return (
    <>
      {isBannerShown && (
        <StyledBanner>
          {bannerSettings.link !== null ? (
            bannerSettings.isInternal ? (
              <StyledBannerTextButton
                type="button"
                onClick={() => {
                  setIsBannerShown(false);
                  history.push(bannerSettings.link);
                }}
              >
                <Trans i18nKey={bannerSettings.messageKey}>
                  <span />
                </Trans>
              </StyledBannerTextButton>
            ) : (
              <a
                href={bannerSettings.link}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                <Trans i18nKey={bannerSettings.messageKey}>
                  <span />
                </Trans>
              </a>
            )
          ) : (
            t(bannerSettings.messageKey)
          )}
          <StyledBannerCloseButton
            type="button"
            onClick={() => setIsBannerShown(false)}
          >
            <img src={closeImg} alt={t('common:dismiss_banner')} />
          </StyledBannerCloseButton>
        </StyledBanner>
      )}
      <StyledHeaderBar
        isBannerShown={isBannerShown}
        isIntegratorModeWithoutLogo={isIntegratorMode && isEmbedded}
        isScrolled={isScrolled}
        dark={location.pathname.indexOf('/dashboard') === 0}
      >
        {!isIntegratorMode && (
          <StyledLogoLink href="https://healmary.com">
            <StyledLogo src={logoImg} alt={t('common:heal_mary')} />
          </StyledLogoLink>
        )}
        {isIntegratorMode && !isEmbedded && integratorClient && (
          <StyledLogoLink
            href={
              integratorClient.hostname.startsWith('http')
                ? integratorClient.hostname
                : `https://${integratorClient.hostname}`
            }
          >
            <StyledIntegratorLogo alt="" src={getIntegratorImageUrl()} />
          </StyledLogoLink>
        )}
        {isIntegratorMode && !isEmbedded && !integratorClient && (
          <StyledIntegratorLogo alt="" src={getIntegratorImageUrl()} />
        )}
        <StyledNav
          isBannerShown={isBannerShown}
          isMenuExpanded={isMenuExpanded}
          onClick={onNavClicked}
        >
          <ul ref={navRef}>
            {!isIntegratorMode && (
              <>
                <li>
                  <StyledExternalLink href="https://healmary.com/about-us">
                    {t('common:about_us')}
                  </StyledExternalLink>
                </li>
                <li>
                  <StyledExternalLink href="https://healmary.com/contact">
                    {t('common:contact_us')}
                  </StyledExternalLink>
                </li>
              </>
            )}
            <li>
              {isIntegratorMode ? (
                <StyledLink to={PATHS.SEARCH}>
                  {t('common:search_trials_title_case')}
                </StyledLink>
              ) : (
                <StyledTextButton onClick={() => setIsSearchModalShown(true)}>
                  {t('common:search_trials_title_case')}
                </StyledTextButton>
              )}
            </li>
            {authState.isAuthChecked &&
              authState.isAuthenticated &&
              !authState.profile && (
                <li>
                  <StyledProfileMenu isExpanded={isProfileMenuExpanded}>
                    <button
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                      onClick={(evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        setIsProfileMenuExpanded(
                          (currentIsMenuShown) => !currentIsMenuShown,
                        );
                      }}
                    >
                      {t('common:my_profile')}
                      <img
                        src={iconAlertImg}
                        alt=""
                        style={{ transform: 'none' }}
                      />
                    </button>
                    <ul ref={menuRef}>
                      <li>
                        <StyledLink to={PATHS.DASHBOARD_PROFILE}>
                          <img src={iconAlertImg} alt="" />
                          <span>{t('dashboard:my_profile')}</span>
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink to={PATHS.SIGN_OUT}>
                          <img src={iconSignOutImg} alt="" />
                          <span>{t('common:sign_out')}</span>
                        </StyledLink>
                      </li>
                    </ul>
                  </StyledProfileMenu>
                </li>
              )}
            {authState.isAuthChecked &&
              authState.isAuthenticated &&
              authState.profile && (
                <li>
                  <StyledProfileMenu isExpanded={isProfileMenuExpanded}>
                    <button
                      onClick={(evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        setIsProfileMenuExpanded(
                          (currentIsMenuShown) => !currentIsMenuShown,
                        );
                      }}
                    >
                      {t('common:my_profile')}
                      <img src={iconChevronImg} alt="" />
                    </button>
                    <ul ref={menuRef}>
                      <li>
                        <StyledLink to={PATHS.DASHBOARD}>
                          <img src={iconDashboardImg} alt="" />
                          <span>{t('dashboard:dashboard')}</span>
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink to={PATHS.DASHBOARD_APPLIED_TRIALS}>
                          <img src={iconAppliedImg} alt="" />
                          <span>{t('dashboard:applied_trials')}</span>
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink to={PATHS.DASHBOARD_LIKED_TRIALS}>
                          <img src={iconLikedImg} alt="" />
                          <span>{t('dashboard:liked_trials')}</span>
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink to={PATHS.DASHBOARD_PROFILE}>
                          <img src={iconProfileImg} alt="" />
                          <span>{t('dashboard:my_profile')}</span>
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink to={PATHS.SIGN_OUT}>
                          <img src={iconSignOutImg} alt="" />
                          <span>{t('common:sign_out')}</span>
                        </StyledLink>
                      </li>
                    </ul>
                  </StyledProfileMenu>
                </li>
              )}
            {(!authState.isAuthChecked || !authState.isAuthenticated) && (
              <>
                <li>
                  <StyledLink to={PATHS.SIGN_IN}>
                    {t('signIn:sign_in')}
                  </StyledLink>
                </li>
                <li>
                  <StyledButtonLink to={PATHS.SIGN_UP}>
                    {t('common:sign_up_cta')}
                  </StyledButtonLink>
                </li>
              </>
            )}

            {!isIntegratorMode && (
              <StyledSocialIcons>
                <SocialIcons dark />
              </StyledSocialIcons>
            )}
          </ul>
        </StyledNav>
        <StyledMenuButton
          isMenuExpanded={isMenuExpanded}
          onClick={() => setIsMenuExpanded(!isMenuExpanded)}
        >
          <span />
          <span />
        </StyledMenuButton>
      </StyledHeaderBar>
      <StyledHeaderPlaceholder
        isBannerShown={isBannerShown}
        isInIframe={isEmbedded}
      />
      {isSearchModalShown && (
        <SearchSelectModal onClose={() => setIsSearchModalShown(false)} />
      )}
    </>
  );
};

export default HeaderBar;
