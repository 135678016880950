import Auth from '@aws-amplify/auth';

/**
 * Make an authenticated fetch
 * @param {string} url The URL to fetch
 * @param {RequestInit | undefined} init The request init options
 * @returns {Promise<Response>} The response
 */
export default async function authenticatedFetch(
  url: string,
  init?: RequestInit | undefined,
) {
  let jwt: string;
  try {
    const session = await Auth.currentSession();
    jwt = session.getAccessToken().getJwtToken();
  } catch (ex) {
    throw new Error('Failed to get JWT');
  }

  const initWithAuth = init ?? {};
  if (init?.headers) {
    (init.headers as Headers).set('Authorization', `Bearer ${jwt}`);
  } else {
    const headers: HeadersInit = new Headers();
    headers.set('Authorization', `Bearer ${jwt}`);
    initWithAuth.headers = headers;
  }
  return fetch(url, initWithAuth);
}
