import * as Sentry from '@sentry/react';
import { postProfile } from 'common/api/profile';
import COUNTRIES, { COUNTRIES_BY_ALPHA_2 } from 'common/countries';
import Profile, {
  EthnicIdentityEnum,
  GenderEnum,
  GenderIdentityEnum,
  LanguagesEnum,
  MethodOfContactEnum,
  UserTypeEnum,
} from 'common/types/Profile';
import Button from 'components/Button';
import LabeledCheckboxGroup from 'components/LabeledCheckboxGroup';
import LabeledInput from 'components/LabeledInput';
import LabeledMultiSelect from 'components/LabeledMultiSelect';
import LabeledPhoneInput from 'components/LabeledPhoneInput';
import LabeledSelect from 'components/LabeledSelect';
import SuccessMessage from 'components/SuccessMessage';
import useIsMounted from 'hooks/useIsMounted';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';
import SectionHeading from '../SectionHeading';

// Styled Components
const StyledEditProfileForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 80px;

  @media ${(props) => props.theme.devices.mobile} {
    gap: 40px;
  }
`;

const OptionalSpan = styled.span`
  color: ${(props) => props.theme.colors.accentText};
`;

const StyledSectionContent = styled.div<{
  columns: number;
  marginPercent: number;
}>`
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  white-space: nowrap;

  @media ${(props) => props.theme.devices.tablet} {
    flex-direction: column;
  }

  > label,
  > fieldset {
    box-sizing: border-box;
    width: 100%;
    max-width: ${(props) =>
      (100 - (props.columns - 1) * props.marginPercent) / props.columns}%;
    margin-left: ${(props) => props.marginPercent / 2}%;
    margin-right: ${(props) => props.marginPercent / 2}%;

    &:nth-child(${(props) => props.columns}n + 1) {
      margin-left: 0;
    }

    &:nth-child(${(props) => props.columns}n) {
      margin-right: 0;
    }

    &:nth-child(n + ${(props) => props.columns + 1}) {
      margin-top: ${(props) => props.marginPercent}%;
    }

    @media ${(props) => props.theme.devices.tablet} {
      flex: 1;
      max-width: initial;
      margin-left: 0;
      margin-right: 0;

      &:nth-child(n + 2) {
        margin-top: 3%;
      }
    }
  }
`;

const StyledButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${(props) => props.theme.devices.mobile} {
    button {
      width: 100%;
    }
  }
`;

const StyledError = styled.div`
  color: ${(props) => props.theme.colors.error};
  text-align: center;
  margin-bottom: 32px;
`;

type FormState = {
  firstName: string;
  lastName: string;
  genderBiological?: GenderEnum | '';
  genderIdentity?: GenderIdentityEnum;
  ethnicIdentity?: string[];
  otherEthnicIdentity: string;
  userType: UserTypeEnum | '';
  age?: number;
  phone: string;
  email: string;
  preferredContactMethod: MethodOfContactEnum[];
  preferredContactTime: string;
  preferredLanguages: string[];
  otherPreferredLanguage: string;
  addressStreet?: string;
  addressSecondary?: string;
  addressCountry?: string;
  addressState?: string;
  addressCity?: string;
  addressPostalCode?: string;
};

const REQUIRED_FIELDS: (keyof FormState)[] = [
  'firstName',
  'lastName',
  'genderBiological',
  'userType',
  'age',
  'phone',
  'email',
  'preferredContactMethod',
];

export type EditProfileFormProps = {
  authEmail?: string;
  profile?: Profile;
  columns?: number;
  onProfileSaved?: (profile: Profile) => void;
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'form'>;

const EditProfileForm = ({
  authEmail,
  columns = 3,
  profile,
  onProfileSaved,
  ...rest
}: EditProfileFormProps) => {
  const { t } = useTranslation();

  // Extract the current 'other' ethnic identity if there is one
  const existingOtherEthnicIdentityValues = profile?.ethnic_identity?.filter(
    (value) =>
      !Object.values(EthnicIdentityEnum).includes(value as EthnicIdentityEnum),
  );

  const existingOtherEthnicIdentity =
    existingOtherEthnicIdentityValues &&
    existingOtherEthnicIdentityValues.length > 0
      ? existingOtherEthnicIdentityValues[0]
      : '';

  // Extract the current 'other' language if there is one
  const existingOtherPreferredLanguageValues =
    profile?.preferred_languages?.filter(
      (value) => !Object.values(LanguagesEnum).includes(value as LanguagesEnum),
    );

  const existingOtherPreferredLanguage =
    existingOtherPreferredLanguageValues &&
    existingOtherPreferredLanguageValues.length > 0
      ? existingOtherPreferredLanguageValues[0]
      : '';

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorKey, setErrorKey] = useState<string | null>(null);
  const [profileSaved, setProfileSaved] = useState<boolean>(false);
  const [errorFields, setErrorFields] = useState<(keyof FormState)[]>([]);
  const [formState, setFormState] = useState<FormState>({
    firstName: profile?.first_name ?? '',
    lastName: profile?.last_name ?? '',
    genderBiological: profile?.gender_biological ?? '',
    genderIdentity:
      profile?.gender_identity ?? GenderIdentityEnum.preferNotToSay,
    ethnicIdentity:
      profile?.ethnic_identity?.filter(
        (ethnicIdentity) => ethnicIdentity !== existingOtherEthnicIdentity,
      ) ?? [],
    otherEthnicIdentity: existingOtherEthnicIdentity,
    userType: profile?.user_type ?? '',
    age: profile?.age,
    phone: profile?.phone ?? '',
    email: profile?.email ?? '',
    preferredContactMethod: profile?.preferred_contact_method ?? [
      MethodOfContactEnum.phone,
      MethodOfContactEnum.email,
    ],
    preferredContactTime: profile?.preferred_contact_time ?? '',
    preferredLanguages:
      profile?.preferred_languages.filter(
        (language) => language !== existingOtherPreferredLanguage,
      ) ?? [],
    otherPreferredLanguage: existingOtherPreferredLanguage,
    addressStreet: profile?.address_street ?? '',
    addressSecondary: profile?.address_secondary ?? '',
    addressCountry: profile?.address_country_alpha_2
      ? COUNTRIES_BY_ALPHA_2[profile.address_country_alpha_2]
      : '',
    addressState: profile?.address_state ?? '',
    addressCity: profile?.address_city ?? '',
    addressPostalCode: profile?.address_postal_code ?? '',
  });
  const inputRefs = useRef<Record<keyof FormState, HTMLElement | null>>({
    firstName: null,
    lastName: null,
    genderBiological: null,
    genderIdentity: null,
    ethnicIdentity: null,
    otherEthnicIdentity: null,
    userType: null,
    age: null,
    phone: null,
    email: null,
    preferredContactMethod: null,
    preferredContactTime: null,
    preferredLanguages: null,
    otherPreferredLanguage: null,
    addressStreet: null,
    addressSecondary: null,
    addressCountry: null,
    addressState: null,
    addressCity: null,
    addressPostalCode: null,
  });
  const isMounted = useIsMounted();

  useEffect(() => {
    if (authEmail) {
      setFormState((currentFormState) => ({
        ...currentFormState,
        email: authEmail,
      }));
    }
  }, [authEmail]);

  const onSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    return false;
  };

  const onSaveProfile = async () => {
    setErrorKey(null);

    let hasError = false;
    const updatedErrorFields: (keyof FormState)[] = [];
    REQUIRED_FIELDS.forEach((item) => {
      if (
        !formState[item] ||
        ((typeof formState[item] === 'string' ||
          Array.isArray(formState[item])) &&
          (formState[item] as string | MethodOfContactEnum[])?.length === 0)
      ) {
        updatedErrorFields.push(item);
        hasError = true;
      }
    });

    // Check if preferred contact time is set when phone is selected
    if (
      formState.preferredContactMethod.includes(MethodOfContactEnum.phone) &&
      (!formState.preferredContactTime ||
        formState.preferredContactTime.length === 0)
    ) {
      updatedErrorFields.push('preferredContactTime');
      hasError = true;
    }

    setErrorFields(updatedErrorFields);
    if (hasError) {
      inputRefs.current[updatedErrorFields[0]]?.scrollIntoView({
        behavior: 'smooth',
      });
      return;
    }

    try {
      setIsSubmitting(true);
      // For ethnic identity and preferred languages, need to merge in other value
      const ethnicIdentity = formState.ethnicIdentity ?? [];
      if (formState.otherEthnicIdentity) {
        ethnicIdentity.push(formState.otherEthnicIdentity);
      }
      const preferredLanguages = formState.preferredLanguages ?? [];
      if (formState.otherPreferredLanguage) {
        preferredLanguages.push(formState.otherPreferredLanguage);
      }

      const result = await postProfile({
        first_name: formState.firstName,
        last_name: formState.lastName,
        gender_biological: formState.genderBiological! as GenderEnum,
        gender_identity: formState.genderIdentity,
        ethnic_identity: ethnicIdentity,
        user_type: formState.userType! as UserTypeEnum,
        age: formState.age!,
        medical_details: !profile ? {} : undefined,
        phone: formState.phone,
        email: formState.email,
        preferred_contact_method: formState.preferredContactMethod,
        preferred_contact_time: formState.preferredContactTime,
        preferred_languages: preferredLanguages,
        notification_types: !profile ? [] : undefined,
        address_street: formState.addressStreet,
        address_secondary: formState.addressSecondary,
        address_country_alpha_2: formState.addressCountry
          ? COUNTRIES[formState.addressCountry].alpha_2
          : undefined,
        address_state: formState.addressState,
        address_city: formState.addressCity,
        address_postal_code: formState.addressPostalCode,
      });

      if (!isMounted.current) {
        return;
      }
      setIsSubmitting(false);

      if (!result) {
        setErrorKey('profile:unknown_error');
        return;
      }

      if (onProfileSaved !== undefined) {
        onProfileSaved(result);
      }
      setProfileSaved(true);
      setTimeout(() => {
        if (isMounted.current) {
          setProfileSaved(false);
        }
      }, 5000);
    } catch (ex: any) {
      if (!isMounted.current) {
        return;
      }

      setErrorKey('profile:unknown_error');
      setIsSubmitting(false);
      Sentry.captureException(ex);
    }
  };

  const ageValues: number[] = [];
  for (let i = 1; i <= 130; i++) {
    ageValues.push(i);
  }

  // Calculate margins upfront
  const marginPercent = 6.6 / columns;

  return (
    <StyledEditProfileForm onSubmit={onSubmit} {...rest}>
      <section>
        <SectionHeading>{t('profile:personal_information')}</SectionHeading>

        <StyledSectionContent columns={columns} marginPercent={marginPercent}>
          <LabeledInput
            disabled={isSubmitting}
            hasError={errorFields.includes('firstName')}
            ref={(ref) => {
              inputRefs.current.firstName = ref;
            }}
            label={t('profile:first_name')}
            type="text"
            value={formState.firstName}
            onChange={(evt) =>
              setFormState({ ...formState, firstName: evt.target.value })
            }
          />
          <LabeledInput
            disabled={isSubmitting}
            hasError={errorFields.includes('lastName')}
            ref={(ref) => {
              inputRefs.current.lastName = ref;
            }}
            label={t('profile:last_name')}
            type="text"
            value={formState.lastName}
            onChange={(evt) =>
              setFormState({ ...formState, lastName: evt.target.value })
            }
          />
          <LabeledSelect
            disabled={isSubmitting}
            hasError={errorFields.includes('genderBiological')}
            ref={(ref) => {
              inputRefs.current.genderBiological = ref;
            }}
            label={t('profile:gender_biological')}
            value={formState.genderBiological}
            tooltip={t('profile:why_biological_gender')}
            onChange={(evt) =>
              setFormState({
                ...formState,
                genderBiological: evt.target.value as GenderEnum,
              })
            }
          >
            <option value="" />
            {Object.values(GenderEnum).map((gender) => (
              <option key={gender} value={gender}>
                {t(`profile:${gender}`)}
              </option>
            ))}
          </LabeledSelect>

          <LabeledSelect
            disabled={isSubmitting}
            hasError={errorFields.includes('genderIdentity')}
            ref={(ref) => {
              inputRefs.current.genderIdentity = ref;
            }}
            label={
              <Trans i18nKey="profile:gender_identity">
                <span />
                <OptionalSpan />
              </Trans>
            }
            tooltip={t('profile:why_gender_identity')}
            value={formState.genderIdentity}
            onChange={(evt) =>
              setFormState({
                ...formState,
                genderIdentity: evt.target.value as GenderIdentityEnum,
              })
            }
          >
            {Object.values(GenderIdentityEnum).map((gender) => (
              <option key={gender} value={gender}>
                {t(`profile:${gender}`)}
              </option>
            ))}
          </LabeledSelect>
          <LabeledMultiSelect
            defaultValue={t('profile:prefer_not_to_say')}
            disabled={isSubmitting}
            hasError={errorFields.includes('ethnicIdentity')}
            ref={(ref) => {
              inputRefs.current.ethnicIdentity = ref;
            }}
            label={
              <Trans i18nKey="profile:ethnic_identity">
                <span />
                <OptionalSpan />
              </Trans>
            }
            options={Object.values(EthnicIdentityEnum).map((ethnicity) => ({
              label: t(`profile:ethnicity_${ethnicity}`),
              value: ethnicity,
            }))}
            otherValue={formState.otherEthnicIdentity}
            tooltip={t('profile:why_ethnic_identity')}
            values={formState.ethnicIdentity ?? []}
            onOtherChange={(value: string) => {
              setFormState({
                ...formState,
                otherEthnicIdentity: value,
              });
            }}
            onValuesChange={(value: string[]) =>
              setFormState({
                ...formState,
                ethnicIdentity: value,
              })
            }
          />
          <LabeledSelect
            disabled={isSubmitting}
            hasError={errorFields.includes('userType')}
            ref={(ref) => {
              inputRefs.current.userType = ref;
            }}
            label={t('profile:user_type')}
            value={formState.userType}
            onChange={(evt) =>
              setFormState({
                ...formState,
                userType: evt.target.value as UserTypeEnum,
              })
            }
          >
            <option value="" />
            {Object.values(UserTypeEnum).map((userType) => (
              <option key={userType} value={userType}>
                {t(`profile:${userType}`)}
              </option>
            ))}
          </LabeledSelect>

          <LabeledSelect
            disabled={isSubmitting}
            hasError={errorFields.includes('age')}
            ref={(ref) => {
              inputRefs.current.age = ref;
            }}
            label={t('profile:age')}
            value={formState.age}
            onChange={(evt) =>
              setFormState({
                ...formState,
                age: parseInt(evt.target.value),
              })
            }
          >
            <option value="" />
            {ageValues.map((age) => (
              <option key={age} value={age}>
                {age}
              </option>
            ))}
          </LabeledSelect>

          <LabeledMultiSelect
            defaultValue={t('profile:prefer_not_to_say')}
            disabled={isSubmitting}
            ref={(ref) => {
              inputRefs.current.preferredLanguages = ref;
            }}
            label={
              <Trans i18nKey="profile:preferred_languages">
                <span />
                <OptionalSpan />
              </Trans>
            }
            options={Object.values(LanguagesEnum).map((language) => ({
              label: t(`profile:language_${language}`),
              value: language,
            }))}
            otherValue={formState.otherPreferredLanguage}
            tooltip={t('profile:why_preferred_language')}
            values={formState.preferredLanguages}
            onOtherChange={(value: string) => {
              setFormState({
                ...formState,
                otherPreferredLanguage: value,
              });
            }}
            onValuesChange={(values: string[]) =>
              setFormState({
                ...formState,
                preferredLanguages: values,
              })
            }
          />
        </StyledSectionContent>
      </section>

      <section>
        <SectionHeading>{t('profile:contact_information')}</SectionHeading>
        <StyledSectionContent columns={columns} marginPercent={marginPercent}>
          <LabeledPhoneInput
            disabled={isSubmitting}
            hasError={errorFields.includes('phone')}
            ref={(ref) => {
              inputRefs.current.phone = ref;
            }}
            label={t('profile:phone')}
            type="text"
            value={formState.phone || undefined}
            onChange={(value) =>
              setFormState({ ...formState, phone: (value as string) ?? '' })
            }
          />
          <LabeledInput
            disabled={isSubmitting}
            hasError={errorFields.includes('email')}
            ref={(ref) => {
              inputRefs.current.email = ref;
            }}
            label={t('profile:email')}
            type="text"
            tooltip={t('profile:why_email')}
            value={formState.email}
            onChange={(evt) =>
              setFormState({ ...formState, email: evt.target.value })
            }
          />
          <LabeledCheckboxGroup
            disabled={isSubmitting}
            hasError={errorFields.includes('preferredContactMethod')}
            ref={(ref) => {
              inputRefs.current.preferredContactMethod = ref;
            }}
            label={t('profile:preferred_method_of_contact')}
            options={Object.values(MethodOfContactEnum).map(
              (contactMethod) => ({
                label: t(`profile:method_of_contact_${contactMethod}`),
                value: contactMethod,
              }),
            )}
            values={formState.preferredContactMethod}
            onChange={(values) =>
              setFormState({
                ...formState,
                preferredContactMethod: values as MethodOfContactEnum[],
              })
            }
          />

          {formState.preferredContactMethod.includes(
            MethodOfContactEnum.phone,
          ) && (
            <LabeledInput
              disabled={isSubmitting}
              hasError={errorFields.includes('preferredContactTime')}
              ref={(ref) => {
                inputRefs.current.preferredContactTime = ref;
              }}
              label={t('profile:preferred_contact_time')}
              type="text"
              value={formState.preferredContactTime}
              onChange={(evt) =>
                setFormState({
                  ...formState,
                  preferredContactTime: evt.target.value,
                })
              }
            />
          )}
        </StyledSectionContent>
      </section>

      <section>
        <SectionHeading>
          <Trans i18nKey="profile:address_optional">
            <span />
            <OptionalSpan />
          </Trans>
        </SectionHeading>
        <StyledSectionContent columns={columns} marginPercent={marginPercent}>
          <LabeledInput
            disabled={isSubmitting}
            hasError={errorFields.includes('addressStreet')}
            ref={(ref) => {
              inputRefs.current.addressStreet = ref;
            }}
            label={t('profile:street_address')}
            type="text"
            value={formState.addressStreet}
            onChange={(evt) =>
              setFormState({
                ...formState,
                addressStreet: evt.target.value,
              })
            }
          />
          <LabeledInput
            disabled={isSubmitting}
            hasError={errorFields.includes('addressSecondary')}
            ref={(ref) => {
              inputRefs.current.addressSecondary = ref;
            }}
            label={t('profile:unit_suite_number')}
            type="text"
            value={formState.addressSecondary}
            onChange={(evt) =>
              setFormState({
                ...formState,
                addressSecondary: evt.target.value,
              })
            }
          />
          <LabeledSelect
            disabled={isSubmitting}
            hasError={errorFields.includes('addressCountry')}
            ref={(ref) => {
              inputRefs.current.addressCountry = ref;
            }}
            label={t('profile:country')}
            value={formState.addressCountry}
            onChange={(evt) => {
              setFormState({
                ...formState,
                addressCountry: evt.target.value,
                addressState: undefined, // Clear state on country change
              });
            }}
          >
            <option value=""></option>
            {Object.values(COUNTRIES).map(({ i18nKey, value }) => (
              <option key={value} value={value}>
                {t(i18nKey)}
              </option>
            ))}
          </LabeledSelect>

          {formState.addressCountry &&
            COUNTRIES[formState.addressCountry]?.states && (
              <LabeledSelect
                disabled={isSubmitting}
                hasError={errorFields.includes('addressState')}
                ref={(ref) => {
                  inputRefs.current.addressState = ref;
                }}
                label={t('profile:state')}
                value={formState.addressState}
                onChange={(evt) =>
                  setFormState({
                    ...formState,
                    addressState: evt.target.value,
                  })
                }
              >
                <option value=""></option>
                {Object.values(COUNTRIES[formState.addressCountry].states!).map(
                  ({ value, i18nKey }) => (
                    <option key={value} value={value}>
                      {t(i18nKey)}
                    </option>
                  ),
                )}
              </LabeledSelect>
            )}
          <LabeledInput
            disabled={isSubmitting}
            hasError={errorFields.includes('addressCity')}
            ref={(ref) => {
              inputRefs.current.addressCity = ref;
            }}
            label={t('profile:city')}
            type="text"
            value={formState.addressCity}
            onChange={(evt) =>
              setFormState({
                ...formState,
                addressCity: evt.target.value,
              })
            }
          />
          <LabeledInput
            disabled={isSubmitting}
            hasError={errorFields.includes('addressPostalCode')}
            ref={(ref) => {
              inputRefs.current.addressPostalCode = ref;
            }}
            label={t('profile:postal_code')}
            type="text"
            maxLength={16}
            value={formState.addressPostalCode}
            onChange={(evt) =>
              setFormState({
                ...formState,
                addressPostalCode: evt.target.value,
              })
            }
          />
        </StyledSectionContent>
      </section>
      <StyledButtonRow>
        {errorKey && <StyledError>{t(errorKey)}</StyledError>}
        <SuccessMessage
          message={t('profile:saved_success')}
          shown={profileSaved}
        />
        <Button type="button" dark onClick={onSaveProfile}>
          {t('profile:save_profile')}
        </Button>
      </StyledButtonRow>
    </StyledEditProfileForm>
  );
};

export default EditProfileForm;
