import { ACTION_SIGN_OUT } from 'modules/SignIn/const';

/* eslint-disable quote-props */
export const SOCIAL_LINKS = {
  FACEBOOK: 'https://www.facebook.com/HealMaryApp/',
  TWITTER: 'https://twitter.com/healmaryapp',
  INSTAGRAM: 'https://www.instagram.com/healmaryapp/',
  LINKED_IN: 'https://www.linkedin.com/company/heal-mary/about/',
  MEDIUM: 'https://medium.com/@healmaryapp',
};

export const SURVEY_LINK = 'https://forms.gle/UkGXrEYqZDGSU75v9';

export const CONTACT_ADDRESS =
  '610 Main Street, 4th floor. Vancouver, BC. V6A 2V3';
export const CONTACT_EMAIL = 'hello@healmaryapp.com';
export const CONTACT_PHONE = '1-888-734-HEAL / 1-888-734-4325';

export const MAPS_API_KEY = 'AIzaSyBGFp0l-LyB0bX9BsxYRSGQSR0KkXQEj1E';

export const MAILCHIMP_LINK = 'http://eepurl.com/gFFelL';

export const FACEBOOK_PRIVACY_LINK =
  'https://www.facebook.com/privacy/explanation';
export const GOOGLE_MAPS_PRIVACY_LINK = 'https://policies.google.com/privacy';

export const IS_INTEGRATOR_MODE = process.env.REACT_APP_MODE === 'integrator';

export const ELIGIBILITY_VALUES = {
  YES: 'yes',
  NO: 'no',
  UNSURE: 'unsure',
};

export const ELIGIBILITY_RESULTS = {
  MAY_NOT_QUALIFY: 'MAY_NOT_QUALIFY',
  MAY_QUALIFY: 'MAY_QUALIFY',
  UNSURE: 'UNSURE',
};

export const CONTACT_METHODS = {
  PHONE: 'PHONE',
  TEXT: 'TEXT',
  EMAIL: 'EMAIL',
};

// Countries that may have different names in the trial
export const API_COUNTRY_MAPPINGS = {
  Korea: [
    'Korea',
    "Korea, Democratic People's Republic of",
    'Korea, Republic of',
  ],
} as Record<string, string[]>;

export const PATHS = {
  ABOUT: '/about',
  CONTACT: '/contact',
  COVID: '/covid',
  COVID_SEARCH: '/covidsearch',
  DASHBOARD: '/dashboard',
  DASHBOARD_APPLIED_TRIALS: '/dashboard/applied_trials',
  DASHBOARD_PROFILE: '/dashboard/profile',
  DASHBOARD_PROFILE_MEDICAL: '/dashboard/profile/medical_information',
  DASHBOARD_PROFILE_SETTINGS: '/dashboard/profile/settings',
  DASHBOARD_PROFILE_NOTIFICATIONS: '/dashboard/profile/notifications',
  DASHBOARD_LIKED_TRIALS: '/dashboard/liked_trials',
  FAQ: '/faq',
  FORGOT_PASSWORD: '/forgotpassword',
  HOME: '/',
  MAGIC_LINK: '/magic',
  PRIVACY: '/privacy',
  LEGACY_RESULTS: '/results',
  RESET_PASSWORD: '/resetpassword',
  SEARCH: '/search',
  SIGN_IN: '/signin',
  SIGN_IN_MAGIC: '/signin_magic',
  SIGN_UP: '/signup',
  SIGN_OUT: `/signin?action=${ACTION_SIGN_OUT}`,
  TERMS: '/terms',
};

export const BANNER_SETTINGS = {
  ENABLED: true,
  MESSAGE_KEY: 'common:create_a_profile_banner',
  LINK: PATHS.SIGN_UP,
  IS_INTERNAL: true,
};
