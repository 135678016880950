export default {
  'active': 'Active Trials',
  'applied_on': 'Applied On',
  'archived': 'Archived Trials',
  'archive_trial': 'Archive Trial',
  'no_archived_trials': 'You haven\'t archived any trials yet.',
  'no_trials_applied_for': 'You have not applied for any trials yet.',
  'status': 'Status',
  'trials_ive_applied_for': 'Trials I\'ve Applied For',
  'trial_name': 'Trial Name',
  'trial_objective': 'Objective',
  'type': 'Type',
  'unarchive_trial': 'Unarchive Trial',
};
