import * as Sentry from '@sentry/react';
import {
  changeEmail,
  changePassword,
  getAuthEmailDetails,
  resendEmailVerification,
  verifyEmailChange,
} from 'common/api/auth';
import EmailDetails from 'common/types/EmailDetails';
import isEmailValid from 'common/util/isEmailValid';
import isPasswordValid from 'common/util/isPasswordValid';
import Button from 'components/Button';
import LabeledInput from 'components/LabeledInput';
import LabeledPasswordField from 'components/LabeledPasswordField';
import SuccessMessage from 'components/SuccessMessage';
import TextButton from 'components/TextButton';
import useIsMounted from 'hooks/useIsMounted';
import DashboardPageHeading from 'modules/Dashboard/components/DashboardPageHeading';
import SectionHeading from 'modules/Dashboard/components/SectionHeading';
import QueryString from 'qs';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components/macro';

import { ACTION_CONFIRM } from './const';

// Styled Components
const StyledProfileSettings = styled.div``;

const StyledHeading = styled(DashboardPageHeading)`
  @media ${(props) => props.theme.devices.tablet} {
    display: none;
  }
`;

const StyledUpdateEmailForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media ${(props) => props.theme.devices.mobile} {
    gap: 40px;
  }
`;

const StyledUpdatePasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-top: 80px;

  @media ${(props) => props.theme.devices.mobile} {
    gap: 40px;
  }
`;

const StyledFormFields = styled.div`
  div {
    display: flex;
    margin-top: 3.3%;

    @media ${(props) => props.theme.devices.tablet} {
      flex-direction: column;
      margin-top: 3%;
    }

    label {
      box-sizing: border-box;
      width: 100%;
      max-width: 48.35%;

      &:nth-child(2n + 1) {
        margin-right: 1.65%;
      }

      &:nth-child(2n) {
        margin-left: 1.65%;
      }

      @media ${(props) => props.theme.devices.tablet} {
        flex: 1;
        max-width: initial;
        margin-left: 0;
        margin-right: 0;

        &:nth-child(n + 2) {
          margin-top: 3%;
        }
      }
    }
  }
`;

const StyledVerifyEmailMessage = styled.div`
  text-align: center;
`;

const StyledButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${(props) => props.theme.devices.mobile} {
    button {
      width: 100%;
    }
  }
`;

const StyledError = styled.span`
  color: ${(props) => props.theme.colors.error};
  text-align: center;
  margin-bottom: 32px;
`;

type QueryItems = {
  action?: string;
  code?: string;
  email?: string;
  region?: string;
};

export type PasswordFormState = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export type ProfileSettingsProps = {
  theme?: DefaultTheme;
};

const ProfileSettings = ({ ...rest }: ProfileSettingsProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { action, email, code } = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as QueryItems;

  const [currentEmailDetails, setCurrentEmailDetails] = useState<EmailDetails>({
    email: '',
    isVerified: true,
  });
  const [areEmailDetailsChecked, setAreEmailDetailsChecked] =
    useState<boolean>(false);
  const [newEmail, setNewEmail] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [emailHasError, setEmailHasError] = useState<boolean>(false);
  const [passwordHasError, setPasswordHasError] = useState<boolean>(false);
  const [newPasswordHasError, setNewPasswordHasError] =
    useState<boolean>(false);
  const [confirmPasswordHasError, setConfirmPasswordHasError] =
    useState<boolean>(false);
  const [emailErrorKey, setEmailErrorKey] = useState<string | null>(null);
  const [passwordErrorKey, setPasswordErrorKey] = useState<string | null>(null);
  const [passwordFormState, setPasswordFormState] = useState<PasswordFormState>(
    {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
  );
  const [emailUpdated, setEmailUpdated] = useState<boolean>(false);
  const [emailVerified, setEmailVerified] = useState<boolean>(false);
  const [emailVerificationSent, setEmailVerificationSent] =
    useState<boolean>(false);
  const [passwordUpdated, setPasswordUpdated] = useState<boolean>(false);

  const isMounted = useIsMounted();

  // Load the user's current email
  useEffect(() => {
    const loadCurrentEmail = async () => {
      setIsSubmitting(true);
      const emailDetails = await getAuthEmailDetails();
      if (!isMounted.current) {
        return;
      }

      setIsSubmitting(false);
      if (!emailDetails) {
        setEmailErrorKey('profile:error_loading_email');
        setAreEmailDetailsChecked(true);
        return;
      }

      setCurrentEmailDetails(emailDetails);
      setAreEmailDetailsChecked(true);
    };

    loadCurrentEmail();
  }, [isMounted]);

  // Take action as passed (e.g. verify email with code)
  useEffect(() => {
    const takeAction = async () => {
      switch (action) {
        case ACTION_CONFIRM: {
          if (!code) {
            return;
          }

          try {
            setIsSubmitting(true);
            const result = await verifyEmailChange(code);

            if (!isMounted.current) {
              return;
            }
            setIsSubmitting(false);

            if (!result?.success) {
              setEmailErrorKey('profile:email_verification_failed');
              return;
            }

            setEmailVerified(true);
            setCurrentEmailDetails((emailDetails) => ({
              ...emailDetails,
              isVerified: true,
            }));
            setTimeout(() => {
              if (!isMounted.current) {
                return;
              }
              setEmailVerified(false);
            }, 10000);
          } catch (ex) {
            if (!isMounted.current) {
              return;
            }
            setEmailErrorKey('profile:email_verification_failed');
            setIsSubmitting(false);
            Sentry.captureException(ex);
          }
          break;
        }
        default: {
          // Unknown action
          return;
        }
      }
    };

    if (action && areEmailDetailsChecked) {
      takeAction();
    }
  }, [action, email, areEmailDetailsChecked, isMounted, code]);

  const onSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    return false;
  };

  const onUpdateEmail = async () => {
    setEmailErrorKey(null);

    if (!isEmailValid(newEmail)) {
      setEmailHasError(true);
      return;
    }

    try {
      setIsSubmitting(true);
      const result = await changeEmail(newEmail);

      if (!isMounted.current) {
        return;
      }
      setIsSubmitting(false);

      if (!result.success) {
        setEmailErrorKey('common:unknown_error');
        return;
      }

      setCurrentEmailDetails({ ...currentEmailDetails, email: newEmail });
      setNewEmail('');
      setEmailUpdated(true);
      setTimeout(() => {
        if (!isMounted.current) {
          return;
        }
        setEmailUpdated(false);
      }, 10000);
    } catch (ex) {
      if (!isMounted.current) {
        return;
      }

      setEmailErrorKey('common:unknown_error');
      setIsSubmitting(false);
      Sentry.captureException(ex);
    }
  };

  const onSendVerification = async () => {
    try {
      setIsSubmitting(true);
      const result = await resendEmailVerification();
      if (!isMounted.current) {
        return;
      }
      setIsSubmitting(false);

      if (!result.success) {
        setEmailErrorKey('common:unknown_error');
        return;
      }

      setEmailVerificationSent(true);
      setTimeout(() => {
        if (!isMounted.current) {
          return;
        }
        setEmailVerificationSent(false);
      }, 10000);
    } catch (ex) {
      if (!isMounted.current) {
        return;
      }

      setEmailErrorKey('common:unknown_error');
      setIsSubmitting(false);
      Sentry.captureException(ex);
    }
  };

  const onUpdatePassword = async () => {
    setPasswordErrorKey(null);

    let hasError = false;
    if (!isPasswordValid(passwordFormState.currentPassword)) {
      setPasswordHasError(true);
      hasError = true;
    }

    if (!isPasswordValid(passwordFormState.newPassword)) {
      setNewPasswordHasError(true);
      hasError = true;
    }

    if (
      passwordFormState.confirmNewPassword !== passwordFormState.newPassword
    ) {
      setConfirmPasswordHasError(true);
      hasError = true;
    }

    if (hasError) {
      return;
    }

    try {
      setIsSubmitting(true);
      const result = await changePassword(
        passwordFormState.currentPassword,
        passwordFormState.newPassword,
      );

      if (!isMounted.current) {
        return;
      }
      setIsSubmitting(false);

      if (!result.success) {
        setPasswordErrorKey('profile:password_update_failed');
        return;
      }

      setPasswordFormState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      });
      setPasswordUpdated(true);
      setTimeout(() => {
        if (!isMounted.current) {
          return;
        }
        setPasswordUpdated(false);
      }, 10000);
    } catch (ex) {
      if (!isMounted.current) {
        return;
      }

      setPasswordErrorKey('common:unknown_error');
      setIsSubmitting(false);
      Sentry.captureException(ex);
    }
  };

  return (
    <StyledProfileSettings {...rest}>
      <StyledHeading>{t('profile:profile_settings')}</StyledHeading>
      <StyledUpdateEmailForm onSubmit={onSubmit}>
        <section>
          <SectionHeading>{t('profile:update_email_title')}</SectionHeading>
          <StyledFormFields>
            <div>
              <LabeledInput
                label={t('profile:current_email')}
                value={currentEmailDetails.email}
                type="email"
                disabled
              />
              <LabeledInput
                autoComplete="email"
                disabled={isSubmitting}
                hasError={emailHasError}
                label={t('profile:new_email')}
                placeholder={t('common:email_placeholder')}
                value={newEmail}
                type="email"
                onChange={(evt) => {
                  setEmailHasError(false);
                  setNewEmail(evt.target.value);
                }}
              />
            </div>
          </StyledFormFields>
        </section>
        {!currentEmailDetails.isVerified && (
          <StyledVerifyEmailMessage>
            <Trans i18nKey="profile:email_not_verified">
              <span />
              <TextButton onClick={onSendVerification} />
            </Trans>
          </StyledVerifyEmailMessage>
        )}
        <StyledButtonRow>
          <SuccessMessage
            message={t('profile:email_verification_sent')}
            shown={emailVerificationSent}
          />
          <SuccessMessage
            message={t('profile:email_updated_successfully')}
            shown={emailUpdated}
          />
          <SuccessMessage
            message={t('profile:email_verified_successfully')}
            shown={emailVerified}
          />
          {emailErrorKey && <StyledError>{t(emailErrorKey)}</StyledError>}
          <Button
            type="button"
            disabled={isSubmitting}
            dark
            onClick={onUpdateEmail}
          >
            {t('profile:update_email')}
          </Button>
        </StyledButtonRow>
      </StyledUpdateEmailForm>

      <StyledUpdatePasswordForm onSubmit={onSubmit}>
        <section>
          <SectionHeading>{t('profile:update_password_title')}</SectionHeading>
          <StyledFormFields>
            <div>
              <LabeledPasswordField
                disabled={isSubmitting}
                hasError={passwordHasError}
                label={t('profile:current_password')}
                value={passwordFormState.currentPassword}
                onChange={(evt) => {
                  setPasswordHasError(false);
                  setPasswordFormState({
                    ...passwordFormState,
                    currentPassword: evt.target.value,
                  });
                }}
              />
            </div>
            <div>
              <LabeledPasswordField
                disabled={isSubmitting}
                hasError={newPasswordHasError}
                label={t('profile:new_password')}
                value={passwordFormState.newPassword}
                onChange={(evt) => {
                  setNewPasswordHasError(false);
                  setPasswordFormState({
                    ...passwordFormState,
                    newPassword: evt.target.value,
                  });
                }}
              />
              <LabeledPasswordField
                disabled={isSubmitting}
                hasError={confirmPasswordHasError}
                label={t('profile:new_password_confirm')}
                value={passwordFormState.confirmNewPassword}
                onChange={(evt) => {
                  setConfirmPasswordHasError(false);
                  setPasswordFormState({
                    ...passwordFormState,
                    confirmNewPassword: evt.target.value,
                  });
                }}
              />
            </div>
          </StyledFormFields>
        </section>
        <StyledButtonRow>
          {passwordErrorKey && <StyledError>{t(passwordErrorKey)}</StyledError>}
          <SuccessMessage
            message={t('profile:password_updated_successfully')}
            shown={passwordUpdated}
          />
          <Button
            disabled={isSubmitting}
            type="button"
            dark
            onClick={onUpdatePassword}
          >
            {t('profile:update_password')}
          </Button>
        </StyledButtonRow>
      </StyledUpdatePasswordForm>
    </StyledProfileSettings>
  );
};

export default ProfileSettings;
