import Profile from 'common/types/Profile';
import { BASE_URL } from './constants';
import authenticatedFetch from './util/authenticatedFetch';

/**
 * Get the current user's profile
 * @returns {Promise<Profile | null>} The user's profile if it exists
 */
export async function getProfile(): Promise<Profile | null> {
  const response = await authenticatedFetch(`${BASE_URL}/profile`);

  if (response.status === 404) {
    return null;
  }

  if (!response.ok) {
    throw new Error(`Bad Response: ${response.status}`);
  }
  return response.json();
}

/**
 * Post profile content to create/update details
 * @param {Profile} profile The profile details to post
 * @returns {Promise<Profile | null>} The created/updated profile
 */
export async function postProfile(
  profile: Partial<Profile>,
): Promise<Profile | null> {
  const headers: HeadersInit = new Headers();
  headers.set('Content-Type', 'application/json');
  const response = await authenticatedFetch(`${BASE_URL}/profile`, {
    method: 'POST',
    headers,
    body: JSON.stringify(profile),
  });

  if (!response.ok) {
    return null;
  }
  return response.json();
}
