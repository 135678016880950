import 'common/i18n';
import { createBrowserHistory as createHistory } from 'history';
import { Router, withRouter } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { ThemeProvider } from 'styled-components/macro';

// Theme
import healMaryTheme, { GlobalStyle } from 'themes/HealMary';

import { IS_INTEGRATOR_MODE } from 'common/constants';
import getClientId from 'common/util/getClientId';
import { AuthContextWrapper } from 'contexts/AuthContext';
import { IntegratorContextWrapper } from 'contexts/IntegratorContext';
import HealMaryIntegrator from './HealMaryIntegrator';

// Router setup
// In integrator mode, the client ID is the base path, i.e. /:clientId/path
const basename = IS_INTEGRATOR_MODE ? getClientId() : undefined;
const history = createHistory({ basename });
const HealMaryIntegratorWithRouter = withRouter(HealMaryIntegrator);

export default () => (
  <Router history={history}>
    <LastLocationProvider>
      <ThemeProvider theme={healMaryTheme}>
        <>
          <GlobalStyle />
          <IntegratorContextWrapper isIntegratorMode={IS_INTEGRATOR_MODE}>
            <AuthContextWrapper>
              <HealMaryIntegratorWithRouter />
            </AuthContextWrapper>
          </IntegratorContextWrapper>
        </>
      </ThemeProvider>
    </LastLocationProvider>
  </Router>
);
