import * as Sentry from '@sentry/react';
import {
  resendVerification,
  signIn,
  signOut,
  validateSignIn,
  verifyEmail,
} from 'common/api/auth';
import { PATHS } from 'common/constants';
import AuthPage from 'components/AuthPage';
import Button from 'components/Button';
import LabeledInput from 'components/LabeledInput/LabeledInput';
import LabeledPasswordField from 'components/LabeledPasswordField';
import TextButton from 'components/TextButton';
import AuthContext from 'contexts/AuthContext';
import useIsMounted from 'hooks/useIsMounted';
import useRedirectIfSignedIn from 'hooks/useRedirectIfSignedIn';
import QueryString from 'qs';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components';
import accentImg from './assets/accent_image.jpg';
import magicLinkIconImg from './assets/icon-magic_link.svg';
import { ACTION_CONFIRM, ACTION_SIGN_OUT } from './const';

const StyledHeading = styled.h1`
  white-space: pre-line;
  margin-bottom: 50px;
`;

const StyledSignInForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const StyledFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const StyledPasswordSection = styled.div``;

const StyledActionSection = styled.div`
  display: flex;
  margin-top: 10px;

  a {
    border-bottom: none;
    text-decoration: underline;
    color: ${(props) => props.theme.colors.textLinkButton};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${(props) => props.theme.fontSizes.small};
  }
`;

const StyledError = styled.span`
  color: ${(props) => props.theme.colors.error};
`;

const StyledSuccess = styled.div`
  color: ${(props) => props.theme.colors.successText};
`;

const StyledMagicLink = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 30px;
  padding: 8px 16px;
  background-color: ${(props) => props.theme.colors.infoBackground};
  border-radius: 8px;
  font-size: ${(props) => props.theme.fontSizes.small};
`;

const StyledMagicLinkIcon = styled.img`
  display: block;
  margin-left: 8px;
  margin-right: 16px;
`;

const StyledSignUp = styled.div`
  margin-top: 40px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  text-align: center;
`;

type FormState = {
  email: string;
  password: string;
};

type QueryItems = {
  action?: string;
  code?: string;
  email?: string;
  region?: string;
  signUpComplete?: string;
  username?: string;
  targetPath?: string;
};

export type SignInProps = {
  theme?: DefaultTheme;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SignIn = (props: SignInProps) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const history = useHistory();

  const location = useLocation();
  const { action, email, code, signUpComplete, targetPath } = QueryString.parse(
    location.search,
    {
      ignoreQueryPrefix: true,
    },
  ) as QueryItems;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorKey, setErrorKey] = useState<string | null>(null);
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);
  const [emailHasError, setEmailHasError] = useState<boolean>(false);
  const [passwordHasError, setPasswordHasError] = useState<boolean>(false);
  const [formState, setFormState] = useState<FormState>({
    email: email ?? '',
    password: '',
  });

  useRedirectIfSignedIn(
    targetPath ?? PATHS.DASHBOARD,
    action !== ACTION_SIGN_OUT,
  );
  const { setIsAuthenticated, recheckSession } = useContext(AuthContext);

  useEffect(() => {
    const takeAction = async () => {
      // Currently only email verification is the only action
      switch (action) {
        case ACTION_SIGN_OUT: {
          setIsAuthenticated(false);
          try {
            await signOut();
            await recheckSession();
          } catch (ex) {
            Sentry.captureException(ex);
          }
          return;
        }
        case ACTION_CONFIRM: {
          if (!email || !code) {
            return;
          }

          try {
            setIsSubmitting(true);
            const result = await verifyEmail(email, code);

            if (!isMounted.current) {
              return;
            }
            setIsSubmitting(false);

            if (result?.success) {
              setIsEmailVerified(true);
              return;
            }

            setErrorKey('signIn:email_verification_failed');
            return;
          } catch (ex) {
            if (!isMounted.current) {
              return;
            }

            setErrorKey('signIn:unknown_error');
            setIsSubmitting(false);
            Sentry.captureException(ex);
          }
          break;
        }
        default: {
          // Unknown action
          return;
        }
      }
    };

    if (action) {
      takeAction();
    }
  }, [action, setIsAuthenticated, email, isMounted, code, recheckSession]);

  const onSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    return false;
  };

  const onSignInSuccess = async () => {
    setIsAuthenticated(true);
    recheckSession();
    // Since sign in is handled by Cognito, need to send
    // a separate request to validate auth with the backend
    try {
      setIsSubmitting(true);
      const result = await validateSignIn();
      if (!isMounted.current) {
        return;
      }

      setIsSubmitting(false);
      if (result.uid) {
        history.push(targetPath ?? PATHS.DASHBOARD);
        return;
      }
      setErrorKey('signIn:unknown_error');
    } catch (ex) {
      setErrorKey('signIn:unknown_error');
      setIsSubmitting(false);
      Sentry.captureException(ex);
    }
  };

  const onSignIn = async () => {
    setErrorKey(null);
    setIsEmailVerified(false);

    // Validate email
    let hasError = false;
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formState.email)) {
      hasError = true;
      setEmailHasError(true);
    }

    if (formState.password.length === 0) {
      hasError = true;
      setPasswordHasError(true);
    }

    if (hasError) {
      return;
    }

    try {
      setIsSubmitting(true);
      const result = await signIn(formState.email, formState.password);
      if (!isMounted.current) {
        return;
      }
      setIsSubmitting(false);

      if (result?.success) {
        onSignInSuccess();
        return;
      }

      switch (result?.error?.code) {
        case 'UserNotConfirmedException': {
          setErrorKey('signIn:email_not_confirmed');
          return;
        }
        default: {
          setErrorKey('signIn:invalid_username_or_password');
          return;
        }
      }
    } catch (ex: any) {
      if (!isMounted.current) {
        return;
      }

      setErrorKey('signIn:unknown_error');
      setIsSubmitting(false);
      Sentry.captureException(ex);
    }
  };

  const onResendConfirmation = async () => {
    try {
      setIsSubmitting(true);
      const result = await resendVerification(formState.email);

      if (!isMounted.current) {
        return;
      }
      setIsSubmitting(false);

      if (result?.success) {
        setErrorKey(null);
        return;
      }

      setErrorKey('signIn:unknown_error');
    } catch (ex: any) {
      if (!isMounted.current) {
        return;
      }

      setErrorKey('signIn:unknown_error');
      setIsSubmitting(false);
      Sentry.captureException(ex);
    }
  };

  return (
    <AuthPage accentImage={accentImg}>
      <StyledSignInForm onSubmit={onSubmit}>
        <StyledHeading>{t('signIn:welcome_back')}</StyledHeading>
        <StyledFields>
          <LabeledInput
            autoComplete="email"
            disabled={isSubmitting}
            hasError={emailHasError}
            label={t('common:email')}
            placeholder={t('common:email_placeholder')}
            type="email"
            value={formState.email}
            onChange={(evt) => {
              setEmailHasError(false);
              setFormState({ ...formState, email: evt.target.value });
            }}
          />
          <StyledPasswordSection>
            <LabeledPasswordField
              disabled={isSubmitting}
              hasError={passwordHasError}
              label={t('common:password')}
              value={formState.password}
              onChange={(evt) => {
                setPasswordHasError(false);
                setFormState({ ...formState, password: evt.target.value });
              }}
            />
            <StyledActionSection>
              <Link to="/forgotpassword">{t('signIn:forgot_password')}</Link>
            </StyledActionSection>
          </StyledPasswordSection>
          {errorKey &&
            ([
              'signIn:email_not_confirmed',
              'signIn:email_verification_failed',
            ].includes(errorKey) ? (
              <StyledError>
                <Trans i18nKey={errorKey}>
                  <span />
                  <TextButton onClick={onResendConfirmation} />
                </Trans>
              </StyledError>
            ) : (
              <StyledError>{t(errorKey)}</StyledError>
            ))}
          {isEmailVerified && (
            <StyledSuccess>{t('signIn:email_verified')}</StyledSuccess>
          )}
          {signUpComplete == 'true' && (
            <StyledSuccess>{t('signIn:sign_up_complete')}</StyledSuccess>
          )}
          <Button dark disabled={isSubmitting} onClick={onSignIn}>
            {t('signIn:sign_in')}
          </Button>
        </StyledFields>
        <StyledMagicLink>
          <StyledMagicLinkIcon src={magicLinkIconImg} alt="" />
          <span>
            <Trans i18nKey={'signIn:magic_link_prompt'}>
              <Link to={PATHS.SIGN_IN_MAGIC} />
            </Trans>
          </span>
        </StyledMagicLink>
        <StyledSignUp>
          <Trans i18nKey="signIn:dont_have_an_account">
            <span />
            <Link
              to={`${PATHS.SIGN_UP}${
                targetPath
                  ? `?targetPath=${encodeURIComponent(targetPath)}`
                  : ''
              }`}
            />
          </Trans>
        </StyledSignUp>
      </StyledSignInForm>
    </AuthPage>
  );
};

export default SignIn;
