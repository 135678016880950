import React, { useState } from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import definitions from 'common/definitions.json';

import TokenizedText from 'components/TokenizedText';
import Modal from 'components/Modal';
import TextButton from 'components/TextButton';

// Styled Components
const StyledClickableDefinition = styled.span`
  color: ${(props) => props.theme.colors.textLinkButton};
  text-decoration: underline;
  cursor: pointer;
`;

const StyledDefinition = styled.span`
  color: ${(props) => props.theme.colors.text};
  white-space: pre-line;
`;

const StyledHeading = styled.h3`
  margin-bottom: 0.75em;
`;

export type ClickableDefinitionsProps = {
  children: string;
  theme?: DefaultTheme;
};

// Component
const ClickableDefinitions = ({ children }: ClickableDefinitionsProps) => {
  const { t } = useTranslation(['common']);

  const [isDefinitionShown, setIsDefinitionShown] = useState<boolean>(false);
  const [currentDefinition, setCurrentDefinition] = useState<string>('');

  const definitionTokens = Object.keys(definitions);
  const getOnDefinitionClick =
    (definition: string) => (evt: React.MouseEvent | React.KeyboardEvent) => {
      evt.preventDefault();

      setCurrentDefinition(definition);
      setIsDefinitionShown(true);
    };

  const tokenWrap = (text: string, token: string) => (
    <StyledClickableDefinition
      role="button"
      tabIndex={0}
      onClick={getOnDefinitionClick(token)}
      onKeyPress={getOnDefinitionClick(token)}
    >
      {text}
    </StyledClickableDefinition>
  );

  const onClose = () => setIsDefinitionShown(false);

  return (
    <>
      <span>
        <TokenizedText tokens={definitionTokens} wrap={tokenWrap}>
          {children}
        </TokenizedText>
      </span>
      {isDefinitionShown && (
        <Modal
          controls={
            <TextButton onClick={onClose}>{t('common:close')}</TextButton>
          }
          depth={10}
          small
          onClose={onClose}
        >
          <StyledHeading>{currentDefinition}</StyledHeading>
          <StyledDefinition>
            {Object.keys(definitions).includes(currentDefinition)
              ? (definitions as Record<string, string>)[currentDefinition]
              : ''}
          </StyledDefinition>
        </Modal>
      )}
    </>
  );
};

export default ClickableDefinitions;
