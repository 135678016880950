export default {
  'browser_title': 'Heal Mary - Contact',

  'contact_us': 'Contact Us',

  'contact_us_email': 'Send us an email: <1>{{email}}</1>',
  'contact_us_phone': 'Call us toll free: {{- phone}}',

  'share_feedback': 'Share feedback',
  'share_feedback_with_us': 'Share your feedback with us',

  'share_feedback_content': '<p>Hey thanks for being here.</p><p>It\'s really important for our team to understand what you think. Your feedback is going to be so important for creating the best experience to help patients find trials that meet their needs in partnership with their doctors.</p><p>3-5 minutes of your time will make a world of difference for people looking for answers about clinical trials, drugs, and results in an already overwhelming situation of trying to learn so much about their disease/ailment.</p><p>If you\'d like to participate please click on this link.</p>',

  'title': 'Got a question? Get in touch with us.',
};
