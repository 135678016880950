import Button from 'components/Button';
import Modal from 'components/Modal';
import { ModalProps } from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const StyledConfirmationButtons = styled.div`
  display: flex;
  gap: 16px;
`;

const StyledHeading = styled.h1`
  font-size: ${(props) => props.theme.fontSizes.h4};
`;

const StyledPrompt = styled.p``;

export type ConfirmationModalProps = {
  prompt?: string;
  title?: string;
  onCancel: () => void;
  onConfirm: () => void;
  theme?: DefaultTheme;
} & ModalProps;

const ConfirmationModal = ({
  children,
  prompt,
  title,
  onCancel,
  onConfirm,
  ...rest
}: ConfirmationModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal {...rest}>
      <StyledContent>
        {title && <StyledHeading>{title}</StyledHeading>}
        {prompt && <StyledPrompt>{prompt}</StyledPrompt>}
        <StyledConfirmationButtons>
          <Button small onClick={() => onCancel()}>
            {t('common:cancel')}
          </Button>
          <Button dark small onClick={() => onConfirm()}>
            {t('common:ok')}
          </Button>
        </StyledConfirmationButtons>
      </StyledContent>
      {children}
    </Modal>
  );
};

export default ConfirmationModal;
