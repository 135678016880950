import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { SOCIAL_LINKS } from 'common/constants';

import facebookImg from './assets/facebook.svg';
import twitterImg from './assets/twitter.svg';
import instagramImg from './assets/instagram.svg';
import linkedInImg from './assets/linkedin.svg';
import mediumImg from './assets/medium.svg';

import facebookRoseImg from './assets/facebook-rose.svg';
import twitterRoseImg from './assets/twitter-rose.svg';
import instagramRoseImg from './assets/instagram-rose.svg';
import linkedInRoseImg from './assets/linkedin-rose.svg';
import mediumRoseImg from './assets/medium-rose.svg';

// Styled Components
const StyledSocialIcons = styled.div``;

const StyledSocailLink = styled.a`
  margin: 0 10px;
  border-bottom: none;

  & > img {
    transition: transform 0.2s;
    &:hover {
      transform: scale(1.2);
    }
  }
`;

export type SocialIcons = {
  dark?: boolean;
  theme?: DefaultTheme;
};

// Component
const SocialIcons = ({ dark = false }: SocialIcons) => {
  const { t } = useTranslation(['common']);

  return (
    <StyledSocialIcons>
      <StyledSocailLink
        href={SOCIAL_LINKS.FACEBOOK}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={dark ? facebookRoseImg : facebookImg}
          alt={t('common:facebook')}
        />
      </StyledSocailLink>
      <StyledSocailLink
        href={SOCIAL_LINKS.TWITTER}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={dark ? twitterRoseImg : twitterImg}
          alt={t('common:twitter')}
        />
      </StyledSocailLink>
      <StyledSocailLink
        href={SOCIAL_LINKS.INSTAGRAM}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={dark ? instagramRoseImg : instagramImg}
          alt={t('common:instagram')}
        />
      </StyledSocailLink>
      <StyledSocailLink
        href={SOCIAL_LINKS.LINKED_IN}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={dark ? linkedInRoseImg : linkedInImg}
          alt={t('common:linkedin')}
        />
      </StyledSocailLink>
      <StyledSocailLink
        href={SOCIAL_LINKS.MEDIUM}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={dark ? mediumRoseImg : mediumImg} alt={t('common:medium')} />
      </StyledSocailLink>
    </StyledSocialIcons>
  );
};

export default SocialIcons;
