import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledSectionHeading = styled.h2`
  margin: 0;
  margin-bottom: 32px;

  font-size: ${(props) => props.theme.fontSizes.h3};
  color: ${(props) => props.theme.colors.text};

  @media ${(props) => props.theme.devices.mobile} {
    margin-bottom: 24px;
  }
`;

export type SectionHeadingProps = {
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'h2'>;

const SectionHeading = ({ children, ...rest }: SectionHeadingProps) => (
  <StyledSectionHeading {...rest}>{children}</StyledSectionHeading>
);

export default SectionHeading;
