import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components/macro';

export type StyledButtonProps = {
  dark?: boolean;
  small?: boolean;
  hasIcon?: boolean;
};

// Styled Components
const StyledButton = styled.button<StyledButtonProps>`
  /* Common styles */
  position: relative;
  min-width: 140px;
  margin: 0;
  padding: 14px 20px;
  border-radius: 5px;
  text-align: center;

  ${(props) =>
    props.dark
      ? /* Dark Styles */
        css`
          color: ${props.theme.colors.darkButtonText};
          background-color: ${props.theme.colors.darkButtonBackground};
          border: 2px solid ${props.theme.colors.darkButtonBorder};
          transition: opacity 0.4s, background-color 0.4s, border-color 0.4s;

          &:hover {
            opacity: 0.85;
          }

          &:disabled {
            background-color: ${props.theme.colors
              .darkButtonDisabledBackground};
            border-color: ${props.theme.colors.darkButtonDisabledBackground};

            &:hover {
              opacity: 1;
            }
          }
        `
      : /* Light Styles */
        css`
          color: ${props.theme.colors.lightButtonText};
          background-color: ${props.theme.colors.lightButtonBackground};
          border: 2px solid ${props.theme.colors.lightButtonBorder};
          transition: background-color 0.4s, color 0.4s;

          &:hover {
            background-color: ${props.theme.colors.darkButtonBackground};
            color: ${props.theme.colors.darkButtonText};
          }
        `}

  ${(props) =>
    props.small &&
    css`
      padding: 10px 18px;
      font-size: ${props.theme.fontSizes.small};
    `}

  ${(props) =>
    props.hasIcon &&
    (props.small
      ? css`
          padding-left: 46px;
        `
      : css`
          padding-left: 64px;
        `)}

  @media ${(props) => props.theme.devices.mobile} {
    display: block;
  }
`;

const StyledIconContainer = styled.div<{ small: boolean }>`
  position: absolute;
  left: 18px;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);

  ${(props) =>
    props.small
      ? css`
          height: 19px;
          width: 19px;
        `
      : css`
          height: 30px;
          width: 30px;
        `}
`;

const StyledIcon = styled.img<{ hasHover?: boolean }>`
  display: inline-block;

  ${(props) =>
    props.hasHover
      ? css`
          ${StyledButton}:hover & {
            display: none;
          }
        `
      : css``};
`;

const StyledHoverIcon = styled.img`
  display: none;

  ${StyledButton}:hover & {
    display: inline-block;
  }
`;

export type ButtonProps = {
  as?: any;
  icon?: string | null;
  iconHover?: string | null;
  dark?: boolean;
  small?: boolean;
  theme?: DefaultTheme;
} & Omit<React.ComponentProps<'button'>, 'ref'>;

// Component
const Button = ({
  as,
  children,
  icon = null,
  iconHover,
  dark,
  small = false,
  ...rest
}: ButtonProps) => (
  <StyledButton
    as={as}
    dark={dark}
    hasIcon={icon !== null}
    small={small}
    {...rest}
  >
    {icon && (
      <StyledIconContainer small={small}>
        {icon ? (
          <StyledIcon src={icon} hasHover={iconHover !== null} alt="" />
        ) : (
          ''
        )}
        {iconHover ? <StyledHoverIcon src={iconHover} alt="" /> : ''}
      </StyledIconContainer>
    )}
    {children}
  </StyledButton>
);

export default Button;
