/* eslint-disable react/no-array-index-key */
import * as Sentry from '@sentry/react';
import qs from 'qs';
import { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import styled, { DefaultTheme, css } from 'styled-components/macro';

import { checkIsSignedIn } from 'common/api/auth';
import { isTrialLiked, likeTrial, unlikeTrial } from 'common/api/likedTrials';
import { applyToTrial } from 'common/api/trials';
import LocationGroupings from 'common/types/LocationGroupings';
import TrialResponse from 'common/types/TrialResponse';
import groupLocationByCountryAndState from 'common/util/groupLocationByCountryAndState';
import hasDisplayableIntervention from 'common/util/hasDisplayableIntervention';
import isIQVIATrial from 'common/util/isIQVIATrial';

import Button from 'components/Button';
import ClickableDefinitions from 'components/ClickableDefinitions';
import ContactDetails from 'components/ContactDetails';
import DetailsList from 'components/DetailsList';
import DetailsSubList from 'components/DetailsSubList';
import EligibilityCheckModal from 'components/EligibilityCheckModal';
import FacilityDetails from 'components/FacilityDetails';
import InterventionIconSet from 'components/InterventionIconSet';
import LocationListModal from 'components/LocationListModal';
import LocationSummaryList from 'components/LocationSummaryList';
import Modal from 'components/Modal';
import RedirectModal from 'components/RedirectModal';
import ResourceLinks from 'components/ResourceLinks';
import SectionHeading from 'components/SectionHeading';
import SectionSubheading from 'components/SectionSubheading';
import SharePageControl from 'components/SharePageControl';
import StatusIndicator from 'components/StatusIndicator';
import SummaryList from 'components/SummaryList';
import TextButton from 'components/TextButton';
import AuthContext from 'contexts/AuthContext';

import { PATHS } from 'common/constants';
import AiProcessedIndicator from 'components/AiProcessedIndicator';
import ButtonLink from 'components/ButtonLink';
import Survey83Bar from 'components/Survey83Bar';
import iconHeartOutlineImg from './assets/icon-heart-outline.svg';
import iconHeartImg from './assets/icon-heart.svg';
import iconPrintLightImg from './assets/icon-print-light.svg';
import iconPrintImg from './assets/icon-print.svg';

// Styled Components
const StyledTrial = styled.main`
  padding-top: 60px;
  padding-bottom: 60px;
`;

const StyledTrialContent = styled.div`
  ${(props) => props.theme.paddedContent}

  max-width: 800px;
  margin: 0 auto;
`;

const StyledBackLink = styled(TextButton)`
  margin-bottom: 60px;
  text-decoration: none;

  color: ${(props) => props.theme.colors.altLightButtonText};
`;

const StyledTitleSection = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 80px;
  margin-bottom: 1.25em;
`;

const StyledTitle = styled.h3`
  font-family: ${(props) => props.theme.fonts.main};
  margin-bottom: 0;
`;

const StyledActionsDesktop = styled.div`
  display: flex;
  flex-shrink: 0;
  gap: 16px;

  @media ${(props) => props.theme.devices.mobile} {
    display: none;
  }
`;

const StyledActionsMobile = styled.div`
  display: none;

  @media ${(props) => props.theme.devices.mobile} {
    display: block;
  }
`;

const StyledSharePageControl = styled(SharePageControl)`
  margin-bottom: 56px;
`;

const StyledLoading = styled.h4`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 250px;

  font-family: ${(props) => props.theme.fonts.main};
  text-align: center;
`;

const StyledLoadingError = styled.h4`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 250px;

  font-family: ${(props) => props.theme.fonts.main};
  color: ${(props) => props.theme.colors.error};
  text-align: center;
`;

const StyledStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 30px;
`;

const StyledStatusLabel = styled(SectionSubheading)`
  padding-right: 10px;
`;

const StyledTextContent = styled.p`
  white-space: pre-line;
  word-wrap: break-word;
`;

const StyledDetailsSection = styled.section`
  margin-top: 60px;
  margin-bottom: 60px;
`;

const StyledSubSection = styled.section<{ horizontal?: boolean }>`
  margin-top: 30px;
  margin-bottom: 30px;

  ${(props) =>
    props.horizontal &&
    css`
      display: flex;
      align-items: center;
      gap: 16px;
    `}
`;

const StyledContactButton = styled(Button)`
  @media ${(props) => props.theme.devices.mobile} {
    width: 100%;
  }
`;

const StyledModalHeading = styled.h2`
  text-align: center;
`;

const StyledContactSet = styled.div`
  margin-top: 20px;
`;

const StyledCTASection = styled.div`
  padding: 40px 20px;

  background-color: ${(props) => props.theme.rawColors.offWhite};
  text-align: center;

  @media ${(props) => props.theme.devices.mobile} {
    margin-left: -15px;
    margin-right: -15px;
  }

  & > button {
    margin-top: 20px;

    @media ${(props) => props.theme.devices.mobile} {
      width: 100%;
    }
  }
`;

const StyledCompleteProfileMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  max-width: 400px;
  margin: 0 auto;
`;

export type RouteMatchParams = {
  id?: string;
};

export type TrialPageProps = {
  loadTrial: () => Promise<TrialResponse>;
  theme?: DefaultTheme;
};

// Component
const TrialPage = ({ loadTrial }: TrialPageProps) => {
  const { t } = useTranslation(['trial']);
  const history = useHistory();
  const pageLocation = useLocation();
  const match = useRouteMatch<RouteMatchParams>();
  const authState = useContext(AuthContext);

  const {
    params: { id = null },
  } = match;

  const {
    showEligibilityCheck = false,
    inclusionValues: inclusionValuesString = '',
    exclusionValues: exclusionValuesString = '',
  } = qs.parse(pageLocation.search, { ignoreQueryPrefix: true });

  const inclusionValues = qs.parse(inclusionValuesString as string) as Record<
    number,
    string
  >;
  const exclusionValues = qs.parse(exclusionValuesString as string) as Record<
    number,
    string
  >;

  const lastLocation = useLastLocation();

  const [trial, setTrial] = useState<TrialResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<any | null>(null);
  const [isLocationModalShown, setIsLocationModalShown] =
    useState<boolean>(false);
  const [isContactModalShown, setIsContactModalShown] =
    useState<boolean>(false);
  const [isEligibilityModalShown, setIsEligibilityModalShown] =
    useState<boolean>(showEligibilityCheck == 'true' || false);
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const [isProfileModalShown, setIsProfileModalShown] =
    useState<boolean>(false);

  const [isRedirectModalShown, setIsRedirectModalShown] = useState(false);
  const redirectTimeoutRef = useRef<number | null>(null);
  const redirectUrlRef = useRef<string>();

  // TODO: Revisit data loading
  useEffect(() => {
    const callLoadTrial = async () => {
      try {
        const trialData = await loadTrial();
        setTrial(trialData);
      } catch (ex) {
        setLoadingError(ex);
      } finally {
        setIsLoading(false);
      }
    };

    callLoadTrial();
  }, [id, loadTrial]);

  useEffect(() => {
    const checkIsLiked = async () => {
      if (!id) {
        return;
      }
      try {
        // Only check if the user is logged in
        const isLoggedIn = await checkIsSignedIn();
        if (!isLoggedIn) {
          return;
        }

        const isLikedResult = await isTrialLiked(id);
        setIsLiked(isLikedResult);
      } catch (ex) {
        Sentry.captureException(ex);
      }
    };

    checkIsLiked();
  }, [id]);

  if (isLoading) {
    return (
      <StyledTrial>
        <StyledLoading>{t('trial:loading_trial')}</StyledLoading>
      </StyledTrial>
    );
  }

  if (loadingError || !trial || !id) {
    return (
      <StyledTrial>
        <StyledLoadingError>
          {t('trial:error_loading_trial')}
        </StyledLoadingError>
      </StyledTrial>
    );
  }

  // Destructure the trial details
  const {
    data: {
      brief_title: briefTitle,
      overall_status: overallStatus,
      is_processed: isProcessed,
      conditions = [],
      overall_contact: overallContact = {},
      overall_contact_backup: overallContactBackup = {},
      detailed_description: { textblock: detailedDescription = null } = {},
      eligibility: {
        criteria: {
          inclusion = null,
          exclusion = null,
          textblock: eligibilityCriteria = null,
        } = {},
      } = {},
      interventions = [],
      is_sponsored: isSponsored = false,
      locations = [],
      links = [],
    } = {},
  } = trial;

  // Check if contact information is available
  const hasContact = Object.keys(overallContact).length > 0;
  const hasContactBackup = Object.keys(overallContactBackup).length > 0;

  // Group locations and filter those with contacts
  const locationsByCountryState: LocationGroupings = {};
  const locationsByCountryStateWithContact: LocationGroupings = {};

  let hasLocationWithContact = false;

  locations.forEach((location) => {
    // Destructure location details
    const {
      contact = null,
      contact_backup: contactBackup = null,
      facility: {
        address: { country = undefined, state = undefined } = {},
      } = {},
    } = location;

    groupLocationByCountryAndState(
      locationsByCountryState,
      country,
      state,
      location,
    );

    // Keep separate structure of only locations with contacts
    if (contact || contactBackup) {
      hasLocationWithContact = true;
      groupLocationByCountryAndState(
        locationsByCountryStateWithContact,
        country,
        state,
        location,
      );
    }
  });

  const isIQVIA = isIQVIATrial(id);
  const survey83BarId =
    id === 'NCT00183BAR' ? '9e77de4cdedbf221922e3508ca8b3b64' : undefined;
  const startRedirect = async () => {
    const redirectData = await applyToTrial(id, {}, [], []);
    if (!redirectData || !redirectData.redirect_url) {
      return;
    }

    redirectUrlRef.current = redirectData.redirect_url;
    setIsRedirectModalShown(true);
    redirectTimeoutRef.current = window.setTimeout(() => {
      window.location.href = redirectUrlRef.current!;
    }, 5000);
  };

  const onCancelRedirect = () => {
    if (redirectTimeoutRef.current !== null) {
      window.clearTimeout(redirectTimeoutRef.current);
      redirectTimeoutRef.current = null;
    }
    setIsRedirectModalShown(false);
  };

  const onForceRedirect = () => {
    window.location.href = redirectUrlRef.current!;
  };

  const onLike = async () => {
    if (!authState.profile) {
      setIsProfileModalShown(true);
      return;
    }

    // Optimistically like
    setIsLiked(true);
    try {
      await likeTrial(id);
    } catch (ex) {
      Sentry.captureException(ex);
    }
  };
  const onUnlike = async () => {
    // Optimistically unlike
    setIsLiked(false);
    try {
      await unlikeTrial(id);
    } catch (ex) {
      Sentry.captureException(ex);
    }
  };

  return (
    <StyledTrial>
      <StyledTrialContent>
        {lastLocation !== null && (
          <StyledBackLink onClick={() => history.goBack()}>
            {t('trial:back_to_results')}
          </StyledBackLink>
        )}
        <StyledTitleSection>
          <StyledTitle>{briefTitle}</StyledTitle>
          <StyledActionsDesktop>
            {authState.isAuthChecked &&
              authState.isAuthenticated &&
              (isLiked ? (
                <Button
                  icon={iconHeartImg}
                  iconHover={iconHeartImg}
                  dark
                  small
                  onClick={onUnlike}
                >
                  {t('trial:liked_trial')}
                </Button>
              ) : (
                <Button
                  icon={iconHeartOutlineImg}
                  iconHover={iconHeartOutlineImg}
                  small
                  onClick={onLike}
                >
                  {t('trial:like_trial')}
                </Button>
              ))}
            <Button
              icon={iconPrintImg}
              iconHover={iconPrintLightImg}
              small
              onClick={() => window.print()}
            >
              {t('common:print')}
            </Button>
          </StyledActionsDesktop>
        </StyledTitleSection>

        <StyledSharePageControl
          shareTitle={briefTitle ?? ''}
          shareDescription={briefTitle ?? ''}
          title={t('trial:share_this_page')}
        />

        <StyledStatus>
          <StyledStatusLabel>{t('trial:status')}</StyledStatusLabel>
          <StatusIndicator status={overallStatus!} />
        </StyledStatus>

        {isProcessed && (
          <AiProcessedIndicator
            message={
              <Trans i18nKey="trial:ai_processed_with_link">
                <a
                  href={`https://classic.clinicaltrials.gov/ct2/show/${id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  link
                </a>
              </Trans>
            }
          />
        )}

        <StyledActionsMobile>
          {authState.isAuthChecked &&
            authState.isAuthenticated &&
            (isLiked ? (
              <Button
                icon={iconHeartImg}
                iconHover={iconHeartImg}
                dark
                small
                onClick={onUnlike}
              >
                {t('trial:liked_trial')}
              </Button>
            ) : (
              <Button
                icon={iconHeartOutlineImg}
                iconHover={iconHeartOutlineImg}
                small
                onClick={onLike}
              >
                {t('trial:like_trial')}
              </Button>
            ))}
        </StyledActionsMobile>

        {conditions && conditions.length > 0 && (
          <StyledSubSection>
            <SectionSubheading>{t('trial:conditions_label')}</SectionSubheading>
            <p>{conditions.join(', ')}</p>
          </StyledSubSection>
        )}

        {hasDisplayableIntervention(interventions) && (
          <>
            <StyledSubSection horizontal>
              <SectionSubheading>{t('trial:type')}</SectionSubheading>
              <InterventionIconSet interventions={interventions} maxIcons={5} />
            </StyledSubSection>
            <StyledSubSection>
              <SectionSubheading>
                {t('trial:interventions_label')}
              </SectionSubheading>
              <SummaryList>
                {interventions.map((intervention, index) => (
                  <li key={`${intervention.intervention_name}_${index}`}>
                    {`${intervention.intervention_type}: ${intervention.intervention_name}`}
                  </li>
                ))}
              </SummaryList>
            </StyledSubSection>
          </>
        )}

        {locations && locations.length > 0 && (
          <StyledSubSection>
            <LocationSummaryList
              locations={locations}
              onViewAll={() => setIsLocationModalShown(true)}
            />
          </StyledSubSection>
        )}

        {links && links.length > 0 && (
          <StyledSubSection>
            <ResourceLinks links={links} isMainSection />
          </StyledSubSection>
        )}

        {detailedDescription && (
          <StyledDetailsSection>
            <SectionHeading>{t('trial:detailed_description')}</SectionHeading>
            <StyledTextContent>
              <ClickableDefinitions>{detailedDescription}</ClickableDefinitions>
            </StyledTextContent>
          </StyledDetailsSection>
        )}

        {survey83BarId && <Survey83Bar surveyId={survey83BarId} />}

        {
          // If the inclusion/exclusion data is available, show eligibility button
          !survey83BarId &&
          ((inclusion && inclusion.length > 0) ||
            (exclusion && exclusion.length > 0) ||
            isIQVIA) ? ( // TODO: Remove when no longer needed - quick fix to handle sponsored trial
            <StyledCTASection>
              <SectionHeading>
                {t('trial:interested_in_participating')}
              </SectionHeading>
              <Button
                dark
                onClick={() => {
                  if (isIQVIA) {
                    // TODO: Remove when no longer needed - quick fix to redirect on apply for IQVIA trials
                    startRedirect();
                  } else {
                    setIsEligibilityModalShown(true);
                  }
                }}
              >
                {t('trial:check_my_eligibility')}
              </Button>
            </StyledCTASection>
          ) : (
            // If the inclusion/exclusion data isn't available but the text version is, show it
            eligibilityCriteria && (
              <StyledDetailsSection>
                <SectionHeading>
                  {t('trial:eligibility_criteria')}
                </SectionHeading>
                <StyledTextContent>{eligibilityCriteria}</StyledTextContent>
              </StyledDetailsSection>
            )
          )
        }

        {
          // If the inclusion/exclusion data isn't available and there is contact info, show the contact button
          (!inclusion || inclusion.length === 0) &&
            (!exclusion || exclusion.length === 0) &&
            (hasContact || hasContactBackup || hasLocationWithContact) && (
              <StyledContactButton
                dark
                onClick={() => setIsContactModalShown(true)}
              >
                {t('trial:contact_clinical_trial')}
              </StyledContactButton>
            )
        }
      </StyledTrialContent>

      {isRedirectModalShown && (
        <RedirectModal onCancel={onCancelRedirect} onGoNow={onForceRedirect} />
      )}

      {isEligibilityModalShown && (
        <EligibilityCheckModal
          inclusionCriteria={inclusion!}
          exclusionCriteria={exclusion!}
          inclusionValues={inclusionValues as string[]}
          exclusionValues={exclusionValues as string[]}
          isSponsored={isSponsored || false}
          isProcessed={isProcessed}
          locations={locations}
          trialId={id}
          onClose={() => setIsEligibilityModalShown(false)}
        />
      )}

      {isLocationModalShown && (
        <LocationListModal
          locationsByCountryState={locationsByCountryState}
          onClose={() => setIsLocationModalShown(false)}
        />
      )}

      {isContactModalShown && (
        <Modal
          controls={
            <TextButton onClick={() => setIsContactModalShown(false)}>
              {t('common:close')}
            </TextButton>
          }
        >
          {(hasContact || hasContactBackup) && (
            <>
              <StyledModalHeading>
                {t('trial:general_contact')}
              </StyledModalHeading>
              <DetailsList>
                <li>
                  <DetailsSubList>
                    {hasContact && (
                      <li>
                        <ContactDetails contact={overallContact} />
                      </li>
                    )}
                    {hasContactBackup && (
                      <li>
                        <ContactDetails contact={overallContactBackup} />
                      </li>
                    )}
                  </DetailsSubList>
                </li>
              </DetailsList>
            </>
          )}
          {hasLocationWithContact && (
            <>
              <StyledModalHeading>
                {t('trial:locations_with_contacts')}
              </StyledModalHeading>
              <DetailsList>
                {Object.keys(locationsByCountryStateWithContact).map(
                  (country) =>
                    Object.keys(
                      locationsByCountryStateWithContact[country],
                    ).map((state) => (
                      <li key={`${country}_${state}`}>
                        <SectionSubheading>
                          {state === 'other'
                            ? `${country}`
                            : `${country}, ${state}`}
                        </SectionSubheading>
                        <DetailsSubList>
                          {locationsByCountryStateWithContact[country][
                            state
                          ].map(
                            (
                              {
                                contact = {},
                                contact_backup: contactBackup = {},
                                facility = null,
                              },
                              index,
                            ) => (
                              <li key={`facility-contact-${index}`}>
                                {facility && (
                                  <FacilityDetails facility={facility} />
                                )}
                                {Object.keys(contact).length > 0 && (
                                  <StyledContactSet>
                                    <ContactDetails
                                      contact={contact}
                                      isContentOnly
                                    />
                                  </StyledContactSet>
                                )}
                                {Object.keys(contactBackup).length > 0 && (
                                  <StyledContactSet>
                                    <ContactDetails
                                      contact={contactBackup}
                                      isContentOnly
                                    />
                                  </StyledContactSet>
                                )}
                              </li>
                            ),
                          )}
                        </DetailsSubList>
                      </li>
                    )),
                )}
              </DetailsList>
            </>
          )}
        </Modal>
      )}
      {isProfileModalShown && (
        <Modal
          heading={t('common:complete_profile')}
          controls={
            <TextButton onClick={() => setIsProfileModalShown(false)}>
              {t('common:close')}
            </TextButton>
          }
          small
        >
          <StyledCompleteProfileMessage>
            <div>{t('common:complete_profile_to_like')}</div>
            <ButtonLink to={PATHS.DASHBOARD} dark>
              {t('common:complete_profile')}
            </ButtonLink>
          </StyledCompleteProfileMessage>
        </Modal>
      )}
    </StyledTrial>
  );
};

export default TrialPage;
