import SectionHeading from 'components/SectionHeading';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledSurvey83Bar = styled.iframe`
  border: none;
  width: 100%;
  height: 580px;

  @media screen and (max-width: 374px) {
    height: 420px;
  }
`;

export type Survey83BarProps = {
  className?: string;
  surveyId: string;
  theme?: DefaultTheme;
};

const Survey83Bar = ({ surveyId, ...rest }: Survey83BarProps) => {
  const { t } = useTranslation();
  const [baseUrl, params] = window.location.href.split('?');

  return (
    <div {...rest}>
      <SectionHeading>{t('trial:check_my_eligibility')}</SectionHeading>
      <StyledSurvey83Bar
        id={`iframe83bar-${surveyId}`}
        data-survey-id={surveyId}
        src={`https://hydrant.83bar.com/${surveyId}?captured_url=${baseUrl}${
          params && params.length > 0 ? `&${params}` : ''
        }`}
        {...rest}
      />
    </div>
  );
};

export default Survey83Bar;
