export default {
  'apply': 'Apply',
  'confirm_close': 'Confirm Close',
  'check_eligibility': 'Check eligibility',
  'check_my_eligibility': 'Check my eligibility',
  'checklist_can_take_minutes': 'This checklist can take up to {{minutes}} minutes to complete',
  'copy_url': 'Copy URL to clipboard',
  'exclusion_criteria': 'Exclusion Criteria',
  'get_in_touch': 'Get in Touch',
  'inclusion_criteria': 'Inclusion Criteria',
  'looks_like_unsure': 'There are some items you are unsure about that we can help with. Fill in this form and we will get in touch, or copy this URL to send to your doctor.',
  'may_not_qualify': '<0>You may not qualify for this trial, you can either </0><1>start a new search</1><2> or get in touch so we can help match you with future trials.</2>',
  'might_qualify': 'It looks like you might qualify for this clinical trial. Please click the button to get in touch with the primary contact of this trial.',
  'might_qualify_apply': 'It looks like you might qualify for this clinical trial. If you would like to apply, click Apply below.',
  'new_trial_search': 'New clinical trial search',
  'no': 'No',
  'please_fill_out': 'Please fill out all the following questions to see if you are a fit for this clinical trial',
  'selections_will_be_lost': 'You have selections that will be lost, are you sure you\'d like to stop checking your eligibility?',
  'unsure': 'Unsure',
  'url_copied': 'URL copied!',
  'yes': 'Yes',
};
