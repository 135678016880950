import Profile from 'common/types/Profile';
import EditProfileForm from 'modules/Dashboard/components/EditProfileForm';
import DashboardPageHeading from 'modules/Dashboard/components/DashboardPageHeading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledEditProfile = styled.div``;

const StyledHeading = styled(DashboardPageHeading)`
  @media ${(props) => props.theme.devices.tablet} {
    display: none;
  }
`;

export type EditProfileProps = {
  profile: Profile;
  onProfileUpdated: (profile: Profile) => void;
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'div'>;

const EditProfile = ({
  profile,
  onProfileUpdated,
  ...rest
}: EditProfileProps) => {
  const { t } = useTranslation();

  return (
    <StyledEditProfile {...rest}>
      <StyledHeading>{t('dashboard:my_profile')}</StyledHeading>
      <EditProfileForm
        columns={2}
        profile={profile}
        onProfileSaved={onProfileUpdated}
      />
    </StyledEditProfile>
  );
};

export default EditProfile;
