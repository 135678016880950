import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import Button from 'components/Button';
import { ButtonProps } from 'components/Button/Button';

import getUrlToShare from 'common/util/getUrlToShare';
import IntegratorContext from 'contexts/IntegratorContext';
import copyUrlHighlightedImg from './assets/icon-copy_url-highlighted.svg';
import copyUrlImg from './assets/icon-copy_url.svg';

// Styled Components
const StyledCopyText = styled.span`
  display: inline-block;
  min-width: 86px;
`;

// Component
const CopyUrlButton = (props: ButtonProps) => {
  const { t } = useTranslation(['common']);
  const [copyButtonText, setCopyButtonText] = useState(t('common:copy_url'));
  const copyTimeoutRef = useRef<number | null>(null);
  const { integratorClient, isEmbedded } = useContext(IntegratorContext);

  const copyUrl = () => {
    // Copy the current URL
    const urlToCopy = getUrlToShare(isEmbedded, integratorClient);
    const input = document.createElement('input');
    document.body.append(input);
    input.value = urlToCopy;
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);

    // Show copied text
    setCopyButtonText(t('common:copied'));

    if (copyTimeoutRef.current !== null) {
      window.clearTimeout(copyTimeoutRef.current);
    }
    copyTimeoutRef.current = window.setTimeout(() => {
      setCopyButtonText(t('common:copy_url'));
    }, 2000);
  };

  return (
    <Button
      icon={copyUrlImg}
      iconHover={copyUrlHighlightedImg}
      onClick={copyUrl}
      {...props}
    >
      <StyledCopyText>{copyButtonText}</StyledCopyText>
    </Button>
  );
};

export default CopyUrlButton;
