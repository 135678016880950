import { getProfile } from 'common/api/profile';
import { PATHS } from 'common/constants';
import Profile from 'common/types/Profile';
import LoadingOverlay from 'components/LoadingOverlay';
import TabNavigation from 'components/TabNavigation';
import useRequireAuth from 'hooks/useRequireAuth';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components/macro';

import AppliedTrials from './modules/AppliedTrials';
import DashboardHome from './modules/DashboardHome';
import LikedTrials from './modules/LikedTrials';
import MyProfile from './modules/MyProfile';
import ProfileSetup from './modules/ProfileSetup';

import iconProfileImg from './assets/icon-profile.svg';
import iconLikedImg from './assets/icon-liked.svg';
import iconDashboardImg from './assets/icon-dashboard.svg';
import iconAppliedImg from './assets/icon-applied.svg';

// Styled Components
const StyledDashboard = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${(props) => props.theme.colors.dashboardBackground};
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: ${(props) => props.theme.colors.headerBackgroundDark};
`;

const StyledNavigationContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.darkBackground};
`;

const StyledNavigationContent = styled.div`
  ${(props) => props.theme.dashboardContent}

  @media ${(props) => props.theme.devices.mobile} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledContent = styled.div`
  flex: 1;
  position: relative;
`;

const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
`;

const StyledError = styled.div`
  ${(props) => props.theme.dashboardContent}
  margin-left: auto;
  margin-right: auto;
  margin-top: 64px;
  text-align: center;
  color: ${(props) => props.theme.colors.error};
  font-size: ${(props) => props.theme.fontSizes.h3};
`;

export type DashboardProps = {
  theme?: DefaultTheme;
};

const NAV_ITEM_WIDTH = 176;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Dashboard = ({ ...rest }: DashboardProps) => {
  const { t } = useTranslation();
  const [profile, setProfile] = useState<Profile | null>(null);
  const [isProfileChecked, setIsProfileChecked] = useState<boolean>(false);
  const [errorKey, setErrorKey] = useState<string | null>(null);
  const isAuthChecked = useRequireAuth();
  const history = useHistory();

  const onProfileSetup = (profile: Profile) => {
    setProfile(profile);
    history.replace(PATHS.DASHBOARD_PROFILE_MEDICAL);
  };

  useEffect(() => {
    const checkProfile = async () => {
      try {
        setErrorKey(null);
        const profile = await getProfile();
        setProfile(profile);
        setIsProfileChecked(true);
      } catch (ex) {
        setErrorKey('common:unknown_error');
      }
    };

    checkProfile();
  }, []);

  if (errorKey) {
    return (
      <StyledDashboard>
        <StyledError>{t(errorKey)}</StyledError>
      </StyledDashboard>
    );
  }

  return (
    <StyledDashboard>
      <StyledHeader />
      <StyledNavigationContainer>
        <StyledNavigationContent>
          {(profile || !isProfileChecked) && (
            <TabNavigation
              navItemWidth={NAV_ITEM_WIDTH}
              navItems={[
                {
                  icon: iconDashboardImg,
                  textKey: 'dashboard:dashboard',
                  path: PATHS.DASHBOARD,
                },
                {
                  icon: iconAppliedImg,
                  textKey: 'dashboard:applied_trials',
                  textKeyMobile: 'dashboard:applied_trials_short',
                  path: PATHS.DASHBOARD_APPLIED_TRIALS,
                },
                {
                  icon: iconLikedImg,
                  textKey: 'dashboard:liked_trials',
                  textKeyMobile: 'dashboard:liked_trials_short',
                  path: PATHS.DASHBOARD_LIKED_TRIALS,
                },
                {
                  icon: iconProfileImg,
                  textKey: 'dashboard:my_profile',
                  path: PATHS.DASHBOARD_PROFILE,
                },
              ]}
              dark
            />
          )}
        </StyledNavigationContent>
      </StyledNavigationContainer>
      <StyledContent>
        {!profile || !isProfileChecked ? (
          isProfileChecked ? (
            <ProfileSetup onProfileSetup={onProfileSetup} />
          ) : (
            <StyledLoading>{t('common:loading')}</StyledLoading>
          )
        ) : (
          <Switch>
            <Route
              path={PATHS.DASHBOARD_APPLIED_TRIALS}
              render={() => <AppliedTrials />}
            />
            <Route
              path={PATHS.DASHBOARD_LIKED_TRIALS}
              render={() => <LikedTrials />}
            />
            <Route
              path={PATHS.DASHBOARD_PROFILE}
              render={() => (
                <MyProfile profile={profile} onProfileUpdated={setProfile} />
              )}
            />
            <Route render={() => <DashboardHome />} />
          </Switch>
        )}
        <LoadingOverlay isShown={!isAuthChecked || !isProfileChecked} />
      </StyledContent>
    </StyledDashboard>
  );
};

export default Dashboard;
