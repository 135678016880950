import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import Select, { SingleValue } from 'react-select';

import SelectTitle from 'components/SelectTitle';

// Styled Components
const StyledAgeInput = styled.div``;

const StyledInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .age_select {
    width: 140px;
    margin-right: 20px;
  }
`;

export type AgeInputProps = {
  includeBlank?: boolean;
  menuPortalTarget?: HTMLElement | null | undefined;
  value?: number;
  startAge?: number;
  endAge?: number;
  theme?: DefaultTheme;

  onChange: (newValue: SingleValue<0 | AgeOption>) => void;
};

export type AgeOption = {
  label: string;
  value: number;
};

// Component
const AgeInput = ({
  includeBlank = false,
  menuPortalTarget,
  value,
  startAge = 1,
  endAge = 130,
  onChange,
}: AgeInputProps) => {
  const { t } = useTranslation(['searchCovid']);

  const options: AgeOption[] = [];

  if (includeBlank) {
    options.push({
      value: 0,
      label: '-',
    });
  }

  for (let i = startAge; i <= endAge; i++) {
    options.push({
      value: i,
      label: `${i}`,
    });
  }

  return (
    <StyledAgeInput>
      <SelectTitle>{t('searchCovid:how_old_are_you')}</SelectTitle>
      <StyledInput>
        <Select
          className="age_select"
          menuPortalTarget={menuPortalTarget}
          options={options}
          styles={{
            menuPortal: (provided) => ({ ...provided, zIndex: 1000 }),
          }}
          value={value && { value, label: `${value}` }}
          onChange={onChange}
        />
        <span>{t('searchCovid:years_old')}</span>
      </StyledInput>
    </StyledAgeInput>
  );
};

export default AgeInput;
