import { EVENT_PAGE_VIEW, gtmPush } from 'common/analytics/gtm';
import { PATHS } from 'common/constants';
import notifyParent from 'common/util/notifyParent';
import HeaderBar from 'components/HeaderBar';
import useOnResize from 'hooks/useOnResize';
import Dashboard from 'modules/Dashboard';
import ForgotPassword from 'modules/ForgotPassword';
import PrivacyPolicy from 'modules/PrivacyPolicy';
import ResetPassword from 'modules/ResetPassword';
import Search from 'modules/Search';
import SearchCovid from 'modules/SearchCovid';
import SignIn from 'modules/SignIn';
import SignUp from 'modules/SignUp';
import TermsOfService from 'modules/TermsOfService';
import Trial from 'modules/Trial';
import TrialCovid from 'modules/TrialCovid';
import { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

// Styled Components
const StyledHealMaryIntegrator = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 800px;
`;

// Component
const HealMaryIntegrator = () => {
  const location = useLocation();

  // Scroll to top on location change - react-router doesn't handle this natively
  useEffect(() => {
    // Skip scrolling to top when navigating on the covid search to allow for
    // dynamically updating the filters in the URL without causing scrolling
    if (location.pathname === '/covidsearch' && location.search !== '') {
      return;
    }
    window.scrollTo(0, 0);
    notifyParent({ action: 'pageChange', data: { path: location.pathname } });
  }, [location]);

  gtmPush({
    event: EVENT_PAGE_VIEW,
    page: {
      path: location,
    },
  });

  useOnResize(
    () => {
      const height = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
      );
      notifyParent({ action: 'resize', data: { height } });
    },
    true,
    document.body,
  );

  useEffect(() => {
    // Listen for scroll offset messages when embedded
    // Used to make a best effort compensating for scroll
    // when a modal is shown
    const onMessage = (evt: MessageEvent) => {
      if (evt.data.action === 'scrollOffset' && evt.data.value) {
        scrollTo(0, (evt.data.value as number) * -1);
      }
    };
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  return (
    <StyledHealMaryIntegrator>
      <HeaderBar isIntegratorMode />
      <Switch>
        <Route path={PATHS.COVID_SEARCH} component={SearchCovid} />
        <Route path="/covidtrials/:id" component={TrialCovid} />
        <Route path={PATHS.DASHBOARD} component={Dashboard} />
        <Route path={PATHS.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route path={PATHS.SEARCH} component={Search} />
        <Route path="/trials/:id" component={Trial} />
        <Route path={PATHS.PRIVACY} component={PrivacyPolicy} />
        <Route path={PATHS.RESET_PASSWORD} component={ResetPassword} />
        <Route path={PATHS.SIGN_IN} component={SignIn} />
        <Route path={PATHS.SIGN_UP} component={SignUp} />
        <Route path={PATHS.TERMS} component={TermsOfService} />
        <Route path={PATHS.HOME} component={Search} />
      </Switch>
    </StyledHealMaryIntegrator>
  );
};

export default HealMaryIntegrator;
