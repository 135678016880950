import React from 'react';
import { DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import TextButton from 'components/TextButton';
import SearchLinks from 'components/SearchLinks';

export type SearchSelectModalProps = {
  onClose: () => void;
  theme?: DefaultTheme;
};

// Component
const SearchSelectModal = ({ onClose }: SearchSelectModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      heading={t('common:what_type_of_clinical_trials')}
      controls={<TextButton onClick={onClose}>{t('common:close')}</TextButton>}
      small
    >
      <SearchLinks onLinkClicked={onClose} />
    </Modal>
  );
};

export default SearchSelectModal;
