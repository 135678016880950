import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledPageHeader = styled.div<{
  backgroundColor?: string;
  backgroundImage?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;

  background-color: ${(props) => props.backgroundColor};
  background-image: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;

  & h1,
  & h2 {
    color: ${(props) => props.color};
  }

  @media ${(props) => props.theme.devices.mobile} {
    height: initial;
    min-height: 300px;
  }
`;

const StyledContent = styled.div`
  ${(props) => props.theme.paddedContent};

  max-width: 900px;
  margin-left: 18%;
  margin-right: 18%;

  @media ${(props) => props.theme.devices.tablet} {
    margin-left: 8%;
    margin-right: 8%;
  }

  @media ${(props) => props.theme.devices.mobile} {
    margin-left: 0;
    margin-right: 0;
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

export type PageHeaderProps = {
  backgroundColor?: string;
  backgroundImage?: string;
  children: React.ReactNode;
  color?: string;
  theme?: DefaultTheme;
};

// Component
const PageHeader = ({
  backgroundColor,
  backgroundImage,
  children,
  color,
}: PageHeaderProps) => (
  <StyledPageHeader
    color={color}
    backgroundColor={backgroundColor}
    backgroundImage={backgroundImage}
  >
    <StyledContent>{children}</StyledContent>
  </StyledPageHeader>
);

export default PageHeader;
