import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

import checkImg from './assets/check.svg';

// Styled Components
const StyledCheckboxRadioGroup = styled.fieldset`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border: none;
  padding: 0;
  margin: 0;
  gap: 16px;

  @media ${(props) => props.theme.devices.mobile} {
    flex-direction: column;
  }
`;

const StyledLegend = styled.span`
  @media ${(props) => props.theme.devices.mobile} {
    margin-bottom: 16px;
  }
`;

const StyledOptionBox = styled.div`
  width: 20px;
  height: 20px;

  text-align: center;

  background-color: ${(props) => props.theme.rawColors.white};
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  box-shadow: 0 0 10px -5px ${(props) => props.theme.rawColors.black20};
  border-radius: 5px;

  @media ${(props) => props.theme.devices.mobile} {
    display: inline-block;
  }
`;

const StyledOptions = styled.span`
  display: flex;
  gap: 16px;

  label {
    display: flex;
    align-items: center;
    font-weight: ${(props) => props.theme.fontWeights.bold};
    font-size: ${(props) => props.theme.fontSizes.xsmall};
    text-align: center;

    cursor: pointer;

    input {
      opacity: 0;
      width: 0;
      height: 0;
      margin: 0;
      padding: 0;

      &:focus + ${StyledOptionBox} {
        border-color: ${(props) => props.theme.colors.active};
      }
    }

    span {
      display: block;
      margin-left: 8px;
    }
  }
`;

export type Option = {
  label: string;
  value: string;
};

export type CheckboxRadioGroupProps = {
  legend?: React.ReactNode;
  options: Option[];
  value: string;
  onValueChange: (value: string) => void;
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'fieldset'>;

const CheckboxRadioGroup = ({
  legend,
  options,
  value,
  onValueChange,
  ...rest
}: CheckboxRadioGroupProps) => (
  <StyledCheckboxRadioGroup tabIndex={0} {...rest}>
    {legend && <StyledLegend>{legend}</StyledLegend>}
    <StyledOptions>
      {options.map(({ label, value: optionValue }) => (
        <label key={optionValue}>
          <input
            type="radio"
            value={optionValue}
            checked={value === optionValue}
            onChange={() => onValueChange(optionValue)}
          />
          <StyledOptionBox>
            {value === optionValue && <img src={checkImg} alt="" />}
          </StyledOptionBox>
          <span>{label}</span>
        </label>
      ))}
    </StyledOptions>
  </StyledCheckboxRadioGroup>
);

export default CheckboxRadioGroup;
