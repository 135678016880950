import { MedicalDetailsEnum } from 'common/types/Profile';
import { BASE_URL } from './constants';
import authenticatedFetch from './util/authenticatedFetch';

export enum ConditionTypeEnum {
  cancer = 'cancer',
  covid = 'covid',
  other = 'other',
}

export type CancerConditionDetails = {
  condition: string;
  phase?: string;
};

export type OtherConditionDetails = {
  condition: string;
};

export enum CovidConditionDetailsEnum {
  inClinicalTrial = 'in_clinical_trial',
  previousClinicalTrial = 'previous_clinical_trial',
  inHospital = 'in_hospital',
  healthcareWorker = 'healthcare_worker',
  requireVentilator = 'require_ventilator',
  haveImmuneDiseases = 'have_immune_diseases',
  onChemotherapy = 'on_chemotherapy',
  takingImmunosuppressants = 'taking_immunosuppressants',
  breastfeeding = 'breastfeeding',
}

// Filter out overlap with general medical conditions
// TODO: Rework definitions so that search uses CovidConditionDetailsEnum & MedicalDetailsEnum
// so that CovidConditionDetailsEnum only has covid specific values
export const CovidDetailsEnumWithoutMedical = Object.values(
  CovidConditionDetailsEnum,
).filter(
  (value) =>
    !(Object.values(MedicalDetailsEnum) as string[]).includes(value as string),
);

export type CovidConditionDetails = Partial<
  Record<CovidConditionDetailsEnum, boolean>
>;

export type Condition = {
  id: number;
} & (
  | { type: ConditionTypeEnum.cancer; details: CancerConditionDetails }
  | { type: ConditionTypeEnum.covid; details: CovidConditionDetails }
  | { type: ConditionTypeEnum.other; details: OtherConditionDetails }
);

export type ConditionsResponse = {
  conditions: Condition[];
  total: number;
};

/**
 * Get the current user's conditions
 * @returns {Promise<ConditionsResponse>} The user's conditions
 */
export async function getConditions(): Promise<ConditionsResponse> {
  const response = await authenticatedFetch(`${BASE_URL}/conditions`);
  if (!response.ok) {
    throw new Error(`Bad response: ${response.status}`);
  }
  return response.json();
}

/**
 * Add a condition
 * @param {ConditionTypeEnum} type The condition type
 * @param {CancerConditionDetails | CovidConditionDetails} details The details about the condition
 * @returns {Promise<Condition | null>} The condition if added successfully
 */
export async function addCondition(
  type: ConditionTypeEnum,
  details:
    | CancerConditionDetails
    | CovidConditionDetails
    | OtherConditionDetails,
): Promise<Condition | null> {
  const headers: HeadersInit = new Headers();
  headers.set('Content-Type', 'application/json');
  const response = await authenticatedFetch(`${BASE_URL}/conditions`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      type,
      details,
    }),
  });

  if (!response.ok) {
    return null;
  }
  return response.json();
}

/**
 * Update a condition's details
 * @param {id} id The ID of the condition
 * @param {CancerConditionDetails | CovidConditionDetails} details The details about the condition
 * @returns {Promise<Condition | null>} The condition if updated successfully
 */
export async function updateCondition(
  id: number,
  details: CancerConditionDetails | CovidConditionDetails,
): Promise<Condition | null> {
  const headers: HeadersInit = new Headers();
  headers.set('Content-Type', 'application/json');
  const response = await authenticatedFetch(`${BASE_URL}/conditions/${id}`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      details,
    }),
  });

  if (!response.ok) {
    return null;
  }
  return response.json();
}

/**
 * Delete a user's condition
 * @returns {Promise<boolean>} True if the request succeeded
 */
export async function deleteCondition(id: number): Promise<boolean> {
  const response = await authenticatedFetch(`${BASE_URL}/conditions/${id}`, {
    method: 'DELETE',
  });

  if (!response.ok) {
    throw new Error(`Bad response: ${response.status}`);
  }
  return true;
}
