import * as Sentry from '@sentry/react';
import {
  addCondition,
  CancerConditionDetails,
  Condition,
  ConditionTypeEnum,
  CovidConditionDetails,
  CovidDetailsEnumWithoutMedical,
  OtherConditionDetails,
} from 'common/api/conditions';
import Button from 'components/Button';
import IconSelect from 'components/IconSelect/IconSelect';
import useIsMounted from 'hooks/useIsMounted';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';

import EditCancerConditionForm from '../EditCancerConditionForm';
import EditCovidConditionForm from '../EditCovidConditionForm';
import EditOtherConditionForm from '../EditOtherConditionForm';

// Styled Components
const StyledAddConditionForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;

  @media ${(props) => props.theme.devices.mobile} {
    gap: 40px;
  }
`;

const StyledHeading = styled.h1`
  font-size: ${(props) => props.theme.fontSizes.h4};
  margin-bottom: 24px;
`;

const StyledSubHeading = styled.span`
  color: ${(props) => props.theme.colors.accentText};
`;

const StyledConditionDetails = styled.div``;

const StyledButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${(props) => props.theme.devices.mobile} {
    button {
      width: 100%;
    }
  }
`;

const StyledError = styled.span`
  color: ${(props) => props.theme.colors.error};
  text-align: center;
  margin-bottom: 32px;
`;

export type AddConditionFormProps = {
  conditions: Condition[];
  onConditionAdded: (condition: Condition) => void;
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'div'>;

const AddConditionForm = ({
  conditions,
  onConditionAdded,
  ...rest
}: AddConditionFormProps) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [conditionType, setConditionType] = useState<ConditionTypeEnum | null>(
    null,
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorKey, setErrorKey] = useState<string | null>(null);

  const [cancerDetails, setCancerDetails] = useState<
    Partial<CancerConditionDetails>
  >({});
  const [covidDetails, setCovidDetails] = useState<CovidConditionDetails>({});
  const [otherConditionDetails, setOtherConditionDetails] = useState<
    Partial<OtherConditionDetails>
  >({});

  const onAddCondition = async () => {
    if (!conditionType) {
      return;
    }
    setErrorKey(null);

    try {
      setIsSubmitting(true);
      let condition = null;
      switch (conditionType) {
        case ConditionTypeEnum.cancer: {
          condition = await addCondition(
            conditionType,
            cancerDetails as CancerConditionDetails,
          );
          break;
        }
        case ConditionTypeEnum.covid: {
          condition = await addCondition(conditionType, covidDetails);
          break;
        }
        case ConditionTypeEnum.other: {
          condition = await addCondition(
            conditionType,
            otherConditionDetails as OtherConditionDetails,
          );
          break;
        }
        default: {
          setErrorKey('common:unknown_error');
          return;
        }
      }
      if (!isMounted.current) {
        return;
      }
      setIsSubmitting(false);

      if (!condition) {
        setErrorKey('common:unknown_error');
        return;
      }

      onConditionAdded(condition);
    } catch (ex) {
      if (!isMounted.current) {
        return;
      }

      setErrorKey('common:unknown_error');
      setIsSubmitting(false);
      Sentry.captureException(ex);
    }
  };

  const covidConditionCount = conditions.filter(
    ({ type }) => type === ConditionTypeEnum.covid,
  ).length;

  const conditionOptions = Object.values(ConditionTypeEnum)
    .filter((conditionType) => {
      // Remove COVID as an option when one exists
      if (
        covidConditionCount > 0 &&
        conditionType === ConditionTypeEnum.covid
      ) {
        return false;
      }
      return true;
    })
    .map((conditionType) => ({
      text: t(`common:condition_${conditionType}`),
      value: conditionType,
    }));

  let isFormComplete = false;
  switch (conditionType) {
    case ConditionTypeEnum.cancer: {
      isFormComplete =
        cancerDetails.condition && cancerDetails.condition ? true : false;
      break;
    }
    case ConditionTypeEnum.covid: {
      isFormComplete =
        Object.values(covidDetails).length ===
        Object.values(CovidDetailsEnumWithoutMedical).length;
      break;
    }
    case ConditionTypeEnum.other: {
      isFormComplete =
        otherConditionDetails.condition && otherConditionDetails.condition
          ? true
          : false;
      break;
    }
    default: {
      // No-op
    }
  }

  return (
    <StyledAddConditionForm {...rest}>
      <section>
        <StyledHeading>
          {t('profile:please_add_condition')}
          {conditions.length === 0 && (
            <>
              <br />
              <StyledSubHeading>
                {t('profile:can_select_more_than_one_condition')}
              </StyledSubHeading>
            </>
          )}
        </StyledHeading>
        <IconSelect
          options={conditionOptions}
          selectedValues={conditionType ? [conditionType] : []}
          light
          onChange={(values) =>
            setConditionType(
              values.length > 0 ? (values[0] as ConditionTypeEnum) : null,
            )
          }
        />
      </section>
      {conditionType && (
        <StyledConditionDetails>
          {conditionType === ConditionTypeEnum.cancer && (
            <EditCancerConditionForm
              cancerDetails={cancerDetails}
              disabled={isSubmitting}
              onValueChange={setCancerDetails}
            />
          )}
          {conditionType === ConditionTypeEnum.covid && (
            <EditCovidConditionForm
              disabled={isSubmitting}
              error={errorKey ? t(errorKey) : undefined}
              covidDetails={covidDetails}
              onValueChange={setCovidDetails}
            />
          )}
          {conditionType === ConditionTypeEnum.other && (
            <EditOtherConditionForm
              conditionDetails={otherConditionDetails}
              disabled={isSubmitting}
              onValueChange={setOtherConditionDetails}
            />
          )}
        </StyledConditionDetails>
      )}
      <StyledButtonRow>
        {errorKey && <StyledError>{t(errorKey)}</StyledError>}
        <Button
          disabled={!conditionType || !isFormComplete || isSubmitting}
          type="button"
          dark
          onClick={onAddCondition}
        >
          {t('profile:add_medical_condition')}
        </Button>
      </StyledButtonRow>
    </StyledAddConditionForm>
  );
};

export default AddConditionForm;
