export default {
  'applied_trials': 'Applied Trials',
  'applied_trials_short': 'Applied To',
  'dashboard': 'Dashboard',
  'helpful_resources': 'Helpful Articles and Resources',
  'latest_applied_trials': 'Latest Applied Trials',
  'latest_liked_trials': 'Latest Liked Trials',
  'liked_trials': 'Liked Trials',
  'liked_trials_short': 'Liked',
  'medical_information': 'Medical Information',
  'my_profile': 'My Profile',
  'notification_settings': 'Notification Settings',
  'profile_settings': 'Profile Settings',
  'see_all': 'See All',
  'trials_added_since_last_login': '<0>{{trialsAdded}}</0><1>Trials added since you last logged in</1>'
};
