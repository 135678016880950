import EmailDetails from 'common/types/EmailDetails';
import Profile from 'common/types/Profile';
import useIsMounted from 'hooks/useIsMounted';
import EditProfileForm from 'modules/Dashboard/components/EditProfileForm/EditProfileForm';
import DashboardPageHeading from 'modules/Dashboard/components/DashboardPageHeading';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';
import { getAuthEmailDetails } from 'common/api/auth';

// Styled Components
const StyledProfileSetup = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 68px;
  padding-bottom: 248px;
  background-color: ${(props) => props.theme.colors.headerBackgroundDark};

  @media ${(props) => props.theme.devices.mobile} {
    padding-top: 36px;
    padding-bottom: 228px;
  }
`;

const StyledHeaderContent = styled.div`
  ${(props) => props.theme.dashboardContent}
`;

const StyledPageHeading = styled(DashboardPageHeading)`
  margin-bottom: 0;
`;

const StyledDescription = styled.div`
  max-width: 700px;
  margin-top: 8px;
  color: ${(props) => props.theme.colors.heading};
  font-size: ${(props) => props.theme.fontSizes.h4};
`;

const StyledContent = styled.div`
  ${(props) => props.theme.dashboardContent}
  margin-top: -184px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledFormContainer = styled.div`
  margin-bottom: 100px;
  padding: 80px 60px;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: 20px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);

  @media ${(props) => props.theme.devices.mobile} {
    padding: 30px 15px;
  }
`;

export type ProfileSetupProps = {
  onProfileSetup: (profile: Profile) => void;
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'div'>;

const ProfileSetup = ({ onProfileSetup, ...rest }: ProfileSetupProps) => {
  const { t } = useTranslation();
  const [currentEmailDetails, setCurrentEmailDetails] =
    useState<EmailDetails | null>(null);
  const isMounted = useIsMounted();

  // Get the auth email to pass as the default contact email
  useEffect(() => {
    const loadEmail = async () => {
      const emailDetails = await getAuthEmailDetails();

      if (!isMounted.current || !emailDetails) {
        return;
      }

      setCurrentEmailDetails(emailDetails);
    };

    loadEmail();
  }, [isMounted]);

  return (
    <StyledProfileSetup {...rest}>
      <StyledHeader>
        <StyledHeaderContent>
          <StyledPageHeading>
            {t('profile:welcome_to_heal_mary')}
          </StyledPageHeading>
          <StyledDescription>{t('profile:form_description')}</StyledDescription>
        </StyledHeaderContent>
      </StyledHeader>
      <StyledContent>
        <StyledFormContainer>
          <EditProfileForm
            authEmail={currentEmailDetails?.email}
            onProfileSaved={onProfileSetup}
          />
        </StyledFormContainer>
      </StyledContent>
    </StyledProfileSetup>
  );
};

export default ProfileSetup;
