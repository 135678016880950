import { checkIsSignedIn } from 'common/api/auth';
import { getProfile } from 'common/api/profile';
import Profile from 'common/types/Profile';
import React, { ReactNode, useEffect, useState } from 'react';

export type AuthContextState = {
  isAuthChecked: boolean;
  isAuthenticated: boolean;
  isProfileChecked: boolean;
  profile?: Profile;
  recheckSession: () => Promise<void>;
  setIsAuthChecked: (isAuthChecked: boolean) => void;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
};

export const DEFAULT_AUTH_CONTEXT_VALUE: AuthContextState = {
  isAuthChecked: false,
  isAuthenticated: false,
  isProfileChecked: false,
  profile: undefined,
  recheckSession: () => Promise.resolve(),
  setIsAuthChecked: () => {},
  setIsAuthenticated: () => {},
};

export const AuthContext = React.createContext(DEFAULT_AUTH_CONTEXT_VALUE);

export type AuthContextWrapperProps = {
  children: ReactNode;
};

export const AuthContextWrapper = ({ children }: AuthContextWrapperProps) => {
  const [isAuthChecked, setIsAuthChecked] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isProfileChecked, setIsProfileChecked] = useState<boolean>(false);
  const [profile, setProfile] = useState<Profile>();

  useEffect(() => {
    const checkSession = async () => {
      const isSignedIn = await checkIsSignedIn();
      setIsAuthenticated(isSignedIn);
      setIsAuthChecked(true);

      if (isSignedIn) {
        const loadedProfile = await getProfile();
        setProfile(loadedProfile ?? undefined);
        setIsProfileChecked(true);
      }
    };

    checkSession();
  }, []);

  const recheckSession = async () => {
    const isSignedIn = await checkIsSignedIn();
    setIsAuthenticated(isSignedIn);

    if (isSignedIn) {
      const loadedProfile = await getProfile();
      setProfile(loadedProfile ?? undefined);
      setIsProfileChecked(true);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthChecked,
        isAuthenticated,
        isProfileChecked,
        profile,
        recheckSession,
        setIsAuthChecked,
        setIsAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
