import SearchLinks from 'components/SearchLinks';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledNoTrials = styled.div`
  box-sizing: border-box;
  max-width: 100%;
  padding: 80px 16px;
  text-align: center;

  @media ${(props) => props.theme.devices.mobile} {
    padding: 40px 16px;
  }
`;

const StyledMessage = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

const StyledSearch = styled.div`
  box-sizing: border-box;
  width: 568px;
  max-width: 100%;
  margin: 40px 0;
  padding: 40px 24px;

  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  h1 {
    margin-bottom: 32px;
    font-size: ${(props) => props.theme.fontSizes.h4};
  }
`;

export type NoTrialsProps = {
  message?: string;
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'div'>;

const NoTrials = ({ message, ...rest }: NoTrialsProps) => {
  const { t } = useTranslation();

  return (
    <StyledNoTrials {...rest}>
      {message && <StyledMessage>{message}</StyledMessage>}
      <StyledSearch>
        <h1>{t('common:what_type_of_clinical_trials')}</h1>
        <SearchLinks />
      </StyledSearch>
    </StyledNoTrials>
  );
};

export default NoTrials;
