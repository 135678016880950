/* eslint-disable @typescript-eslint/no-unused-vars */
import { Link, LinkProps } from 'react-router-dom';

import Button from 'components/Button';
import { ButtonProps, StyledButtonProps } from 'components/Button/Button';

// Extract Button styling props to avoid passing it to the dom link element
// eslint-disable-next-line react/prop-types
const ButtonPropsExtractedLink = ({
  children,
  dark,
  hasIcon,
  small,
  ...rest
}: StyledButtonProps & LinkProps) => <Link {...rest}>{children}</Link>;

// Component
const ButtonLink = ({ children, ...rest }: ButtonProps & LinkProps) => (
  <Button as={ButtonPropsExtractedLink} {...rest}>
    {children}
  </Button>
);

export default ButtonLink;
